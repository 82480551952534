import { Heading3 } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';

export const MapNonCashFee = () => {
  const [isLoading, accountsList] = useAccounts('nonCashFee');
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <Box padding="0.75rem">
    <Heading3 mb="0.375rem">Recording Non-Cash Fee Adjustment</Heading3>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="Tips">
          {
            t(isInputsOnlyLayout ? 'Enter Non-Cash Fee adjustment account' : 'Select a Non-Cash Fee account')
          } 
        </LabelRequired>
        <Autocomplete
          isLoadingOptions={isLoading}
          id="nonCashFeeAccount"
          name="nonCashFeeAccount"
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter Non-Cash Fee adjustment account' : 'Select an account')
          }
        />
      </Box>
    </Flex>
  </Box>;
};
