import { Spinner } from '@lightspeed/flame/Spinner'
import { Heading1 } from '@lightspeed/flame/Text';
import { useTranslation } from 'react-i18next';
 
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    /* TODO: traslation for authorizing ( loading ) */
    const { t } = useTranslation();

    return (
        <>
            <Heading1>Authorizing</Heading1>
            <Spinner size="40px" />
        </>
    );
}