import { useSelector } from 'react-redux';
import { useLocation as useURL } from "@reach/router";

const useLocation = () => {
  const locationData = useSelector(state => state.location.selected);
  return locationData;
};

export const useCurrentRoute = () => {
  const location = useURL()
  const currentPath = location?.pathname;
  const isInDashboard = currentPath.split("/")[2] === "dashboard";
  return [currentPath, isInDashboard]
}

export const useLocationData = () => {
  const { locationList, locationid } = useSelector(state => state.auth);
  const isMultiLocation = locationList && (locationList.length > 1);
  const currentLocation = locationList?.filter(loc => loc?.id === locationid)[0]

  return {
    currentLocation,
    locationList,
    isMultiLocation,
  };
};

export default useLocation;