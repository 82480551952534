import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCompanyName } from '../redux/reducer/globalsReducer';

export const useCompanyName = () => {
  const name = useSelector(state => state.dashboard.companyName);
  const dispatch = useDispatch();

  const setName = (args) => {
    console.log(args, "companyName check")
    dispatch(setCompanyName(args));
  };

  return [name, setName];
};

export default useCompanyName;
