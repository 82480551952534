import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../hooks/useAccounts';
import { useConnectionMode } from '../../hooks/useConnectionMode';

export const RecordingFee = ({ formik }) => {
  const [isLoading, accountsList] = useAccounts('chargebacks');

  const {
    isSentRecordingFee
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  return <SwitchLayout
    heading={"Recording Processing Fee"}
    description={t('', { swName })}
    switchFieldName="isSentRecordingFee"
    isSwitchOn={isSentRecordingFee}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="recordingFeeAccount">
          {`Default Recording Fee Account`}
        </LabelRequired>
        <Autocomplete
          id="recordingFeeAccount"
          name="recordingFeeAccount"
          isLoadingOptions={isLoading}
          options={accountsList}
          placeholder={"Select an account"}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};