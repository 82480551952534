import { useState } from 'react';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { PageLayout } from '../pageLayout/PageLayout';
import { SyncDetails } from '../syncDetails/SyncDetails';
import { HistoricalSync } from './HistoricalSync';
import { useTranslation } from 'react-i18next';
import { SyncTable } from './SyncTable';
import useConnectionMode from '../../hooks/useConnectionMode';
import { useSelector } from 'react-redux';
import { useGetSyncDataQuery, useRepostSyncMutation } from '../../services/syncService';
import { useNotification } from '../../utils/helpers/notifications';
import amIVisible from '../../componentControl/registry';

export const SyncLogs = ({ isTypePo = false }) => {
  const [, swName] = useConnectionMode();

  const [syncDetailsInfo, setSyncDetailsInfo] = useState({
    show: false,
  });

  const { t } = useTranslation();
  
  const { syncData } = useSelector(state => state.sync)

  // const { isOpsAction } = useSelector(state => state.auth)

  const [repost] = useRepostSyncMutation()

  const [key] = useState(Math.random())

  const { refetch } = useGetSyncDataQuery({ key })

  const { showNotification } = useNotification()

  const elem = document.getElementsByClassName("ReactModal__Body--open")

  const openSyncDetailsModal = (data) => {
    setSyncDetailsInfo({
      show: true,
      data,
    });

    if (elem) {
      if (elem?.length > 0) {
        elem[0].style.overflow = "hidden"
      }
    }
  };

  const closeSyncDetailsModal = (operation) => {
    setSyncDetailsInfo({ show: false });
    
    if (elem) {
      if (elem?.length > 0) {
        elem[0].style.overflow = "scroll"
      }
    }
  };

  const makeRequest = (id) => {
    repost({
      url: "resync/",
      body: {
        id,
        repostingData: false,
        userType: "user",
        resyncPO: isTypePo
      }
    })
    .unwrap()
    .then(() => {
      showNotification("Data Queued")
    })
    .catch(() => {
      showNotification("Something went wrong", 'error')
    })
  }

  return <>
    {syncDetailsInfo.show &&
      <SyncDetails
        closeModal={closeSyncDetailsModal}
        rowData={syncDetailsInfo.data}
        swName={swName}
        isTypePo={isTypePo}
      />
    }
    <PageLayout
      title={t('Sync history')}
      description={t('SyncHistoryDescription')}
      noBottomBar
      showBack
    >
      <>
      {(amIVisible("Historical Sync") && false) && <HistoricalSync isTypePo={isTypePo} />}
        <Card>
          <CardSection style={{ padding: 0 }}>
            <SyncTable
              refreshData={refetch}
              rows={syncData}
              makeRequest={makeRequest}
              openSyncDetailsModal={openSyncDetailsModal}
              isTypePo={isTypePo}
            />
          </CardSection>
        </Card>
      </>
    </PageLayout>
  </>;
};
