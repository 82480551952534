import {
  getAccountsUrl,
  getItemsUrl,
  getTaxesUrl,
  getPaymentMethodsUrl,
  getLocationsUrl,
  getDiscountsUrl,
  getTaxAgencyUrl,
} from '../../utils/urls/url';

import axios from 'axios';
import { getHeaders } from '../../utils/helpers/commonHelpers';

export const getTaxAgency = (location) => {
  return axios.get(getTaxAgencyUrl(), {
    headers: getHeaders(location)
  })
};

export const getAccounts = (location, params) => {
  return axios.get(getAccountsUrl(), {
    headers: getHeaders(location),
    params
  });
};

export const getItemsList = (params, location) => {
  const url = getItemsUrl();
  return axios.get(url, {
    headers: getHeaders(location),
    params,
  });
};

export const getTaxesMappingList = (params, location) => {
  const url = getTaxesUrl();
  return axios.get(url, {
    headers: getHeaders(location),
    params,
  });
};

export const getPaymentMethodsList = (params, location) => {
  const url = getPaymentMethodsUrl();
  return axios.get(url, {
    headers: getHeaders(location),
    params,
  });
};

export const getLocationsList = (location) => {
  return axios.get(getLocationsUrl(), {
    headers: getHeaders(location),
  });
};

export const getDiscountsList = (location) => {
  return axios.get(getDiscountsUrl(), {
    headers: getHeaders(location),
  });
};

