import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';

export const LayAway = ({ formik }) => {
    const isMapLayAway = formik?.values?.isMapLayAway;
    const { t } = useTranslation();

    return <SwitchLayout
        heading={t('Lay Away')}
        description={"Record the lay away transactions as separate open transactions in accounting software."}
        switchFieldName="isMapLayAway"
        isSwitchOn={isMapLayAway}
    ></SwitchLayout>;
};