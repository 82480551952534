import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const softwareListSlice = createSlice({
  name: 'softwareList',
  initialState,
  reducers: {
    setSoftwareList: (state, action) => {
      return action.payload;
    },
    resetSoftwareList: (state) => {
      return [];
    }
  }
});

export const { setSoftwareList } = softwareListSlice.actions;

export default softwareListSlice.reducer;
