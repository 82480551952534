import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useState } from 'react';
import { useEffect } from 'react';
import useAccounts from '../../hooks/useAccounts';
import { useGetCategoriesQuery } from '../../services/dashboardService';
import { FullPageWrapper } from '../custom/IndividualMappingLayout';
import amIVisible from '../../componentControl/registry';

export const IndividualCategoriesMapping = ({ formik }) => {

    const { t } = useTranslation()

    const [isLoadingAccounts, accountsList] = useAccounts('items');

    const { currentSw, categoriesLabels } = useSelector(state => state.dashboard)

    const { categorySpecificInventoryCogsAccounts, isParentCategory } = formik.values;

    const [parentCategory, setCategory] = useState(isParentCategory);

    const [isCategoryChanged, setChanged] = useState(false);

    const isDynamicMapping = amIVisible("enter field for product and categories individual")

    const { isLoading, isFetching, refetch } = useGetCategoriesQuery({
        recordItems: false,
        superParentCategory: Number(parentCategory)
    }, {
        skip: isDynamicMapping,
        selectFromResult: ({ data, ...rest }) => {
            let labels = data?.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            labels = _.filter(labels, item => {
                return !_.keys(_.pickBy(categorySpecificInventoryCogsAccounts, (items) => items !== '')).includes(item?.value + '')
            })

            return {
                data: labels,
                ...rest
            }
        }
    })

    useEffect(() => {
        if (isCategoryChanged) {
            setChanged(false)
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCategoryChanged])

    const refresh = (value) => {
        setCategory(value)
        setChanged(true)
    }

    return (
        <>
            <FullPageWrapper
                title={t('Map Categories')}
                fullPageDesp={t(`Individual categories can be mapped to unique accounts in ${currentSw}. All unassigned and new categories will be assigned to the default category account.`, { swName: currentSw })}
                formik={formik}
                formikFieldName={"categoriesMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Categories"}
                parentCategory={parentCategory}
                isDynamicMapping={isDynamicMapping}
                options={accountsList}
                labelOption={categoriesLabels}
                refetch={refresh}
                advanceMapping
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}