import { Heading3 } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useAccounts from '../../hooks/useAccounts';

export const MapCashRounding = () => {
  const [isLoading, accountsList] = useAccounts('chargebacks');

  return <Box padding="0.75rem">
    <Heading3 mb="0.375rem">Cash Rounding</Heading3>
    {/* <Text color="gray-600" mb="1.5rem" mr="3.25rem" size="small">
      {t('MapTipsDesc', { swName: softwareName })}
    </Text> */}
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="Tips">
            Select a Cash Rounding account
        </LabelRequired>
        <Autocomplete
          isLoadingOptions={isLoading}
          id="cashRounding"
          name="cashRounding"
          options={accountsList}
          placeholder={
            'Select an account'
          }
        />
      </Box>
    </Flex>
  </Box>;
};
