import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAppSubmitting } from '../redux/reducer/globalsReducer';

export const useAppSubmitting = () => {
  const isSubmitting = useSelector(state => state.globals.isSubmitting);
  const dispatch = useDispatch();

  const setSubmitting = (args) => {
    dispatch(setAppSubmitting(args));
  };

  return [isSubmitting, setSubmitting];
};

export default useAppSubmitting;
