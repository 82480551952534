import map from "./config.json"

import store from "../redux/store"

const amIVisible = (name) => {
    const target = store.getState().auth.portal;

    return map[name]?.includes(target)    
}

export const checkVisible = (name, target) => {
    return map[name]?.includes(target)
}

export default amIVisible;

