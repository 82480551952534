import Lottie from "react-lottie";
import anim from "../images/loading.json"
// import { Flex } from '@react-css/flex';
import { useAuthorizeOAuthQuery } from "../services/authService";
import { parse as parseQuery } from 'query-string';
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setToken } from "../slices/authSlice";
import AuthRetry from "./components/authRetry";
import { Flex } from '@lightspeed/flame/Core';
import { moveToNextScreen } from "../utils/helpers/commonHelpers";

export const CompleteOAuth = ({ navigate, location }) => {

    const params = parseQuery(location.search);

    const { data, isSuccess, isError, refetch } = useAuthorizeOAuthQuery(params)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isSuccess) {
            dispatch(setToken(data.token))
            moveToNextScreen(navigate, dispatch, '../dashboard', data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    return (
        <>
            <Flex alignItems={'center'} justifyContent={'center'} style={{ width: "100%", height: "100%" }}>
                <Flex flexDirection="column" alignItems={"center"}>
                    {!isError ?
                        <>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: anim,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                className="lottie"
                                height={80}
                                width={150}
                                background="transparent"
                                isClickToPauseDisabled
                            />
                            <p style={{ fontSize: "34px", fontWeight: "600", color: "black", marginTop: "4px" }}>Authorizing</p>
                        </>
                        : <AuthRetry retry={refetch} />}
                </Flex>
            </Flex>
        </>

    )
}