import { Button } from '@lightspeed/flame/Button';
import { IconSync } from '@lightspeed/flame/Icon/Sync';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setRefreshAccounts } from '../../slices/dashboardSlice';
import { useSelector } from 'react-redux';
import { useNotification } from '../../utils/helpers/notifications';

export const RefreshAccountsSkeleton = ({ handleClick }) => {
  const { t } = useTranslation();
  const { isRefreshAccount } = useSelector(state => state.dashboard)

  return <Button loading={isRefreshAccount} mt="1.125rem" padding="9px" pr="18px" onClick={handleClick}>
    <IconSync />
    {t('Refresh accounts')}
  </Button>;
};

export const RefreshAccounts = ({ dirty: isDirty = false }) => {
  const dispatch = useDispatch();

  const { showNotification } = useNotification()

  const handleClick = () => {
    if (!isDirty) {
      dispatch(setRefreshAccounts(true))
    } else {
      showNotification("Save current changes to refresh the accounts.", 'error')
    }
  };

  return <RefreshAccountsSkeleton handleClick={handleClick} />;
};
