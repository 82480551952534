import React from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Autocomplete } from '../fields';
import { Text } from '@lightspeed/flame/Text';
import { Label } from '@lightspeed/flame/FormField';
import { useGetLocationsQuery } from '../../services/dashboardService';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import { useNotification } from '../../utils/helpers/notifications';

export const LocationMapping = ({ formik }) => {
    const { isMapClass } = formik.values;
    const [, swName] = useConnectionMode();
    const { t } = useTranslation();
    
    const { data: locationsList, isFetching: fetchingLocation, isLoading: loadingLocation } = useGetLocationsQuery({ class: false }, { skip: !isMapClass ?? true })

    const isLoading = fetchingLocation || loadingLocation

    const { showNotification } = useNotification()

    useEffect(() => {
        if (locationsList && locationsList?.length === 0) {
            showNotification('Location not enabled in accounting software', 'error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationsList])

    return (
        <SwitchLayout
            heading={t('Location', { swName })}
            description={`Adds a Location field on forms so you can assign transactions to different locations like stores, sales regions, and counties.`}
            switchFieldName="isMapClass"
            isSwitchOn={isMapClass}
            isLoading={isLoading}
        >
            <Flex>
                <Box width="49%" pr="1.1rem">
                    <Label htmlFor="locationMapping">
                        {t('Select a Location')}
                    </Label>
                    <Autocomplete
                        id="locationMapping"
                        name="locations"
                        isLoadingOptions={isLoading}
                        options={locationsList}
                        placeholder={t('Select a location')}
                        formik={formik}
                    />
                </Box>
            </Flex>
            <Flex mt="1rem" ml=".2rem">
                <><Text fontWeight={"bold"} mr=".3rem">Required : </Text> Location mapping </>
            </Flex>
        </SwitchLayout>
    )
}