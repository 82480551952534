import { createSlice } from '@reduxjs/toolkit';

const roleData = {
  'user': {
    type: 3,
    name: 'user',
    isUser: true,
  },
  'support': {
    type: 1,
    name: 'support',
    isSupport: true,
  }
};

const initialState = {
  role: roleData['user'],
};

const initialParamsSlice = createSlice({
  name: 'initialParams',
  initialState,
  reducers: {
    setInitialParams: (state, action) => {
      ['accountId', 'posType', 'sourceId', 'token'].forEach((key, index) => {
        state[key] = action.payload[index];
      });

      // Set role data
      const roleValue = action.payload[5];
      let role;
      if (roleValue) {
        const roleString = `${roleValue}`.toLowerCase();
        role = roleData[roleString];
      }

      if (!role) {
        role = roleData['user'];
      }

      state.role = role;
    },
  }
});

export const { setInitialParams } = initialParamsSlice.actions;

export default initialParamsSlice.reducer;
