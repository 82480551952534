import * as Yup from 'yup';

export const getValidationSchema = (t) => {
  const schemaObject = {
    postingMethod: Yup.string()
      .oneOf(['auto', 'journalEntry', 'invoiceCategory', 'invoice']),
    email1: Yup.string()
      .test(
        'Required',
        t('Email is required'),
        (value) => Boolean(value),
      ).test(
        'no-space',
        t('Spaces not allowed'),
        (value, context) => value && value.split('').some(ch => ch !== ' '),
      ).test(
        'has-at',
        t('Must have one @ symbol'),
        (value, context) => value && value.split('@').length === 2,
      ).test(
        'has-domain',
        t('Must have a domain'),
        (value, context) => value && Boolean(value.split('@')[1]),
      ).test(
        'one-dot',
        t('At least one dot character'),
        (value, context) => value && value.split('').filter(ch => ch === '.').length >= 1,
      ).test(
        'valid-domain',
        t('Must have a valid domain'),
        (value, context) => value &&
          Boolean(value.split('@')[1]) &&
          value.split('@')[1].split('.').filter(Boolean).length > 1,
      ).test(
        'valid email',
        t('Email is not valid'),
        (value, context) => {
          // eslint-disable-next-line no-useless-escape
          const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

          let result = false;
          if (value) {
            result = value.match(rEmail);
          }

          return result;
        }

      ),
    phoneNumber: Yup.string().min(7, "Enter valid phone number").max(13, "Enter valid phone number"),
    defaultDiscountAccountName: Yup.string()
      .when(['postingMethod', 'isSentDiscount'], {
        is: (method, isEnabled) => isEnabled && (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    defaultPaymentAccountName: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    incomeAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    Tips: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    storeCredit: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    giftCard: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    seriesAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    membershipAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    onAccountAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    storeCreditExpenseAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    loyalityAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    loyaltyExpenseAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    nonCashFeeAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    cashRounding: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    ServiceCharge: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    defaultCustomer: Yup.string()
      .required(t('Required')),
    preferredSyncTime: Yup.string()
      .required(t('Required')),
    preferredTzCountry: Yup.string()
      .required(t('Required')),
    preferredSyncTz: Yup.string()
      .required(t('Required')),
    taxes: Yup.array()
      .of(
        Yup.object().shape({
          account: Yup.string().nullable(true).required(t('Required')),
        })
      ),
    poTaxMapping: Yup.array()
      .of(
        Yup.object().shape({
          account: Yup.string().nullable(true).required(t('Required')),
        })
      )
  };

  schemaObject.serviceCharge = Yup.string()
    .when('postingMethod', {
      is: method => (method !== 'auto'),
      then: Yup.string().required(t('Required')),
    });

  schemaObject.shipping = Yup.string()
    .when(['postingMethod', 'POInventoryCogs', 'isSentPurchaseOrder'], {
      is: (method, isEnabled, isSentPurchaseOrder) => {
        return (method !== 'auto') && isEnabled && isSentPurchaseOrder;
      },
      then: Yup.string().required(t('Required')),
    });

  schemaObject.freightCharges = Yup.string()
    .when(['postingMethod', 'POInventoryCogs', 'isSentPurchaseOrder'], {
      is: (method, isEnabled, isSentPurchaseOrder) => {
        return (method !== 'auto') && isEnabled && isSentPurchaseOrder;
      },
      then: Yup.string().required(t('Required')),
    });

  schemaObject.otherExpenses = Yup.string()
    .when(['postingMethod', 'POInventoryCogs', 'isSentPurchaseOrder'], {
      is: (method, isEnabled, isSentPurchaseOrder) => {
        return (method !== 'auto') && isEnabled && isSentPurchaseOrder;
      },
      then: Yup.string().required(t('Required')),
    });

  schemaObject.defaultInventoryAccountName = Yup.string()
    .when(['postingMethod', 'POInventoryCogs', 'isSentCogs', 'isSentPurchaseOrder'], {
      is: (method, isEnabled, isSentCogs, isSentPurchaseOrder) => {
        return (method !== 'auto') && isEnabled && (isSentCogs || isSentPurchaseOrder);
      },
      then: Yup.string().required(t('Required')),
    });

  schemaObject.defaultCogsAccountName = Yup.string()
    .when(['postingMethod', 'POInventoryCogs', 'isSentCogs', 'isSentPurchaseOrder', 'trackInventory'], {
      is: (method, isEnabled, isSentCogs, isSentPurchaseOrder, trackInventory) => {
        return (method !== 'auto') && isEnabled && (isSentCogs || (isSentPurchaseOrder && trackInventory));
      },
      then: Yup.string().required(t('Required')),
    });

  schemaObject.defaultOverShortAccountName = Yup.string()
    .when(['isSentOverShort'], {
      is: (isEnabled) => isEnabled,
      then: Yup.string().required(t('Required')),
    });

  schemaObject.pettyCashPayoutsAccount = Yup.string()
    .when(['isSentPettyCashPayouts'], {
      is: (isEnabled) => isEnabled,
      then: Yup.string().required(t('Required')),
    });

  schemaObject.adjustmentAccount = Yup.string()
    .when(['isSentAdjustment'], {
      is: (isEnabled) => isEnabled,
      then: Yup.string().required(t('Required')),
    });

  schemaObject.recordingFeeAccount = Yup.string()
    .when(['isSentRecordingFee'], {
      is: (isEnabled) => isEnabled,
      then: Yup.string().required(t('Required')),
    });

  schemaObject.locations = Yup.string()
    .when(['isMapClass'], {
      is: (isEnabled) => isEnabled,
      then: Yup.string().required(t('Required')),
    });

  schemaObject.classes = Yup.string()
    .when(['isMapClass'], {
      is: (isEnabled) => isEnabled,
      then: Yup.string().required(t('Required')),
    });

  return Yup.object(schemaObject);
};