import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useAccounts from '../../hooks/useAccounts';
import { LabelRequired } from '../custom/LabelRequired';
import amIVisible from '../../componentControl/registry';
import IndividualPo from './individualPo';

const DefaultAccountLayout = ({ heading, description, children }) => {
  return <Box mb="1.5rem">
    <Heading3 mb="0.375rem">
      {heading}
    </Heading3>
    <Text color="gray-600" mr="3.25rem"
      mb="1.5rem"
      size="small"
    >
      {description}
    </Text>
    {children}
  </Box>;
}

const DefaultInventoryAccount = () => {
  const [isLoading, accountsList] = useAccounts('inventory');
  const [, inventoryCogs] = useAccounts('inventoryCogs');
  const { t } = useTranslation();

  let listOfAccounts = accountsList

  if (amIVisible("show expense account in inventory")) {
    listOfAccounts = inventoryCogs
  }

  return <DefaultAccountLayout
    heading={t('Default inventory account')}
    description={t('Default Inventory desc')}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultInventoryAccountName">
          {t('Default inventory account')}
        </LabelRequired>
        <Autocomplete
          id="defaultInventoryAccountName"
          name="defaultInventoryAccountName"
          isLoading={isLoading}
          options={listOfAccounts}
          placeholder={t('Select an account')}
        />
      </Box>
    </Flex>
  </DefaultAccountLayout>;
};

const DefaultCogsAccount = () => {
  const [isLoading, accountsList] = useAccounts('cogs');
  const { t } = useTranslation();

  return <DefaultAccountLayout
    heading={t('Default COGS account full')}
    description={t('Default COGS desc')}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultCogsAccountName">
          {t('Default COGS account')}
        </LabelRequired>
        <Autocomplete
          id="defaultCogsAccountName"
          name="defaultCogsAccountName"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
        />
      </Box>
    </Flex>
  </DefaultAccountLayout>;
};

export const MapDefaultIndividualCogsInventory = ({ formik, ...props }) => {

  const {
    isSentPurchaseOrder,
    trackInventory,
    isSentCogs
  } = formik.values;

  const showCogsMapping = (isSentCogs || (isSentPurchaseOrder && trackInventory)) && amIVisible("cogs account");

  return <Box>
    {(isSentCogs || isSentPurchaseOrder) && amIVisible("inventory account") &&
      <DefaultInventoryAccount />
    }
    {showCogsMapping &&
      <DefaultCogsAccount />
    }
    <>
      <IndividualPo formik={formik} {...props} />
    </>
  </Box>;
};
