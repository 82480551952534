import { useEffect, useState } from 'react';
import { TextLink, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { Bone } from '@lightspeed/flame/Bone';
import { useSelector, useDispatch } from 'react-redux';
import { IconCalendar } from '@lightspeed/flame/Icon/Calendar';
import { useTranslation } from 'react-i18next';
import useAppConnected from '../../hooks/useAppConnected';
import useAppSyncing from '../../hooks/useAppSyncing';
import amIVisible from '../../componentControl/registry';
import { setCurrentComponent, setRefetchSyncCount } from '../../slices/dashboardSlice';
import { useNotification } from '../../utils/helpers/notifications';
import { BiRefresh } from 'react-icons/bi';

const SyncStatusBox = ({ alertType, title, info, showLinks, navigate, loading, formik }) => {
  const { t } = useTranslation();
  const showSyncLink = amIVisible("sync history button");
  const showPoLink = amIVisible("Po sync history button");
  const dispatch = useDispatch()
  const { showNotification } = useNotification()

  const { isFetchingSyncCount } = useSelector(state => state.dashboard)

  const handleNavigation = (comp) => {
    if (formik?.dirty) {
      showNotification("Save the current changes to proceed", "error")
    } else {
      dispatch(setCurrentComponent(comp))
    }
  }

  return <Box width="50%" padding="0.75rem">
    <AlertInCard
      height="100%"
      style={{ alignItems: 'flex-start' }}
      type={alertType}
      noCloseBtn
    >
      {<div style={{ marginLeft: ".8rem" }}>
        {
          loading ?
            <>
              <Flex>
                <Bone height={"1rem"} width="200px" />
              </Flex>
              <Flex mt={"1rem"} mb="1rem">
                <Bone height={".8rem"} width="150px" />
              </Flex>
            </>
            :
            <>
              <Text
                color="textHeading"
                fontWeight="bold"
                fontSize="text"
                mt={0} mr={0} mb={1} ml={0}
              >
                {title}
              </Text>
              {amIVisible("Sync Count") && (
                <>
                  {isFetchingSyncCount ? <Bone height={".8rem"} width="100px" /> : <Text mb="1.125rem" color="gray-600">{info}</Text>}
                </>
              )}
            </>
        }
      </div>}
      <div>
        {showSyncLink && <Box mt="0.75rem">
          <TextLink fontWeight="bold" onClick={() => handleNavigation("sync history")}>
            <IconCalendar color="blue-500" style={{ marginRight: "0.75rem" }} /> {t('Sync history')}
          </TextLink>
        </Box>}
        {showPoLink &&
          <Box mt="0.75rem">
            <TextLink fontWeight="bold" onClick={() => handleNavigation("po sync history")}>
              <IconCalendar color="blue-500" style={{ marginRight: "0.75rem" }} /> PO Sync history
            </TextLink>
          </Box>
        }
      </div>

    </AlertInCard>
  </Box>;
};

const toDateTimeString = (locale, timeStamp) => {
  let result;

  if (timeStamp) {
    const dateFormatter = new Intl.DateTimeFormat(locale, { dateStyle: 'long' });
    const timeFormatter = new Intl.DateTimeFormat(locale, { timeStyle: 'short' });
    const nextSyncOnDate = timeStamp ? new Date(timeStamp) : '';
    result = nextSyncOnDate && `${dateFormatter.format(nextSyncOnDate)}, ${timeFormatter.format(nextSyncOnDate)}`;
  }

  return result;
};

const Configured = ({ swName, navigate, fetchedConfig, ...props }) => {
  const { syncSuccessCount, syncFailedCount } = useSelector(state => state.dashboard.syncCounts);
  const hasErrors = syncFailedCount > 0;
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const getGenericTitle = () => {
    return hasErrors ? t('Sync error') : t('Sync enabled');
  };

  return <SyncStatusBox
    alertType={hasErrors ? 'warning' : 'success'}
    title={
      <>
        <Flex>
          {getGenericTitle()}
          <Flex mb="5px" pl="5px">
            <BiRefresh size={"22"} onClick={() => { dispatch(setRefetchSyncCount(true)) }} />
          </Flex>
        </Flex>
      </>
    }
    info={hasErrors ?
      t('SyncErrorDesc', {
        count: syncFailedCount,
        swName
      })
      :
      t('SyncSuccessDesc', {
        count: syncSuccessCount,
        swName,
        syncTime: ""
      })
    }
    showLinks={true}
    navigate={navigate}
    fetchedConfig={fetchedConfig}
    {...props}
  />;
};

const Disconnected = ({ swName, isAppSyncing, navigate, ...props }) => {
  const { t } = useTranslation();

  return <SyncStatusBox
    alertType="danger"
    title={isAppSyncing === false ?
      t('Sync error') :
      t('ConnectionLostDesc', { swName })
    }
    info={isAppSyncing === false ?
      t('UpdateMapping') :
      t('Reconnect to resume syncing')
    }
    showLinks={isAppSyncing === false}
    navigate={navigate}
    {...props}
  />;
};

const Paused = ({ navigate, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  return <SyncStatusBox
    alertType="warning"
    title={
      <Flex>
        {t('Sync paused')}
        <Flex mb="5px" pl="5px" >
          <BiRefresh size={"22"}  onClick={() => { dispatch(setRefetchSyncCount(true)) }} />
        </Flex>
      </Flex>
    }
    info={t('SyncPausedInfo')}
    showLinks={true}
    navigate={navigate}
    {...props}
  />;
};

const SyncStatus = ({ isAppSyncing, formik, ...props }) => {
  const { fetchedConfig } = props;
  const [isPaused, setIsPaused] = useState(false);
  const savedForms = useSelector(state => state.forms);
  const lastConfigSource = savedForms.meta.lastSave.source;
  const { syncRange } = formik?.values;

  const { isSynced: isConnected } = useSelector(state => state.dashboard)

  useEffect(() => {
    let newValue;
    if (lastConfigSource === 'save') {
      newValue = syncRange ? syncRange === '0' : false;
    } else {
      newValue = fetchedConfig && fetchedConfig?.syncEnabled === 0 ? true : false;
    }

    if ([true, false].includes(newValue) && (newValue !== isPaused)) {
      setIsPaused(newValue);
    }
  }, [fetchedConfig, isPaused, lastConfigSource, syncRange]);

  return <>
    {(isConnected) ?
      <>
        {isPaused ?
          <Paused formik={formik} {...props} /> :
          <Configured formik={formik} {...props} />
        }
      </> :
      <Disconnected formik={formik} isAppSyncing={isAppSyncing} {...props} />
    }
  </>;
};

const SyncStatusWrapper = ({ isSyncStatusLoading, softwareName, ...props }) => {
  const [isConnected] = useAppConnected();
  const [isAppSyncing] = useAppSyncing();
  const isLoading = props.isLoading || isSyncStatusLoading || (isConnected === null);
  const { t } = useTranslation();

  return <>
    {
      <SyncStatus
        isConnected={isConnected}
        loading={isLoading}
        isAppSyncing={isAppSyncing}
        swName={softwareName || t('Accounting Software')}
        navigate={props.navigate}
        fetchedConfig={props.fetchedConfig}
        {...props}
      />
    }
  </>;
};

export { SyncStatusWrapper as SyncStatus };
