import { Flex, Box } from '@lightspeed/flame/Core';
import { Heading2, Text, TextLink } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { useSelector } from 'react-redux';
import { IconWarning } from '@lightspeed/flame/Icon/Warning';
import { IconVerified } from '@lightspeed/flame/Icon/Verified';
import { IconDanger } from '@lightspeed/flame/Icon/Danger';
import { getConnectionModeName } from '../../utils/helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
import { Button } from '@lightspeed/flame/Button';
import { Modal } from 'antd';
import { useState } from 'react';
import { Divider } from '@lightspeed/flame/Divider';
import { useConnectionModeMutation } from '../../services/authService';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLocationId, setLocationList } from '../../slices/authSlice';
import { setConnectionMode, setGlobalLoading } from '../../slices/dashboardSlice';
import Logout from '../custom/logout';
import Copyrights from '../custom/copyright';
import { SessionTimeoutError } from '../custom/sessionTimeout';
import moment from 'moment/moment';

export const MultiLocation = ({ navigate }) => {

    const { fullName, locationList } = useSelector(state => state.auth)
    const { t } = useTranslation()

    const [copyLocation, { isLoading, isSuccess, originalArgs }] = useConnectionModeMutation()

    const [showModal, setShowModal] = useState(false)
    const [listOfConnectedAccounts, setConnectedAccounts] = useState([])
    const [selectedLoc, setLoc] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        if (isSuccess) {
            dispatch(setLocationList(locationList.map((loc) => {
                const currentLocation = Object.assign({}, loc)
                if (currentLocation?.id === selectedLoc?.id) {
                    currentLocation.showSetupAccountingButton = false;
                    currentLocation.isParentLocation = false;
                    
                    let connectionMode = originalArgs?.body?.connectionMode

                    if (connectionMode === "Sage One") {
                        connectionMode = "Sage Business Cloud"
                    }

                    if (connectionMode) {
                        currentLocation.connectionMode = connectionMode;
                    }
                }

                return currentLocation
            })))

            navigate("../dashboard")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    const handleBtnClick = (location) => {
        const { id, showSetupAccountingButton: isSetup, connectionMode } = location

        if (isSetup) {
            let connectedAccounts = locationList.filter((loc) => !loc?.showSetupAccountingButton)

            if (locationList) {
                if (locationList[0]?.isParentLocation) {
                    connectedAccounts = connectedAccounts.filter((loc) => loc?.isParentLocation)
                }
            }

            if (connectedAccounts.length > 0) {
                setShowModal(true)
                setConnectedAccounts(connectedAccounts)
                setLoc(location)
            } else {
                dispatch(setGlobalLoading(true))
                dispatch(setLocationId(id ?? ""))
                navigate("../user-onboard")
            }
        } else {
            dispatch(setGlobalLoading(true))
            dispatch(setLocationId(id ?? ""))
            dispatch(setConnectionMode(connectionMode ?? ""))
            navigate("../dashboard")
        }

    }

    return (
        <>
            <SessionTimeoutError navigate={navigate} />
            <Flex minHeight={"100vh"} padding="1rem" mt="1rem" flexDirection={"column"}>
                <Box pb="1px">
                    <Heading2 mb="0.375rem">{fullName} Accounting</Heading2>
                    <Text size="small" color="gray-600">
                        Eliminate manual data entry by automating your accounting tasks.
                    </Text>
                </Box>
                <Flex flexDirection={"column"} mt={"1rem"} p=".5rem">
                    {locationList?.map((location) => {
                        const { id, isConfigured, syncEnabled, name, companyName, showSetupAccountingButton, connectionMode, address, nextSyncDate } = location

                        return (
                            <>
                                <Card mt={"1.3rem"} p={".2rem"}>
                                    <CardSection>
                                        <Flex justifyContent="space-between" padding="0.75rem" alignItems="center">
                                            <Box width={"100%"}>
                                                <Flex flexDirection={"column"}>
                                                    <Flex alignItems={"center"}>
                                                        <>
                                                            <Text fontWeight="bold">{name}</Text>
                                                            <Text fontSize="text-s" color="gray-600" mt="0.375rem">
                                                                {address}
                                                            </Text>
                                                        </>
                                                        <Flex >
                                                            <Text>{companyName}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Flex justifyContent={"space-between"}>
                                                    <Flex flexDirection={"column"} mt="1rem">
                                                        <Text fontWeight="bold">{getConnectionModeName(t, connectionMode)}</Text>
                                                        {!showSetupAccountingButton &&
                                                            <>
                                                                {<Flex mt=".5rem">
                                                                    <Flex mt=".2rem">
                                                                        {isConfigured ?
                                                                            <>{!syncEnabled && <IconWarning color="orange-500" />}</> :
                                                                            <IconDanger color="maple-700" />
                                                                        }
                                                                    </Flex>
                                                                    {(!isConfigured) ?
                                                                        <Text pl=".5rem" pt=".2rem">{t('UpdateMapping')}</Text>
                                                                        : (!syncEnabled && isConfigured)
                                                                            ? <Text pl=".5rem" pt=".2rem">{t('SyncPausedInfo')}</Text> :
                                                                            ""}
                                                                </Flex>}
                                                                <>
                                                                    {(isConfigured && syncEnabled && !showSetupAccountingButton) ?
                                                                        (
                                                                            <>
                                                                                <Flex>
                                                                                    <Flex mt=".2rem">
                                                                                        <IconVerified color="green-700" />
                                                                                    </Flex>
                                                                                    <Text pl=".5rem" pt=".1rem">Connected</Text>
                                                                                </Flex>
                                                                                <Text fontWeight='bold' mt=".8rem">
                                                                                    Next Sync Date : {moment(moment())?.add(1, 'days')?.format("YYYY-MM-DD")}
                                                                                </Text>
                                                                            </>
                                                                        )
                                                                        : <></>}
                                                                </>
                                                            </>
                                                        }
                                                    </Flex>
                                                    <Flex>
                                                        <Button
                                                            variant={showSetupAccountingButton ? 'secondary' : 'neutral'}
                                                            fill={true}
                                                            style={{ height: "36px" }}
                                                            onClick={() => handleBtnClick({ id, showSetupAccountingButton, connectionMode })}
                                                            loading={isLoading}
                                                            disabled={isLoading}
                                                        >
                                                            {showSetupAccountingButton ?
                                                                'Setup Accounting' :
                                                                'Edit mapping'
                                                            }
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </CardSection>
                                </Card>
                                <Modal title="Setup with existing account" maskClosable={false} closable={false} open={showModal} footer={null}>
                                    <Flex p={"1rem"} flexDirection="column">
                                        <Text>Use an existing account configured for another location or setup a different account for this location.</Text>
                                        <Flex mt="1.5rem" flexDirection={"column"}>
                                            <Text fontWeight="bold" fontSize="text-s">{getConnectionModeName(t, connectionMode)} existing accounts :</Text>
                                            <Flex flexDirection={"column"} mt=".4rem">
                                                {
                                                    listOfConnectedAccounts?.map((loc) => (
                                                        <TextLink
                                                            fontWeight="bold"
                                                            fontSize="text-s"
                                                            onClick={() => {
                                                                setShowModal(false)
                                                                dispatch(setLocationId(selectedLoc?.id))
                                                                dispatch(setConnectionMode(loc?.connectionMode))
                                                                
                                                                let connectionMode = loc?.connectionMode

                                                                if (connectionMode === "Sage Business Cloud") {
                                                                    connectionMode = "Sage One"
                                                                }

                                                                copyLocation({ method: "POST", body: { connectionMode, locationFrom: loc?.id } })
                                                            }}
                                                        >
                                                            {getConnectionModeName(t, loc?.connectionMode)} {loc?.name}
                                                        </TextLink>
                                                    ))
                                                }
                                            </Flex>
                                        </Flex>
                                        <Divider mt="2rem" />
                                        <Flex width="100%" justifyContent="flex-end" mt={"1rem"}>
                                            <Button
                                                onClick={() => {
                                                    setShowModal(false)
                                                    setLoc({})
                                                    setConnectedAccounts([])
                                                }}
                                                mr="0.75rem"
                                            >Cancel</Button>
                                            <Button
                                                variant="secondary" fill="true"
                                                onClick={() => {
                                                    dispatch(setGlobalLoading(true))
                                                    dispatch(setLocationId(selectedLoc?.id))
                                                    navigate('../user-onboard')
                                                }}
                                            >Setup another account</Button>
                                        </Flex>
                                    </Flex>
                                </Modal>
                            </>
                        )
                    })}
                </Flex>
            </Flex>
            <Logout navigate={navigate} />
            <Copyrights />
        </>
    )
}