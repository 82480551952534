import { parse as parseQuery } from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpsAction, setLocationId, setLocationList, setToken } from '../../slices/authSlice';
import { setCurrentComponent } from '../../slices/dashboardSlice';
import AuthLoading from '../../Auth/components/authLoading';
import _ from 'lodash';
import { useAuthenticateMutation } from '../../services/authService';
import { useNotification } from '../../utils/helpers/notifications';
import { Flex } from '@lightspeed/flame/Core';
import { setSoftwareList } from '../../redux/reducer/softwareListReducer';

const Admin = ({ navigate, location }) => {

    const { token, opsAction, locationId } = parseQuery(location?.search);

    const dispatch = useDispatch()

    const { locationid: savedLocationId, token: savedToken } = useSelector(state => state.auth)

    const [authenticate] = useAuthenticateMutation()

    const { showNotification } = useNotification()

    useEffect(() => {
        dispatch(setToken(token))
        dispatch(setLocationId(locationId))
        dispatch(setIsOpsAction(true))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!_.isEmpty(savedToken) && !_.isEmpty(savedLocationId)) {
            authenticate()
                .then(res => {
                    const resData = res?.data
                    const locations = resData.locationList;

                    showNotification(resData?.message)

                    dispatch(setSoftwareList(resData.accountingSoftware));
                    dispatch(setLocationList(locations));

                    switch(opsAction) {
                        case "salesHistory":
                            dispatch(setCurrentComponent("sync history"))
                            break
                        case "poHistory":
                            dispatch(setCurrentComponent("po sync history"))
                            break
                        default:
                            break
                    }

                    navigate("../dashboard");
                })
                .catch((err) => {
                    if (err?.data?.message) {
                        showNotification(err?.data?.message, "error")
                    }
                })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedLocationId, savedToken])

    return ( 
        <Flex style={{ height: "100%" }} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <AuthLoading />
        </Flex>
     );
}
 
export default Admin;