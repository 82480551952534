import { useState, useEffect } from 'react';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Button } from '@lightspeed/flame/Button';
import { FormHelper, Label } from '@lightspeed/flame/FormField';
import { Input } from '@lightspeed/flame/Input';
import { Card, CardHeader, CardSection, CardFooter } from '@lightspeed/flame/Card';
import { TextLink } from '@lightspeed/flame/Text';
import ct from "countries-and-timezones"
import { Autocomplete } from '@lightspeed/flame/Autocomplete';
import { useSignupMutation } from '../../services/authService';
import { useNotification } from '../../utils/helpers/notifications';
import { some } from 'lodash';
import { isEmpty } from 'lodash';
import signupSchema from "./signupSchema"
import Copyrights from '../../components/custom/copyright';
import { Modal, Spin } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneNumber from 'react-phone-number-input/input';
import { getCountryCallingCode } from 'react-phone-number-input'
import { Text } from '@lightspeed/flame/Text';
import { getTZ } from '../../utils/helpers/commonHelpers';
import { BiLinkExternal } from "react-icons/bi/index";

const getInitialValues = () => {
  let fields = {}

  for (let i = 0; i < signupSchema.length; i++) {
    fields[signupSchema[i]?.fieldName] = ''
  }

  return fields;
};

const TextInput = (props) => {
  const [field, meta, helpers] = useField(props);
  const { id, formik, name } = props;

  const [errMsg, setErrMsg] = useState();

  const country = formik?.values?.country === "" ? "US" : formik?.values?.country
  
  let status = {};

  const isPassField = ["password", "confirmPass"].includes(id)

  const [value, setValue] = useState(null)

  const [showPhoneNumberError, setPhoneNumberError] = useState({
    showInvalid: false,
    showRequired: false
  })

  if (Boolean(errMsg)) {
    status = { type: 'error', message: errMsg };
  }

  if (meta.touched && meta.error) {
    if (isPassField) {
      if (Boolean(errMsg)) {
        status = { type: 'error', message: errMsg };
      }
    } else {
      status = { type: 'error', message: meta.error };
    }
  }

  useEffect(() => {

    if (value !== null) {
      if (value.length === (" +" + getCountryCallingCode(country)).length) {
        setPhoneNumberError({
          showInvalid: false,
          showRequired: true
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handlePhoneNumberChange = (val) => {
    let prefix = "+"

    if (country) {
      prefix = "+" + getCountryCallingCode(country)
    }

    if (val?.length < prefix?.length + 11) {
      setPhoneNumberError({
        showInvalid: false,
        showRequired: false
      })
    } else {
      setPhoneNumberError({
        showInvalid: true,
        showRequired: false
      })
    }

    helpers.setValue(val)
    setValue(val)
  }

  const handleChange = (evt) => {
    const val = evt.target.value

    if (isPassField) {

      let msg;

      if (Boolean(val)) {
        // need to contain atleast one upper case letter
        if (!(/[A-Z]/.test(val))) {
          msg = "Password should contain one capital letter"

          // need to contain atleast one lower case letter
        } else if (!(/[a-z]/.test(val))) {
          msg = "Password should contain one small letter"

          // need to check for on number
        } else if (!(/[0-9]/.test(val))) {
          msg = "Password should contain one number"

          // need to check for one special letter
        } else if (!(/[^a-zA-Z0-9]/.test(val))) {
          msg = "Password should contain one special character"

          // pwd need to be more than 8 character
        } else if (val?.length < 8) {
          msg = "Password should be more than 8 characters"

          // pwd need to be more than 8 character
        } else if (val?.length > 16) {
          msg = "Password should be less than 16 characters"

        } else if (["confirmPass"].includes(id)) {
          if (formik?.values?.password !== val) {
            msg = "Confirm password isn't matching with password"
          }
        }
      } else {
        msg = "Password is required"

      }

      setErrMsg(msg)

      helpers.setValue(val)
    } else {

      helpers.setValue(val)
    }
  };

  return (
    <>
      {
        (name === "contactNumber") ?
          (
            <>
              <PhoneNumber
                country={country}
                onChange={handlePhoneNumberChange}
                inputComponent={Input}
                autoComplete="none"
                {...props}
              />
              {showPhoneNumberError.showInvalid && <Text style={{ fontSize: ".875rem" }} color="#DE2E37" mt=".3rem">Invalid Phone Number</Text>}
              {showPhoneNumberError.showRequired && <Text style={{ fontSize: ".875rem" }} color="#DE2E37" mt=".3rem">Contact Phone Number is required</Text>}
            </>
          )
          :
          (
            <>
              <Input
                status={status} {...field} {...props}
                onChange={handleChange}
                value={field.value === undefined ? '' : field.value}
                isAutofilled={false}
              />
              {["password"].includes(id) && <Text mt={".4rem"} fontWeight={"700"} ml={".1rem"} fontSize={"11.5px"} color={"#524d4d91"}>
                Note : Password should have at least 8 characters and be less than 16. It should also contain one capital letter, one small letter, one number, and one special character.
              </Text>}
            </>
          )
      }
    </>
  );
};

const SelectField = (props) => {
  const [field, meta, helpers] = useField(props);
  const [options, setOptions] = useState(props?.options ?? [])
  const selectedOption = options?.find(option => (option.value === meta.value));
  const { formik, name } = props;
  const country = formik?.values?.country
  let c = Object.assign({
    "US": null,
    "CA": null,
    "GB": null,
    "MX": null,
    "AU": null,
    "IN": null,
    "DE": null,
  }, ct.getAllCountries())


  const handleChange = (option) => {
    if (name !== "timeZone" && Object.keys(getInitialValues()).includes(name)) {
      formik.setFieldValue('timeZone', '')
      formik.setFieldValue('contactNumber', '')
      formik.validateField('timeZone')
    }

    helpers.setValue(option.value);
  };

  useEffect(() => {
    if (name === "country" || name === "timeZone") {
      setOptions(name === "timeZone" ?
        getTZ(country)?.map(t => { return { label: t, value: t } }) ?? []
        : Object.keys(c).map(k => { return { label: c[k].name, value: c[k].id } }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (country !== "" && name === "timeZone") {
      setOptions(getTZ(country)?.map(t => { return { label: t, value: t } }) ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  let status = {}, hasError = false;

  if (meta.error) {
    status = { type: 'error', message: meta.error };
    hasError = true
  }

  return (
    <>
      <Autocomplete
        {...field}
        {...props}
        status={status}
        hasError={hasError}
        options={options}
        onChange={handleChange}
        isDisabled={country === "" && name === "timeZone"}
        value={selectedOption || ''}
      />
      {
        status.message &&
        <FormHelper mt={1} status={status.type}>
          {status.message}
        </FormHelper>
      }
    </>
  );
}

const createValidationSchema = () => {

  let validation = {}

  for (let i = 0; i < signupSchema.length; i++) {
    const { name, fieldName, required } = signupSchema[i]

    if (required) {
      switch (fieldName) {
        case "email":
          validation[fieldName] = Yup.string()
            .email('Invalid email address')
            .required('Email is required')
          break
        case "password":
          validation[fieldName] = Yup.string()
            .required('Password is required')
            .max(16, 'No more than 16 characters')
            .min(6, 'At least 6 characters required')
            .matches(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&]).{6,16}',
              'Must have one capital character, one number and one special character'
            )
          break
        case "confirmPass":
          validation[fieldName] = Yup.string()
            .required('Please confirm your password')
            .oneOf([Yup.ref('password')], 'Does not match password')
          break
        default:
          validation[fieldName] = Yup.string()
            .required(`${name} is required`)
      }
    }
  }

  return Yup.object().shape(validation);
};

const SignupForm = ({ formik, loading }) => {

  const { country, contactNumber } = formik.values

  const checkPhoneNumber = () => {
    if (country !== '') {
      const prefix = "+" + getCountryCallingCode(country)

      if (!(contactNumber?.length <= prefix?.length + 10 && contactNumber?.length >= prefix?.length + 10)) {
        return true
      }
    }

    return false;
  }

  let isDisabled = (!isEmpty(formik.errors) || some(formik.values, isEmpty)) || checkPhoneNumber();

  return <Form>
    {
      signupSchema?.map(field => {
        const { name, fieldName, type } = field

        return (
          <Box mb={3}>
            <Label htmlFor={fieldName}>{name}</Label>
            {
              type !== "option" ?
                <TextInput formik={formik} id={fieldName} name={fieldName} type={type} placeholder={`Enter ${name}`} />
                :
                <SelectField
                  id={fieldName}
                  name={fieldName}
                  formik={formik}
                />
            }
            {
              Boolean(field?.despLink) && (
                <Flex mb="1rem" flexDirection={"row"} alignItems={"flex-end"}>
                  <BiLinkExternal style={{ color: "#418de1" }} />
                  <TextLink onClick={() => { window.open(field?.despLink, "_blank") }} style={{ fontSize: "14px", marginTop: ".8rem", marginLeft: ".3rem" }} fontWeight={"500"}>click here to generate a Personal Access Token</TextLink>
                </Flex>
              )
            }
          </Box>
        )
      })
    }
    <Button variant="primary" type="submit" fill={true}
      disabled={isDisabled}
      loading={loading}
      width="100%"
      mb={2}
    >
      Submit
    </Button>
  </Form>;
};

export const Signup = (props) => {
  const [sendPayload] = useSignupMutation()
  const { showNotification } = useNotification();
  const [isLoading, setLoading] = useState(false)

  const onSubmit = (values) => {
    setLoading(true)
    const prefix = "+" + getCountryCallingCode(values?.country ?? "US")

    const payload = {
      ...values,
      contactNumber: values?.contactNumber?.substring(prefix?.length, values?.contactNumber?.length)
    }

    sendPayload({ ...payload })
      .unwrap()
      .then(res => {
        if (res?.oauthUrl) {
          Modal.success({
            content: <>
              <Spin spinning /> Redirecting...
            </>,
          });
          window.open(res?.oauthUrl, "_self")
          res?.message ? showNotification(res.message) : showNotification('User Registered!')
        } else {
          res.message ? showNotification(res.message) : showNotification('User Registered!')
          props.navigate('../login');
        }
      })
      .catch(res => {
        showNotification(res.data.error, "error")
      })
      .finally(() => {
        setLoading(false)
      })
  };

  return <>
    <Flex justifyContent="center" mb="35px">
      <Box width={450} marginTop={50}>
        <Card>
          <CardHeader title="Signup" />
          <CardSection>
            <Formik
              initialValues={getInitialValues()}
              validationSchema={createValidationSchema()}
              onSubmit={onSubmit}
              validateOnChange
            >
              {formik =>
                <SignupForm formik={formik} loading={isLoading} {...props} />
              }
            </Formik>
          </CardSection>
          <CardFooter>
            <Flex justifyContent="center">
              Already have an account? <TextLink ml={1} onClick={() => props.navigate('../login')}>Login</TextLink>
            </Flex>
          </CardFooter>
        </Card>
      </Box>
    </Flex>
    <Copyrights />
  </>;
};