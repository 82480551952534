import { useToasts } from '@lightspeed/flame/Toaster';

export const useNotification = () => {
    const { addToast } = useToasts()

    const showNotification = (msg, type) => {
        addToast(msg, { appearance: type })
    }

    return { showNotification }
};