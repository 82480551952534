import { Bone } from "@lightspeed/flame/Bone"
import { Card } from "@lightspeed/flame/Card"
import { Flex } from "@lightspeed/flame/Core"
import React from "react"

export const RowLoader = ({ rows = 5, box = false }) => {

    let Comp = React.Fragment

    if (box) {
        Comp = Card
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Comp style={{ padding: "10px" }}>
                    {
                        [...Array(rows).keys()]?.map(() => (
                            <Flex padding={".5rem"}>
                                <Flex width={"50%"} pl="0.8rem" pr="0.8rem" justifyContent={"center"}>
                                    <Bone height="1.5rem" width="15rem" />
                                </Flex>
                                <Flex width={"50%"} pl="0.8rem" pr="0.8rem" justifyContent={"center"}>
                                    <Bone height="1.5rem" width="15rem" />
                                </Flex>
                            </Flex>
                        ))
                    }
                </Comp>
            </Flex>
        </>
    )
}