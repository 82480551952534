import { useSelector } from 'react-redux';
import { isAccountsLoading } from '../slices/dashboardSlice';
import { filterAccountsByKeywords } from '../utils/helpers/commonHelpers';
import useConnectionMode from './useConnectionMode';

const keywordsLists = {
  'Online': {
    items: ['income', 'liability', 'sales', 'revenue', 'liabilities'],
    pettyCash: ['asset', 'expense', 'income', 'liability', 'sales', 'revenue', 'liabilities'],
    discount: ['expense', 'income', 'sales', 'revenue'],
    giftCard: ['liability', 'liabilities', 'income'],
    payment: ['payments'],
    paymentLiability: ['liabilities', 'liability'],
    tips: ['liability', 'liabilities'],
    serviceCharge: ['liability', 'liabilities', 'income'],
    overShort: ['asset', 'expense', 'income', 'liability', 'sales', 'revenue', 'liabilities'],
    shipping: ['expense'],
    inventory: ['asset', 'inventory'],
    cogs: ['expense', "Cost of Goods Sold", "Direct Costs"],
    inventoryCogs: ['asset', 'inventory', 'expense', "Cost of Goods Sold", "Direct Costs"],
    chargebacks: ['expense'],
    nonCashFee: ['revenue', 'liability', 'liabilities']
  },
  'Xero': {
    items: ['Revenue', 'Income', 'Other income', 'Current', 'Sales'],
  },
};

const getKeywords = (accountsType, connectionMode) => {
  let keywords = keywordsLists[connectionMode] ?
    keywordsLists[connectionMode][accountsType]
    :
    keywordsLists['Online'][accountsType];

  if (!keywords) {
    keywords = keywordsLists['Online'][accountsType];
  }

  return keywords ? keywords.map(keyword => keyword.toLowerCase()) : [];
};

export const useAccounts = (accountsType) => {
  // const { status, entities, error } = useSelector(state => state.accounts);
  const entities = useSelector((state) => state.dashboard.accounts);
  const isLoading = useSelector(isAccountsLoading);
  const [connectionMode] = useConnectionMode();
  const keywords = getKeywords(accountsType, connectionMode);
  return [isLoading, filterAccountsByKeywords(entities, keywords)];
};

export default useAccounts;
