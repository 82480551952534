import { Button, Input, Modal, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Flex, Box } from '@lightspeed/flame/Core';
import { useState } from 'react';
import { useNotification } from '../../utils/helpers/notifications';
import { useSelector } from 'react-redux';

const DynamicFields = ({ labels = [], onFieldAdd, title, options }) => {

    const [showModal, setShowModal] = useState(false)
    const [labelField, setLabelField] = useState('')
    const [valueField, setValueField] = useState()

    const { showNotification } = useNotification()

    const { isGlobalLoading } = useSelector(state => state.dashboard)

    if (isGlobalLoading) {
        return <></>
    }

    return (
        <>
            <Modal title={`Add New ${title}`} width="600px" open={showModal} footer={null} closeIcon={<></>}>
                <Flex justifyContent={"space-between"} mt="30px">
                    <Box width="50%">
                        <Input allowClear value={labelField} onChange={(evt) => setLabelField(evt.target.value)} placeholder={`Enter ${title}`} />
                    </Box>
                    <Box width="50%">
                        <Select
                            showSearch
                            onChange={(evt) => setValueField(evt)}
                            style={{ marginLeft: ".8rem", width: "250px" }}
                            allowClear
                            options={options}
                            value={valueField}
                            placeholder={`Select ${title} Account`}
                        />
                    </Box>
                </Flex>
                <Flex justifyContent={"end"} mt="2rem">
                    <Button danger type="text" onClick={() => {
                        setShowModal(false)
                        setLabelField('')
                        setValueField()
                    }}>Cancel</Button>
                    <Flex ml=".5rem">
                        <Button disabled={labelField === ''} type='primary' onClick={() => {
                            const allLabels = labels?.map(row => row?.id)
                            if (!allLabels?.includes(labelField)) {
                                setShowModal(false)
                                onFieldAdd(labelField, valueField)
                                setLabelField('')
                                setValueField()
                            } else {
                                showNotification(`${title} is already mapped.`, "error")
                            }
                        }}>Add</Button>
                    </Flex>
                </Flex>
            </Modal>
            <Flex justifyContent={"end"}>
                <Button type='link' icon={<PlusOutlined />} onClick={() => setShowModal(true)}>Add More Fields</Button>
            </Flex>
        </>
    );
}

export default DynamicFields;