import { configureStore, isRejected } from '@reduxjs/toolkit';
import { apiService } from '../services/apiService';
import { dashboardApi } from '../services/dashboardService';
import rootReducer from './reducer/rootReducer';
import { setSessionExpired } from '../slices/dashboardSlice';
import { authService } from '../services/authService';
import { syncApi } from '../services/syncService';

const errorResponseHanlder = ({ dispatch }) => {
  return (next) => (action) => {

    if (isRejected(action)) {
      console.error(action, "*******")
      if (action?.payload?.data?.error?.key === "invalidJwt")
        dispatch(setSessionExpired())
    }

    return next(action)
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (m) => m().concat(apiService.middleware, dashboardApi.middleware, authService.middleware, syncApi.middleware, errorResponseHanlder)
});

export default store;
