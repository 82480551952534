import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import { useConnectionMode } from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';

export const MapServiceCharges = (props) => {
  const [, softwareName] = useConnectionMode();
  const [isLoading, accountsList] = useAccounts('serviceCharge');
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <Box padding="0.75rem">
    <Heading3 mb="0.375rem">Service charges</Heading3>
    <Text color="gray-600" mb="1.5rem" mr="3.25rem" size="small">
      {`Service charges can be mapped to an account in ${softwareName}.`}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="ServiceCharge">
          Select a service charges account
        </LabelRequired>
        <Autocomplete
          isLoadingOptions={isLoading}
          id="ServiceCharge"
          name="ServiceCharge"
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter service change account' : 'Select an account')
          }
        />
      </Box>
    </Flex>
  </Box>;
};
