import React, { useEffect } from 'react';
import { Router as Routes, Redirect } from "@reach/router";
import { AuthorizeSoftware } from '../Auth/AuthorizeSoftware';
import { Dashboard } from '../components/Dashboard/Dash';
import { SyncLogs } from '../components/syncLogs/SyncLogs';
import { IndividualDifferences } from '../components/mapDifferences/IndividualDifferences';
import { MapIndividualPurchaseTax } from '../components/cogsInventory/MapIndividualPurchaseTax';
import { SelectSoftware } from '../components/selectSoftware/SelectSoftware';
import { IndividualDiscounts } from '../components/mapDiscounts/IndividualDiscounts';
import i18n from '../i18n';
import portal from "../target.json"
import { useDispatch } from 'react-redux';
import { setAppToken, setFullName, setPortal, setToken } from '../slices/authSlice';
import { parse as parseQuery } from 'query-string';
import { LSAuthorize } from '../Auth/LSAuthorize';
import { Signup } from '../Auth/authorizeLogin/signup';
import { Login } from '../Auth/authorizeLogin/login';
import { UserOnboard } from '../Auth/userOnboard/userOnboard';
import target from "../target.json";
import { MultiLocation } from '../components/selectLocation/multiLocation';
import { CompleteOAuth } from '../Auth/CompleteOAuth';
import { ChangePassword } from '../Auth/authorizeLogin/changePwd';
import Admin from '../components/admin/admin';

const Router = () => {

    const dispatch = useDispatch();
    const params = parseQuery(window.location.search);

    useEffect(() => {
        if (params && params.language) {
            i18n.changeLanguage(params.language);
        }

        if (portal.target) {
            if (portal.target !== "ls") {
                dispatch(setPortal(portal.target))
            } else {
                dispatch(setPortal("ls"))
            }

            if (params?.token) {
                dispatch(setToken(params?.token))
            }

            let name = ''

            switch (portal.target) {
                case "ls":
                    name = "PayEngine"
                    break
                case "epos":
                    name = "EposNow"
                    break
                case "revel":
                    name = "Revel"
                    break
                case "runit":
                    name = "RunIt"
                    break
                case "treez":
                    name = "treez"
                    break
                case "xs":
                    name = "Lightspeed"
                    break
                default:
                    name = portal.target
            }

            dispatch(setFullName(name))
            document.title = name + " Accounting"
        }

        if (params?.appToken) {
            dispatch(setAppToken(params?.appToken))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes style={{ height: "100%" }} basepath={target.basepath ?? ''} >
            <LSAuthorize path="/authorize" key="authorize"  />
            <LSAuthorize path="/authorize-existing" key="authorize-existing"  />
            <LSAuthorize path="/authorize-new" key="authorize-new"  />
            <CompleteOAuth path="/completeOAuth" />
            <Admin path="/ops" />
            {/* <EposAuthorize path="/" /> */}
            <Redirect from="/" to="./login" noThrow />
            <Login path="/login" />
            <Signup path="/signup" />
            <ChangePassword path="/changePassword" />
            <UserOnboard path="/user-onboard" />
            <AuthorizeSoftware path="/auth-sw" />
            <SelectSoftware path="/selectsw" />
            <Dashboard path="/dashboard" />
            <MultiLocation path="/select-location" />
            <IndividualDiscounts path="/map-discounts" />
            <SyncLogs path="/sync-logs" />
            <IndividualDifferences path="/map-differences" />
            <MapIndividualPurchaseTax path="/map-purchase-tax" />
        </Routes>
    );
}

export default Router;