import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetPaymentMethodQuery } from "../../services/dashboardService";
import useAccounts from "../../hooks/useAccounts";
import { FullPageWrapper } from '../custom/IndividualMappingLayout';
import amIVisible from '../../componentControl/registry';

export const IndividualPaymentMapping = ({ formik }) => {

    const { t } = useTranslation()

    const { currentSw } = useSelector(state => state.dashboard)

    const { PaymentMethodTypeBreakdown: isIntegratedPayment, openInvoices: openInvoice } = formik?.values ?? {}

    const { paymentsLabels } = useSelector(state => state.dashboard)

    const { isLoading, isFetching } = useGetPaymentMethodQuery({ isIntegratedPayment, openInvoice }, { refetchOnMountOrArgChange: true, skip: paymentsLabels?.length !== 0 })

    const [isLoadingAccounts, accountsList] = useAccounts('payment');
    const [, paymentLiabilityList] = useAccounts('paymentLiability');

    const isDynamicMapping = amIVisible("dynamic payment individual");

    const { paymentsMapping } = formik?.values;

    return (
        <>
            <FullPageWrapper
                title={t('Payment types')}
                fullPageDesp={t('IndividualPaymentsDesc', { swName: currentSw })}
                formik={formik}
                paymentLiabilityList={paymentLiabilityList}
                formikFieldName={"paymentsMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Payments"}
                dynamicFieldTitle="Payment Type"
                options={accountsList}
                labels={paymentsMapping}
                isDynamic={isDynamicMapping}
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}