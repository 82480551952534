import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../hooks/useAccounts';
import { useConnectionMode } from '../../hooks/useConnectionMode';
import amIVisible from '../../componentControl/registry';
import { useEffect, useState } from 'react';

export const Adjustment = ({ formik }) => {
  const [isLoading, accountsList] = useAccounts('pettyCash');
  const [, chargeBackAccounts] = useAccounts('chargebacks');

  const {
    isSentAdjustment
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  const [title, setTitle] = useState("Adjustment")
  const [accounts, setAccounts] = useState(accountsList)

  useEffect(() => {
    if (amIVisible("chargebacks")) {
      setTitle("Chargebacks")
      setAccounts(chargeBackAccounts)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SwitchLayout
    heading={title}
    description={t('', { swName })}
    switchFieldName="isSentAdjustment"
    isSwitchOn={isSentAdjustment}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="adjustmentAccount">
          {`Default ${title} Account`}
        </LabelRequired>
        <Autocomplete
          id="adjustmentAccount"
          name="adjustmentAccount"
          isLoadingOptions={isLoading}
          options={accounts}
          placeholder={"Select an account"}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};