import { getClassObject, getCurrentTarget, parseClassMapping } from './commonHelpers';

const target = getCurrentTarget();

const parseDiscountMapping = (forms, payload) => {
  if (forms.discountsMapping && forms.discountsMapping.dirty) {
    const values = forms.discountsMapping.values;
    payload.discountsMapping = values.discountsMapping.map(row => {
      return {
        id: row.id,
        name: row.account,
      };
    });
  }
};

const parseProductMapping = (forms, payload) => {
  const hasItemsMapping = forms.productsMapping;
  const hasCategoriesMapping = forms.categoriesMapping || forms?.categoriesMapping?.values?.hasMappingLevelChanged;
  const itemsMapping = hasItemsMapping && forms.productsMapping.values;
  const categoriesMapping = hasCategoriesMapping && forms.categoriesMapping.values;
  const mappingType = (itemsMapping && itemsMapping.mappingType) || (categoriesMapping && categoriesMapping.mappingType);

  if (mappingType) {
    payload.recordCategoryForInvoiceByProduct = mappingType === 'category';
  }

  if (hasCategoriesMapping) {
    if (payload.recordCategoryForInvoiceByProduct) {
      payload.isParentCategory = categoriesMapping.breakCategories === 'highest';
    }

    payload.categories = {};
    const mapping = categoriesMapping.categoriesMapping;
    mapping && mapping.forEach(row => {
      payload.categories[row.id] = {
        incomeAccount: row.account,
      };
    });
  }

  if (hasItemsMapping) {
    payload.items = {};
    const mapping = itemsMapping.productsMapping;
    mapping && mapping.forEach(row => {
      payload.items[row.id] = {
        incomeAccount: row.account,
      };
    });
  }
};

const parseIndividualCogsMapping = (forms, payload) => {
  const hasItemsMapping = forms.poProductsMapping;
  const hasCategoriesMapping = forms.poCategoriesMapping || forms?.poCategoriesMapping?.values?.hasMappingLevelChanged;
  const itemsMapping = hasItemsMapping && forms.poProductsMapping.values;
  const categoriesMapping = hasCategoriesMapping && forms.poCategoriesMapping.values;
  const mappingType = (itemsMapping && itemsMapping.mappingType) || (categoriesMapping && categoriesMapping.mappingType);

  if (mappingType) {
    payload.swapCategoryFromProducts = mappingType === 'category';
  }

  if (hasCategoriesMapping) {
    payload.swapSuperParentCategoryFromProducts = categoriesMapping ? (categoriesMapping.breakCategories === 'highest') : true;

    payload.categorySpecificInventoryCogsAccounts = {};
    const mapping = categoriesMapping.poCategoriesMapping;
    mapping && mapping.forEach(row => {
      payload.categorySpecificInventoryCogsAccounts[row.id] = {
        inventoryAccount: row.inventoryAccount,
        expenseAccount: row.cogsAccount,
      };
    });
  }

  if (hasItemsMapping) {
    payload.itemSpecificInventoryCogsAccounts = {};
    const mapping = itemsMapping.poProductsMapping;
    mapping && mapping.forEach(row => {
      payload.itemSpecificInventoryCogsAccounts[row.id] = {
        inventoryAccount: row.inventoryAccount,
        expenseAccount: row.cogsAccount,
      };
    });
  }
};

const parsePaymentMapping = (forms, payload) => {
  if (forms.paymentsMapping) {
    const values = forms.paymentsMapping.values;
    payload.payments = {};
    values.paymentsMapping.forEach(row => {
      payload.payments[row.id] = row.account;
    });
  }
};

const parseOverShortMapping = (forms, payload) => {
  if (forms.overShortMapping && forms.overShortMapping.dirty) {
    const values = forms.overShortMapping.values;
    payload.overShorts = {};
    values.overShortMapping.forEach(row => {
      payload.overShorts[row.id] = row.account;
    });
  }
};

const parsePurchaseTax = (values) => {
  const mapping = {};

  if (values) {
    values.purchaseTaxMapping.forEach(taxData => {
      mapping[taxData.id] = taxData.account;
    });
  }

  return mapping;
};

const parseDashboard = (posType, values, forms, connectionMode) => {
  const syncRange = parseInt(values.syncRange, 10);
  const payload = {
    syncRange,
  };

  if (values?.dataTransferType === "1" || values?.dataTransferType === "2") {
    payload.dataTransferType = parseInt(values?.dataTransferType, 10)
  }

  if (forms?.dashboard?.values?.phoneNumber) {
    payload.phoneNumber = forms.dashboard.values.phoneNumber
  } else {
    payload.phoneNumber = ""
  }
  // Sync schedule
  if (syncRange > 1) {
    payload.weekDay = syncRange - 1;
    payload.syncRange = 2;
  }

  payload.email1 = values.email;

  if (values.postingMethod !== '') {
    payload.defaultAccount = values.postingMethod === 'auto' ? 0 : 1;
    payload.useModificationdate = values.bookReceipts === '0' ? 0 : 1;
    payload.openInvoices = 0;

    if (posType.isRetail) {
      payload.POInventoryCogs = values.isMapPoInventoryCogs;
    }

    if (values.postingMethod === 'auto') {
      payload.type = 0;
    } else {
      payload.type = 1;
      payload.recordItems = values.postingMethod === 'invoice';
      payload.journalEntry = values.postingMethod === 'journalEntry';
      // payload.isSentDiscount = values.isMapDiscounts;
      payload.isSentDiscount = false;
      payload.PaymentMethodTypeBreakdown = parseInt(values.splitCreditPayments, 10);

      if (values?.dataTransferType === "1") {
        payload.journalEntry = false
      }

      if (!posType.isRetail || posType.isEposnow) {
        if (values.tipsAccount) {
          payload.Tips = values.tipsAccount;
        } else {
          payload.Tips = ""
        }
      }

      if ((target.isRevel || posType.isEposnow) && values.serviceCharge) {
        payload.ServiceCharge = values.serviceCharge;
      } else {
        payload.ServiceCharge = ""
      }

      if (values.defaultPaymentAccount) {
        payload.defaultPaymentAccountName = values.defaultPaymentAccount;
        payload.enabledDefaultPaymentAccount = true;
      } else {
        payload.defaultPaymentAccountName = "";
        payload.enabledDefaultPaymentAccount = false;
      }

      if (values.isMapDiscounts) {
        if (values.defaultDiscountsAccount) {
          payload.defaultDiscountAccountName = values.defaultDiscountsAccount;
          payload.enabledDefaultDiscountAccount = true;
        } else {
          payload.defaultDiscountAccountName = "";
          payload.enabledDefaultDiscountAccount = false;
        }
        parseDiscountMapping(forms, payload);
      }

      if (values.defaultProductsAccount) {
        payload.incomeAccount = values.defaultProductsAccount;
      } else {
        payload.incomeAccount = ""
      }

      parsePaymentMapping(forms, payload);
      parseProductMapping(forms, payload);

      if (posType.isRetail) {
        parseIndividualCogsMapping(forms, payload);
      }
    }

    if (payload.journalEntry) {
      payload.openInvoices = 0;
    } else {
      payload.openInvoices = values.isMapKeepInvoice ? 1 : 0;
    }

    // Parse additional Settings
    payload.isLayAway = values.isMapLayAway ? 1 : 0;
    payload.customTransactionNumber = values.transactionNumber;

    // send POS Customer if customer name is empty.
    if (values.customerName === "") {
      payload.defaultCustomer = "POS customer"
    } else {
      payload.defaultCustomer = values.customerName;
    }

    // Locations
    payload.locationMapping = values.isMapClass;
    if (values.isMapClass && values.classMapping) {
      payload.locations = getClassObject(values.classMapping);
    }

    if (posType.isRetail) {
      // eCom
      payload.eComSale = values.isMapECom;
      if (values.isMapECom && values.eComAccount) {
        payload.eComSaleMappedLocation = values.eComAccount;
      }

      // Over/Short
      payload.isSentOverShort = values.isMapOverShort;
      if (payload.isSentOverShort) {
        payload.defaultOverShortAccountName = values.overShortAccount ?? "";
        parseOverShortMapping(forms, payload);
      }

      // petty cash / payouts
      payload.isSentPettyCashPayouts = values.isSentPettyCashPayouts;
      if (payload.isSentPettyCashPayouts) {
        payload.pettyCashPayoutsAccount = values.pettyCashPayoutsAccount ?? "";
      }

      // PO, Inventory and COGS
      if (payload.POInventoryCogs) {
        payload.isSentPurchaseOrder = values.isMapPurchaseInvoice;
        payload.trackInventory = values.isTrackInventory;
        payload.isSentCogs = values.isMapCogs;

        const inputCode = parseInt(['isMapPurchaseInvoice', 'isMapCogs', 'isTrackInventory',].map(field => {
          return Boolean(values[field]) ? 1 : 0;
        }).join(''), 2);

        if (inputCode !== 0) {
          payload.defaultInventoryAccountName = values.defaultInventoryAccount;

          if (inputCode !== 2) {
            payload.shipping = values.shippingAccount;
            payload.freightCharges = values.freightChargesAccount;
            payload.otherExpenses = values.otherExpensesAccount;
          }

          if (inputCode !== 4) {
            payload.defaultCogsAccountName = values.defaultCogsAccount;
          }
        }
      }
    }

    if (posType.isKSeries) {
      // Financial
      if (connectionMode === 'Datev') {
        payload.taxAdviserName = values.taxAdvisorName;
        payload.taxAdviserNumber = values.taxAdviserNumber;
        payload.mandatorNumber = values.mandatorNumber;
        payload.fiscalYearBegin = values.fiscalYearBegin;
        payload.interimAccount = values.interimAccount;
        payload.lengthOfLedgerAccount = values.lengthOfLedger;
      }
    }
  } else {
    payload.journalEntry = false;
  }

  return payload;
};

const parseTaxVendorMapping = (values) => {

  const payload = {
    vendorNameTax: {}
  }

  values.taxMapping.forEach(taxData => {
    payload.vendorNameTax[taxData.id] = taxData?.agency
  })

  return payload;
}



const parseTaxMapping = (values) => {
  const payload = {
    tax: {},
  };

  values.taxMapping.forEach(taxData => {
    let id = taxData?.id;
    payload.tax[id] = taxData.account;
  });

  return payload;
};

export const createPayload = (posType, forms, meta, connectionMode, defaultConfigs = {}) => {
  let payload = {
    connectionMode,
    isSentOverShort: false,
    trackInventory: false,
    isSentPayouts: false,
    payments: defaultConfigs?.payments,
    categories: defaultConfigs?.categories,
    items: defaultConfigs?.items,
    categorySpecificInventoryCogsAccounts: defaultConfigs?.categorySpecificInventoryCogsAccounts,
    itemSpecificInventoryCogsAccounts: defaultConfigs?.itemSpecificInventoryCogsAccounts,
    tax: defaultConfigs?.tax,
    vendorNameTax: defaultConfigs?.vendorNameTax
  };

  // categories tax mapping
  payload.isParentCategory = forms?.categoriesMapping?.values?.breakCategories ? forms?.categoriesMapping?.values?.breakCategories === "highest" : true

  // User changed posting method
  if (meta.isChangedPostingMethod === true) {
    payload.isParentCategory = true;
    payload.isResetConfiguration = true;
    payload.tax = {}
    payload.vendorNameTax = {}
    payload.poTaxes = {}
    payload.categorySpecificInventoryCogsAccounts = {}
    payload.items = {}
    payload.categories = {}
    payload.Tips = ""
    payload.ServiceCharge = ""
  }

  // Parse all forms besides tax rates
  payload = Object.assign({}, payload, parseDashboard(posType, forms.dashboard.values, forms, connectionMode));

  // Parse tax mapping
  if (forms.taxMapping) {
    payload = Object.assign({}, payload, parseTaxMapping(forms.taxMapping.values));
    payload = Object.assign({}, payload, parseTaxVendorMapping(forms.taxMapping.values));
  }

  // Purchase tax
  if (payload.POInventoryCogs && forms?.purchaseTaxMapping?.dirty) {
    payload.poTaxes = parsePurchaseTax(forms?.purchaseTaxMapping?.values);
  }

  return payload;
};

const isNonNull = (val) => {
  let result = !([null, undefined, '', NaN]).includes(val);

  if (result && (typeof val === 'object')) {
    if (val.length && val.length === 0) {
      result = false;
    } else if (Object.keys(val) && Object.keys(val).length === 0) {
      result = false;
    }
  }

  return result;
}

const setConditional = (values, config, attributes, to, from, operation, operationArgs) => {
  let value;
  if (isNonNull(config[from])) {
    value = config[from];
  } else if (attributes && isNonNull(attributes[from])) {
    value = attributes[from];
  }

  if (isNonNull(value)) {
    if (operation === 'toString') {
      value = `${value}`;
    } else if (operation === 'toBoolean') {
      value = Boolean(value);
    } else if (operation === 'toClass') {
      value = parseClassMapping(value);
    } else if (operation === 'fromNested') {
      const nestedValue = value[operationArgs];
      value = isNonNull(nestedValue) ? nestedValue : null;
    }

    values[to] = value;
  }
};

export const parseFetchedConfig = (config) => {
  // Parse attributes
  const attributes = (typeof config.attributes === 'string') ? JSON.parse(config.attributes) : config.attributes;
  let syncRange
  if (config?.dataTransferType === 1) {
    syncRange = 0
  } else {
    // Parse syncRange
    syncRange = config.syncRange ?? 1;
    if (syncRange === 2) {
      syncRange = 1 + attributes.weekDay;
    }
  }

  const values = {
    email: config.email1 || '',
    syncRange: `${syncRange}`,
    postingMethod: '',
    dataTransferType: config?.dataTransferType
  };

  let operationsList = [];

  const configExists = config && !([null, undefined, ''].includes(config.configType));

  if (configExists) {
    operationsList = [
      ['customerName', 'defaultCustomer'],
      ['transactionNumber', 'customTransactionNumber'],
      ['isMapClass', 'locationMapping', 'toBoolean'],
      ['classMapping', 'locations', 'toClass'],
    ];
  }
  if (config.configType === 0) {
    values.configType = 'auto';
  } else if (config.configType) {
    if (config.journalEntry) {
      values.postingMethod = 'journalEntry';
    } else if (config.recordItems === false) {
      values.postingMethod = 'invoiceCategory';
    } else {
      values.postingMethod = 'invoice';
    }

    operationsList = operationsList.concat([
      ['defaultProductsAccount', 'incomeAccount'],
      ['defaultPaymentAccount', 'defaultPaymentAccountName'],
      ['isMapDiscounts', 'isSentDiscount', 'toBoolean'],
      ['defaultDiscountsAccount', 'defaultDiscountAccountName'],
      ['tipsAccount', 'Tips'],
      ['vendorNameTax', 'vendorNameTax'],
      ['tax', 'tax'],
      ['serviceChargeAccount', 'ServiceCharge'],
      ["dataTransferType", "dataTransferType"],
      ['keepInvoicesOpen', 'openInvoices', 'toString'],
      ['bookReceipts', 'useModificationdate', 'toString'],
      ['splitCreditPayments', 'PaymentMethodTypeBreakdown', 'toString'],
    ]);

    operationsList = operationsList.concat([
      ['isMapOverShort', 'isSentOverShort', 'toBoolean'],
      ['isSentPettyCashPayouts', 'isSentPettyCashPayouts', 'toBoolean'],
      ['overShortAccount', 'defaultOverShortAccountName'],
      ['pettyCashPayoutsAccount', 'pettyCashPayoutsAccount'],
      ['isMapECom', 'eComSale', 'toBoolean'],
      ['phoneNumber', 'phoneNumber'],
      ['isMapKeepInvoice', 'openInvoices', 'toBoolean'],
      ['isMapLayAway', 'isLayAway', 'toBoolean'],
      ['eComAccount', 'eComSaleMappedLocation'],
      ['isMapPoInventoryCogs', 'POInventoryCogs', 'toBoolean'],
      ['isMapPurchaseInvoice', 'isSentPurchaseOrder', 'toBoolean'],
      ['isTrackInventory', 'trackInventory', 'toBoolean'],
      ['isMapCogs', 'isSentCogs', 'toBoolean'],
      ['shippingAccount', 'shipping'],
      ['freightChargesAccount', 'freightCharges'],
      ['otherExpensesAccount', 'otherExpenses'],
      ['defaultInventoryAccount', 'defaultInventoryAccountName'],
      ['defaultCogsAccount', 'defaultCogsAccountName'],
      ['categoriesMapping', 'categories'],
      ["categorySpecificInventoryCogsAccounts", "itemSpecificInventoryCogsAccounts"]
    ]);

    operationsList = operationsList.concat([
      ['taxAdvisorName', 'datevConfig', 'fromNested', 'taxAdviserName'],
      ['taxAdviserNumber', 'datevConfig', 'fromNested', 'taxAdviserNumber'],
      ['mandatorNumber', 'datevConfig', 'fromNested', 'mandatorNumber'],
      ['fiscalYearBegin', 'datevConfig', 'fromNested', 'fiscalYearBegin'],
      ['interimAccount', 'datevConfig', 'fromNested', 'interimAccount'],
      ['lengthOfLedger', 'datevConfig', 'fromNested', 'lengthOfLedgerAccount'],
    ]);

    if (target.isRevel) {
      operationsList.push(['serviceChargeAccount', 'serviceCharge']);
    }
  }

  operationsList.forEach(([to, from, operation, opArgs]) => {
    setConditional(values, config, attributes, to, from, operation, opArgs);
  });

  return values;
};

const getCleanState = (init = false) => {
  const cleanState = {
    "isSentBill": "",
    "createType": "",
    "mappingType": "",
    "openInvoices": true,
    "trackInventory": "",
    "isSentDiscount": "",
    "isSentCogs": "",
    "isSentOverShort": "",
    "isSentPayouts": "",
    "isSentPurchaseOrder": "",
    "email1": "",
    "syncEnabled": "",
    "defaultPaymentAccountName": "",
    "isSentSuperParentCategory": 1,
    "phoneNumber": "",
    "locations": "",
    "classes": "",
    "configType": 1,
    "recordItems": false,
    "dataTransferType": 2,
    "journalEntry": false,
    "defaultCustomer": "POS customer",
    "incomeAccount": "",
    "assetAccount": "",
    "expenseAccount": "",
    "items": {},
    "tax": {},
    "payments": {},
    "isParentCategory": 1,
    "swapCategoryFromProducts": 1,
    "swapSuperParentCategoryFromProducts": 1,
    "invoiceType": 0,
    "vendorNameTax": {},
    "recordCategoryForInvoiceByProduct": 1
  }

  if (!init) {
    delete cleanState.dataTransferType
    delete cleanState.email1
    delete cleanState.phoneNumber
  }

  return cleanState;
};

export const getStateOnChangePostingMethod = (formik) => {
  const { email1, phoneNumber, dataTransferType } = formik?.values;

  let newState = {
    values: Object.assign({ email1, phoneNumber, dataTransferType }, getCleanState()),
    errors: {},
    touched: {},
  };

  return { ...newState  };
};

export { getCleanState };
