import { getCompanyToken, getServerURL, getTarget } from '../helpers/sessionStorage';

let target = getTarget();

const createUrlGetter = (endpoint, options = {}) => {
  

  return () => {
    let result = '';

    const serverUrl = getServerURL();
    target = getTarget();

    if (target === "REVEL") {
      return `${serverUrl}/${endpoint}/`
    }

    // EposNow requires token in URL
    let companyTokenPart = '';
    if (target === "EPOS") {
      const companyToken = getCompanyToken();
      companyTokenPart = `/${companyToken}`;
    }

    const suffix = options.noSlash || target === "EPOS" ? '' : '/';
    result = `https://b4e2-2409-4055-2e8b-930-713a-a8fa-91c-53c1.in.ngrok.io/${endpoint}${companyTokenPart}${suffix}`;

    console.log(result, "&&&&&&&&&")

    return result;
  }
}

export const getAuthorizeIdUrl = createUrlGetter('authorize');
export const getOauthRedirectUrl = createUrlGetter('company/authorize', { noSlash: true });
export const getTaxesUrl = createUrlGetter('taxes');
export const getAccountsUrl = createUrlGetter('accounts');
export const getSubmitConfigUrl = createUrlGetter('configuration');
export const getRegisterSwUrl = createUrlGetter('connectionMode');
export const getItemsUrl = createUrlGetter('items');
export const getPaymentMethodsUrl = createUrlGetter('paymentMethods');
export const getLocationsUrl = createUrlGetter('posLocations');
export const getClassesUrl = createUrlGetter('posLocations')
export const getDiscountsUrl = createUrlGetter('discounts');
export const getChangeSyncStatusUrl = createUrlGetter('sync');
export const getSalesSyncDataUrl = createUrlGetter('getSyncData');
export const getPoSyncDataUrl = createUrlGetter('getPOSyncData');
export const getSalesOrderDetailsUrl = createUrlGetter('order', { noSlash: target === "REVEL" });
export const getPurchaseOrderDetailsUrl = createUrlGetter('purchaseOrder');
export const getValidateSwConnectionUrl = createUrlGetter('isCompanyAuthorize');
export const getReconnectAccountUrl = createUrlGetter('company/recreate');
export const getResyncUrl = createUrlGetter('resync');
export const getHistoricalSyncUrl = createUrlGetter('historicalSync');
export const getTransactionAnalysisUrl = createUrlGetter('transactionAnalysis');
export const getDeatactivateUserUrl = createUrlGetter('deactivateUser');
export const getTaxAgencyUrl = createUrlGetter('supplierList')
export const getPostUrl = createUrlGetter('reviewModePosting')
export const getLoginUrl = createUrlGetter('login');
export const getSignupUrl = createUrlGetter('signup');
export const getPoResyncUrl = createUrlGetter('resyncPurchaseOrder');
export const getPoOrderDetails = createUrlGetter('purchaseOrder');
export const getPoSyncLogUrl = createUrlGetter('getPOSyncData');