import React, { createRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Table,
    Thead,
    Th,
    Td,
    Tr,
    TableActions
} from '@lightspeed/cirrus-table';
import { Button } from '@lightspeed/flame/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Bone } from '@lightspeed/flame/Bone';
import { SyncStatusBadge } from '../custom/SyncStatusBadge';
import { TextLink, Text } from '@lightspeed/flame/Text';
import { Input } from '@lightspeed/flame/Input';
import { Flex } from '@lightspeed/flame/Core';
import { useEffect } from 'react';
import { IconDownload } from '@lightspeed/flame/Icon/Download';
import { DatePicker, Empty } from 'antd';
import { BiReset } from "react-icons/bi/index";
import { Tooltip } from "antd";
import useConnectionMode from '../../hooks/useConnectionMode';
import useCurrency from '../../hooks/useCurrency';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDateFilter, setPostTypeFilter, setResetAll, setResetParticular, setSearchQuery } from '../../slices/syncSlice';
import { Spinner } from '@lightspeed/flame/Spinner';
import { Select } from '@lightspeed/flame/Select';
import moment from 'moment';

export const SyncTable = (props) => {
    const {
        rows,
        openSyncDetailsModal,
        refreshData,
        loadingStatus,
        makeRequest,
        isTypePo
    } = props;

    const [loading, setLoading] = useState(false)
    const connectionMode = useConnectionMode()
    const { t } = useTranslation();
    const ref = createRef();
    const [dateValue, setDateValue] = useState([])
    const { toCurrency } = useCurrency();
    const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

    const dispatch = useDispatch()

    const { isLoading, syncData, s, isLastPage, isResyncing, filterPostType } = useSelector(state => state.sync)

    useEffect(() => {
        setLoading(loadingStatus)
    }, [loadingStatus])

    const handleSyncDetails = (data) => {
        openSyncDetailsModal(data);
    };

    const Search = () => {

        return (
            <Flex flexDirection={"row"} ml={"1rem"} alignItems={"center"}>
                <Input
                    ref={ref}
                    defaultValue={s ?? ''}
                    placeholder='Type order number'
                    width={200}
                />
                <Button
                    ml={"1rem"}
                    mr={'1rem'}
                    loading={isLoading}
                    onClick={() => {
                        dispatch(setSearchQuery(ref.current.value))
                        dispatch(setResetParticular({
                            sd: null,
                            ed: null
                        }))
                        setDateValue([])

                        refreshData()
                    }}
                    variant="primary"
                    type='submit'
                    fill={true}
                >{t('Search')}</Button>
            </Flex>
        )
    }

    const RefreshButton = ({ refreshData }) => {
        const { t } = useTranslation();

        return <Button
            loading={isLoading}
            onClick={refreshData}
            variant="secondary"
            fill={true}
        >{t('Refresh')}</Button>;
    };

    const getTxnType = (type) => {
        switch (type) {
            case 1:
                return isTypePo ? "Purchase Order" : "Individual Sync"
            case 2:
                return isTypePo ? "Bill" : "EOD Sync"
            case 3:
                return "Account Sales"
            case 4:
                return "Account Payment"
            case 5:
                return "Adjustment"
            case 6:
                return "Adjustment"
            default:
                return ""
        }
    }

    const Loading = () => {

        if (!isLoading) {
            return <></>
        }

        return (
            [1, 2, 3, 4, 5].map((item, index) => (
                <Tr key={index}>
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    {(isTypePo) && <Td><Bone height="1.5rem" /></Td>}
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    {!isTypePo && <Td><Bone height="1.5rem" /></Td>}
                </Tr>
            ))
        )
    }

    const handleClick = (url) => {
        window.open(url, '_blank');
    };

    const DateFilter = () => {
        const { RangePicker } = DatePicker;

        return (
            <>
                <RangePicker allowClear value={dateValue} onChange={(e, val) => {
                    setDateValue(e)

                    dispatch(setDateFilter(val))
                    dispatch(setResetParticular({ s: null }))
                    refreshData()
                }} disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return current && current > moment(customDate, "YYYY-MM-DD");
                }} />
            </>
        )
    }

    const Filter = () => {
        const onFilter = (value) => {
            dispatch(setPostTypeFilter(value))
        }

        return (
            <Select onChange={(e) => onFilter(parseInt(e.target.value))} defaultValue={filterPostType === null ? -1 : filterPostType} style={{ marginRight: "15px", zIndex: 0 }} width={"10%"}>
                <option value="-1">{t('All')}</option>
                <option value="1">{t('Success')}</option>
                <option value="0">{t('Failure')}</option>
                <option value="2">{t('Queued')}</option>
            </Select>
        )
    }

    return (
        <>
            <TableActions.Provider>
                <TableActions.ActionsBar
                    leftHandActions={
                        <>
                            <Filter />
                            <DateFilter />
                            <Search />
                            <Tooltip title="Reset filter">
                                <BiReset className='reset-icon' onClick={() => {
                                    dispatch(setResetAll())
                                    setDateValue([])
                                    ref.current.value = ''

                                    refreshData()
                                }} />
                            </Tooltip>
                            <RefreshButton refreshData={() => {
                                dispatch(setResetAll())
                                setDateValue([])
                                refreshData()
                            }} loadingStatus={loading} />
                        </>
                    }
                />
            </TableActions.Provider>
            <InfiniteScroll
                dataLength={rows?.length ?? []}
                next={refreshData}
                hasMore={!isLastPage}
            >
                <Table>
                    <Thead>
                        <Tr>
                            <Th width="15%">{t('Date posted')}</Th>
                            <Th width="15%">{t('Post status')}</Th>
                            <Th width="20%">{t('Reference number')}</Th>
                            {isTypePo && <Th width="20%">{t('Purchase Order ID')}</Th>}
                            <Th width="10%">{t('Total')}</Th>
                            <Th width="20%">{t('Transaction Type')}</Th>
                            {connectionMode === "Datev" &&
                                <Th width="20%">{t('Data')}</Th>
                            }
                            <Th width="10%">{t('Action')}</Th>
                            {!isTypePo && <Th width="60%">{t('View invoice')}</Th>}
                        </Tr>
                    </Thead>
                    <tbody>
                        {
                            <>
                                {rows?.length !== 0 &&
                                    rows?.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>
                                                {(item?.requestExists) ?
                                                    <TextLink
                                                        fontWeight="bold"
                                                        onClick={() => { handleSyncDetails(item) }}
                                                    >
                                                        {item.actionDate}
                                                    </TextLink> :
                                                    <>
                                                        {item.actionDate}
                                                    </>
                                                }
                                            </Td>
                                            <Td><SyncStatusBadge type={item.success} /></Td>
                                            <Td>{(item.success === 3) ? '' : (isTypePo ? item?.orderRef : item?.orderNumber)}</Td>
                                            {isTypePo && <Td>{item?.orderNumber ?? ""}</Td>}
                                            <Td>{(item.success === 3) ? '' : toCurrency(item.totalAmount)}</Td>
                                            <Td>{getTxnType(item?.tranType)}</Td>
                                            {isInputsOnlyLayout &&
                                                <Td>{
                                                    item?.transactionResponse?.response?.data?.file ?
                                                        <Button
                                                            fill={true}
                                                            onClick={() => {
                                                                handleClick(item?.transactionResponse?.response?.data?.file)
                                                            }}
                                                            pl="0.563rem"
                                                            pt="0.625rem"
                                                            pb="0.625rem"
                                                        >
                                                            <IconDownload />
                                                            {t('Download')}
                                                        </Button>
                                                        :
                                                        <Text>{t('Not available')}</Text>
                                                }</Td>
                                            }
                                            {item?.success === 0 && !loading ?
                                                <Td>{isResyncing ? <Spinner /> :
                                                    <TextLink
                                                        fontWeight="bold"
                                                        onClick={() => makeRequest(item?.id)}
                                                        disabled={loading}
                                                    >
                                                        Resync
                                                    </TextLink>
                                                }</Td> : <Td></Td>}
                                            <Td>
                                                {(item.success === 1 && item?.url && Object?.entries(item?.url)?.length !== 0) ?
                                                    Object.keys(item?.url ?? {})?.map((key) => (
                                                        <>
                                                            <TextLink fontWeight="bold" href={item?.url[key]} target="_blank">View {key}</TextLink>
                                                            <Flex mb={".5rem"} />
                                                        </>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </Td>
                                        </Tr>
                                    ))
                                }
                                <Loading />
                            </>
                        }
                    </tbody>
                </Table>
            </InfiniteScroll>
            {(syncData.length === 0 && !isLoading) && <Flex justifyContent={"center"}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Flex>}
        </>
    )
};