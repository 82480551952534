import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetDiscountsQuery } from "../../services/dashboardService";
import useAccounts from "../../hooks/useAccounts";
import { FullPageWrapper } from '../custom/IndividualMappingLayout';

export const IndividualDiscountMapping = ({ formik }) => {

    const { t } = useTranslation()

    const { currentSw } = useSelector(state => state.dashboard)

    const { discountsLabels } = useSelector(state => state.dashboard)

    const { isLoading, isFetching } = useGetDiscountsQuery({ }, { refetchOnMountOrArgChange: true, skip: discountsLabels?.length !== 0 })

    const [isLoadingAccounts, accountsList] = useAccounts('discount');

    return (
        <>
            <FullPageWrapper
                title={t('Map discounts')}
                fullPageDesp={t('Individual discounts can be mapped to unique accounts in {Accounting_Software}. All unassigned and new discounts will be assigned to the default discount account.', { Accounting_Software: currentSw })}
                formik={formik}
                formikFieldName={"discountsMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Discounts"}
                options={accountsList}
                labels={discountsLabels}
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}