import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';

export const KeepInvoice = ({ formik }) => {
  const { openInvoices } = formik?.values;
  const { t } = useTranslation();

  return <SwitchLayout
    heading={t('Keep invoice open for Credit Sales')}
    description={"Turning this on will create an unpaid invoice, which will allow you to manage your account receivables in your accounting software."}
    switchFieldName="openInvoices"
    isSwitchOn={Boolean(openInvoices)}
  ></SwitchLayout>;
};