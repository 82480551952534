import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setJeAsCategory } from '../redux/reducer/globalsReducer';

export const useJeAsCategory = () => {
  const isSet = useSelector(state => state.globals.isJeAsCategory);
  const dispatch = useDispatch();

  const setNewValue = (args) => {
    dispatch(setJeAsCategory(args));
  };

  return [isSet, setNewValue];
};

export default useJeAsCategory;
