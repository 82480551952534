import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubmitting: false,
  isConnected: null,
  isAppSyncing: false,
  hasSubmittedWithErrors: false,
  companyName: '',
  isJeAsCategory: false,
  isInputsOnlyLayout: null,
  defaultConfigs: {},
  sage50Versions: [],
  selectedSageVersion: "",
  canShowLayAway: false,
  isConfigReFetched: false,
  appInstalledOn: "",
  isTaxAgency: false,
  isReload: false,
};

const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    setReload: (state, action) => {
      state.isReload = action.payload
    },
    setIsTaxAgency: (state, action) => {
      state.isTaxAgency = action.payload;
    },
    setAppInstalledOn: (state, action) => {
      state.appInstalledOn = action.payload;
    },
    setOnRefetchConfig: (state, action) => {
      state.isConfigReFetched = action.payload;
    },
    setShowLayAway: (state, action) => {
      state.canShowLayAway = action.payload;
    },
    setSelectedSageVersion: (state, action) => {
      state.selectedSageVersion = action.payload;
    },
    setSage50Versions: (state, action) => {
      state.sage50Versions = action.payload;
    },
    setDefaultConfigs: (state, action) => {
      state.defaultConfigs = action.payload
    },
    setAppSubmitting: (state, action) => {
      const value = Boolean(action.payload.value);
      // Set flag only after API call completes, if not already set
      if (state.isSubmitting && !value) {
        state.hasSubmittedWithErrors = action.payload.showErrors === true;
      }
      state.isSubmitting = value;
    },
    setAppConnected: (state, action) => {
      state.isConnected = Boolean(action.payload);
    },
    setCompanyName: (state, action) => {
      state.companyName = `${action.payload}`;
    },
    setAppSyncing: (state, action) => {
      state.isAppSyncing = Boolean(action.payload);
    },
    setJeAsCategory: (state, action) => {
      state.isJeAsCategory = Boolean(action.payload);
    },
    setIsInputsOnlyLayout: (state, action) => {
      state.isInputsOnlyLayout = Boolean(action.payload);
    },
  }
});

export const {
  setAppSubmitting,
  setAppConnected,
  setAppSyncing,
  setAppRefreshing,
  setCompanyName,
  setJeAsCategory,
  setIsInputsOnlyLayout,
  setDefaultConfigs,
  setSage50Versions,
  setSelectedSageVersion,
  setShowLayAway,
  setOnRefetchConfig,
  setAppInstalledOn,
  setIsTaxAgency,
  setReload
} = globalsSlice.actions;

export default globalsSlice.reducer;
