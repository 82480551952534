import { combineReducers } from '@reduxjs/toolkit';
import accountsReducer from "./accountsReducer"
import formsReducer from "./formsReducer"
import globalsReducer from "./globalsReducer"
import initialParamsReducer from "./initialParamsReducer"
import locationReducer from "./locationReducer"
import portalInfoReducer from "./portalInfoReducer"
import softwareListReducer from "./softwareListReducer"

/* slice imports */
import authReducer from '../../slices/authSlice';
import dashboardReducer from '../../slices/dashboardSlice';
import syncReducer from '../../slices/syncSlice';

/* service imports */
import { apiService } from '../../services/apiService';
import { dashboardApi } from '../../services/dashboardService';
import { syncApi } from '../../services/syncService';

const rootReducer = combineReducers({
    location: locationReducer,
    softwareList: softwareListReducer,
    initialParams: initialParamsReducer,
    portalInfo: portalInfoReducer,
    forms: formsReducer,
    accounts: accountsReducer,
    globals: globalsReducer,

    /* slices */
    auth: authReducer,
    dashboard: dashboardReducer,
    sync: syncReducer,

    /* Services */
    [apiService.reducerPath]: apiService.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [syncApi.reducerPath]: syncApi.reducer
});

export default rootReducer;