import { useEffect, useState } from 'react';
import { useLocale } from './useLocale';

const localeToCurrency = {
  en: 'USD',
  de: 'EUR',
  es: 'EUR',
  fr: 'EUR',
  it: 'EUR',
  nl: 'EUR',
  'nl-be': 'EUR',
};

export const useCurrency = () => {
  const [formatter, setFormatter] = useState();
  const locale = useLocale();

  useEffect(() => {
    const currency = localeToCurrency[locale] || 'USD';
    setFormatter(Intl.NumberFormat(locale, { style: 'currency', currency, }));
  }, [locale]);

  const toCurrency = (value) => {
    let result = '';

    if (![null, undefined, ''].includes(value) && formatter) {
      result = formatter.format(value);
    }

    return result;
  };

  return { toCurrency };
};

export default useCurrency;