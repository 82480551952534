import React from 'react';
import { Box } from '@lightspeed/flame/Core';
import { Label } from '@lightspeed/flame/FormField';
import { SelectField } from '../fields';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { useTranslation } from 'react-i18next';

export const SplitPayment = () => {
    const [isLoadingAccounts] = useAccounts('payment');
    const { t } = useTranslation();
    const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
    const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;  

    return (
        <>
            {
                <Box width="49%" pr="1.1rem" mt="1rem" mb="1rem" ml="1rem">
                    <Label htmlFor="PaymentMethodTypeBreakdown">
                        {t('SplitCardPayment')}
                    </Label>
                    <SelectField id="PaymentMethodTypeBreakdown" name="PaymentMethodTypeBreakdown" disabled={isLoading}>
                        <option value="0">{t('No')}</option>
                        <option value="1">{t('Yes')}</option>
                    </SelectField>
                </Box>
            }
        </>
    )
}