import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    let newLocale = i18n.language || 'en';
    setLocale(newLocale.replace('_', '-').toLowerCase());
  }, [i18n.language]);

  return locale;
};

export default useLocale;