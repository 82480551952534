import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { Autocomplete } from '../fields';
import { useToasts } from '@lightspeed/flame/Toaster';
import { LabelRequired } from '../custom/LabelRequired';
import { useTranslation } from 'react-i18next';
import useAppSubmitting from '../../hooks/useAppSubmitting';
import useAccounts from '../../hooks/useAccounts';
import useConnectionMode from '../../hooks/useConnectionMode';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../utils/helpers/notifications';
import { setCurrentComponent } from '../../slices/dashboardSlice';

export const MapSalesByProducts = ({ formik }) => {
  const { addToast } = useToasts();
  const [isAppSubmitting] = useAppSubmitting();
  const { postingMethod, incomeAccount } = formik.values
  const isTypeCategory = postingMethod !== 'invoice';
  const [isLoadingAccounts, accountsList] = useAccounts('items');
  const [, softwareName] = useConnectionMode();
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;
  const canShowSalesIndividual = amIVisible("sales individual")
  const dispatch = useDispatch()
  const { showNotification } = useNotification()
  const canShowBothProductAndCategories = amIVisible("sales can show both product and categories")

  const isProductOnly = amIVisible("show only individual sales / po product mapping")

  const gotoMapping = () => {
    if (formik.dirty) {
      showNotification("Save the current changes to proceed", "error")
    } else {
      let comp = 'product'
      if (!isTypeCategory && canShowBothProductAndCategories) {
        comp = 'category'
      } else if (isTypeCategory) {
        comp = 'category'
      }

      if (isProductOnly) {
        comp = 'product'
      }

      dispatch(setCurrentComponent("sales " + comp + " mapping"))
    }
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (incomeAccount) {
      gotoMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'DefaultAccountFirst'), { appearance: 'error' });
    }
  }

  const getLabel = () => {
    
    if (isProductOnly) {
      return "Map individual products"
    }

    if (!isTypeCategory && canShowBothProductAndCategories) {
      return "Map individual categories and products"
    } else if (isTypeCategory) {
      return "Map individual categories"
    }

    return "Map individual products"
  }

  return <Box padding="0.75rem">
    <Heading3 mb="0.375rem">
      {isTypeCategory ? 'Sales by categories' : 'Sales by products'}
    </Heading3>
    <Text color="gray-600" mb="1.5rem" size="small">
      {t(
        isTypeCategory ? 'SalesByCategoryDescription' : 'SalesByProductDescription', {
        swName: softwareName,
      })}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="incomeAccount">
          {t('Default sales account')}
        </LabelRequired>
        <Autocomplete
          id="incomeAccount"
          name="incomeAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter default sales account' : 'Select an account')
          }
        />
      </Box>
      {canShowSalesIndividual && <Flex mt="1.4rem" alignItems="center" height="2.25rem">
        {isLoading ?
          <Bone height="1rem" width="12rem" /> :
          <TextLink size="small" fontWeight="bold" onClick={handleNavigation}>
            {getLabel()}
          </TextLink>
        }
      </Flex>}
    </Flex>
  </Box>;
};
