import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Text } from '@lightspeed/flame/Text';
import { Button } from '@lightspeed/flame/Button';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { Divider } from '@lightspeed/flame/Divider';
import { Label } from '@lightspeed/flame/FormField';
import { SyncStatusBadge } from '../custom/SyncStatusBadge';
import { SalesTable } from './SalesTable';
import { useState } from 'react';
import { PaymentsTable } from './PaymentsTable';
import { useTranslation } from 'react-i18next';
import styled from "@emotion/styled";
import { Bone } from '@lightspeed/flame/Bone';
import { PurchasesTable } from './PurchasesTable';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import useLocale from '../../hooks/useLocale';
import { useRole } from '../../hooks/useRole';
import useCurrency from '../../hooks/useCurrency';
import { useGetOrderQuery, useRepostSyncMutation } from '../../services/syncService';
import { useNotification } from '../../utils/helpers/notifications';
import { AdjustmentTable } from './AdjustmentTable';

const target = getCurrentTarget();

const ErrorReason = () => {
  const { t } = useTranslation();

  return <AlertInCard
    type="danger"
    width="auto"
    noCloseBtn
    mb="1.5rem"
  >
    {t('PaymentNotEqual')}
  </AlertInCard>;
};

const RepostWarning = ({ closeModal, confirmAction, swName, actionDate }) => {
  const locale = useLocale();
  const transactionDate = new Date(actionDate + ' 0:0:0');
  const transactionDateText = new Intl.DateTimeFormat(locale, { dateStyle: 'long' }).format(transactionDate);
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '600px', width: '600px' }} scroll={true}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.75rem">
        {t('RePostWarning.0', { transactionDate: transactionDateText })}
      </Text>
      <Text color="gray-600" fontSize="text-s" pr="2.25rem">
        {t('RePostWarning.1', {
          transactionDate: transactionDateText,
          swName,
        })}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal}>{t('Cancel')}</Button>
        <Button
          onClick={confirmAction}
          ml="0.75rem"
          variant="secondary"
          fill={true}
        >
          {t('RePost')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

// const ViewLogs = ({ disableActions, isDisabledClose, rowData }) => {
//   const { addToast } = useToasts();
//   const { t } = useTranslation();
//   const { addMessage } = useMessage();
//   const location = useLocation();

//   const handleError = (res) => {
//     addMessage(res, { default: t('Communication failure') });
//   };

//   const handleButtonClick = () => {
//     if (!isDisabledClose) {
//       disableActions(true);
//       const { orderNumber } = rowData;

//       getTransactionAnalysis(orderNumber, location.id).then(res => {
//         if (res.data) {
//           addToast(t('Check console logs for data'), { appearance: 'success' });
//         } else {
//           handleError(res);
//         }
//       }).catch(err => {
//         handleError(err.response);
//       }).then(() => {
//         disableActions(false);
//       });
//     }
//   };

//   return <Button
//     variant="secondary"
//     fill={true}
//     onClick={handleButtonClick}
//     loading={isDisabledClose}
//     mr="0.75rem"
//     style={{ whiteSpace: 'nowrap' }}
//   >{t('Get logs')}</Button>;
// };

const ResyncRepost = ({ disableActions, isDisabledClose, swName, rowData, closeSyncDetails, hasNoData, isTypePo }) => {
  const [isShowingWarning, setIsShowingWarning] = useState(false);
  let type = 'repost';
  const { t } = useTranslation();
  const [user] = useRole();

  const [repost] = useRepostSyncMutation()

  const { showNotification } = useNotification()

  if ([0, false].includes(rowData.success) || hasNoData) {
    type = 'resync';
  }

  const handleButtonClick = () => {
    if (type === 'repost') {
      setIsShowingWarning(true);
    } else {
      makeRequest();
    }
  };

  const closeWarning = () => {
    setIsShowingWarning(false);
  };

  const makeRequest = () => {
    if (!isDisabledClose) {
      disableActions(true);
      if (type === 'repost') {
        closeWarning();
      }

      const body = {
        id: rowData.id,
        repostingData: type === 'repost',
        userType: user.type,
        resyncPO: isTypePo
      };

      repost({
        url: "resync/",
        body
      })
      .unwrap()
      .then(() => {
        showNotification("Data Queued")
        closeSyncDetails({
          type,
          id: rowData.id,
        });
      })
    }
  };
  return <>
    {isShowingWarning &&
      <RepostWarning
        closeModal={closeWarning}
        confirmAction={makeRequest}
        swName={swName}
        actionDate={rowData.actionDate}
      />
    }
    {((type !== 'repost')) && <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{type === 'repost' ?
          <>
            {target.isRevel ?
              'You may view the logs of the transaction.' :
              t('RePostDesc')
            }
          </>
          :
          t('ResyncDesc', { errorMessage: rowData.errorMessage })
        }</Text>
        <Flex>
          {/* {!hasNoData &&
          <ViewLogs
            disableActions={disableActions}
            isDisabledClose={isDisabledClose}
            rowData={rowData}
          />
        } */}
          <Button
            variant="secondary"
            fill={true}
            onClick={handleButtonClick}
            loading={isDisabledClose}
          >{t('Resync')}</Button>
        </Flex>
      </Flex>
      {!hasNoData && <Divider mb="1.5rem" mt="1.5rem" />}
    </>
    }
  </>;
};

const MetaDetailRow = ({ label, value }) => {
  return <Flex justifyContent="space-between" mb="0.375rem">
    <Label>{label}</Label>
    {value ?
      <Text>{value}</Text>
      :
      <Bone height="1rem" width="100px" />
    }
  </Flex>;
};

const MetaDetailsLeft = ({ rowData, transactionData, isTypePo }) => {
  const locale = useLocale();
  const transactionDate = new Date(rowData.actionDate + ' 0:0:0');
  const transactionDateText = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(transactionDate);
  const { t } = useTranslation();

  return <Box width="50%">
    <MetaDetailRow label={t(isTypePo ? 'PO Date' : 'Date')} value={transactionDateText} />
    <MetaDetailRow label={t('Sync status')} value={<SyncStatusBadge type={rowData.success} />} />
    {!isTypePo &&
      <>
        <MetaDetailRow label={t('Reference number')} value={transactionData && transactionData.referenceNumber} />
        <MetaDetailRow label={t('Memo')} value={transactionData && transactionData.memo} />
      </>
    }
  </Box>;
};

const MetaDetailsRight = ({ transactionData, isTypePo }) => {
  const { t } = useTranslation();
  const { toCurrency } = useCurrency();

  return <Box width="50%">
    {isTypePo ?
      <>
        <MetaDetailRow label={isTypePo ? 'PO Number' : 'Reference Number'} value={transactionData && transactionData.referenceNumber} />
        <MetaDetailRow label={t('Memo')} value={transactionData && transactionData.memo} />
        <MetaDetailRow label={t('Tax amount')}
          value={transactionData && toCurrency(transactionData.taxAmount)}
        />
        <MetaDetailRow label={t('Total amount')}
          value={transactionData && toCurrency(transactionData.totalAmount)}
        />
        {<MetaDetailRow label="Vendor" value={transactionData && transactionData.vendorName} />}
      </>
      :
      <>
        {(!isTypePo) && <MetaDetailRow label={t('Customer')} value={transactionData && transactionData.customer} />}
        {false && <MetaDetailRow label={t('Tax rate')} value={transactionData && transactionData.taxRate} />}
        <MetaDetailRow label={t('Tax amount')}
          value={transactionData && toCurrency(transactionData.taxAmount)}
        />
        <MetaDetailRow label={t('Total amount')}
          value={transactionData && toCurrency(transactionData.totalAmount)}
        />
      </>}
  </Box>;
};

const MetaDetails = (props) => {
  // TODO: Vertical divider
  return <Flex mb="1.125rem">
    <MetaDetailsLeft {...props} />
    <Box pl="1.5rem" pr="1.5rem" />
    <MetaDetailsRight {...props} />
  </Flex>;
};

export const SyncDetails = ({ closeModal, swName, rowData, ...props }) => {
  const { isTypePo } = props;
  const [isDisabledClose, setIsDisabledClose] = useState(false);
  const { t } = useTranslation();

  const hasNoData = (![0, 1, 2, true, false].includes(rowData.success));

  const [key] = useState(Math.random())

  let { data: transactionData } = useGetOrderQuery({ key, orderNumber: rowData.id }, { skip: rowData.success === 3 })

  const handleClose = () => {
    if (!isDisabledClose) {
      closeModal();
    }
  };

  const getRows = (fieldName) => {
    return transactionData ? transactionData[fieldName] : [];
  };

  const CustomModal = styled(Modal)`
    max-height: 100%;
  `;

  return <CustomModal
    isOpen={true}
    onRequestClose={handleClose}
  >
    <ModalHeader>{t('Sync details')}</ModalHeader>
    <ModalBody style={{ maxWidth: '800px', width: '800px' }} scroll={true}>
      {false && <ErrorReason />}
      {(rowData.success !== 2) &&
        <ResyncRepost
          disableActions={setIsDisabledClose} 
          swName={swName}
          isDisabledClose={isDisabledClose}
          rowData={rowData}
          closeSyncDetails={closeModal}
          hasNoData={hasNoData}
          isTypePo={isTypePo}
        />
      }
      {!hasNoData &&
        <>
          <MetaDetails rowData={rowData} transactionData={transactionData} {...props} />
          {isTypePo && <PurchasesTable
            data={getRows('purchases')}
            postingType={transactionData && transactionData.postingType}
            {...props}
          />}
          {!isTypePo && <SalesTable
            data={getRows('sales')}
            mappingType={transactionData && transactionData.mappingType}
            {...props}
          />}
          {!isTypePo && <SalesTable
            data={getRows('refundSales')}
            heading="Refund Sales"
            mappingType={transactionData && transactionData.mappingType}
            {...props}
          />}
          {!isTypePo && <PaymentsTable data={getRows('payments')} {...props} />}
          {!isTypePo && <PaymentsTable data={getRows('refundPayments')} heading="Refund Payments" {...props} />}
          <AdjustmentTable data={getRows('adjustments')} heading={t('Adjustments')} />
        </>
      }
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={handleClose} disabled={isDisabledClose}>
          {t('Close')}
        </Button>
      </Flex>
    </ModalFooter>
  </CustomModal>;
};
