
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lightspeed/flame/Modal';
import { Text } from '@lightspeed/flame/Text';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Button } from '@lightspeed/flame/Button';
import { useEffect, useState } from 'react';
import { Input } from '@lightspeed/flame/Input';
import { createRef } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../../slices/authSlice';
import { useNotification } from '../../utils/helpers/notifications';
import { useUpdateDomainMutation } from '../../services/authService';

export const AuthDomainEdit = ({
    response,
    onClose
}) => {

    const [showUpdateModal, setUpdateModal] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const inputRef = createRef(null)
    const dispatch = useDispatch()
    const { showNotification } = useNotification()
    const [updateDomain, { isLoading }] = useUpdateDomainMutation()

    const {
        domain,
        oauthUrl,
        jwtToken
    } = response;

    useEffect(() => {
        dispatch(setToken(jwtToken))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showDomainNameModal = () => {
        return (
            <>
                <ModalHeader>
                    Verify Domain Name
                </ModalHeader>
                <ModalBody>
                    <Flex mt="1rem" flexDirection={"row"} alignItems={"center"}>
                        <Text fontWeight={"500"}>Please confirm the domain name : </Text>
                        <Text ml=".3rem" fontWeight={"700"}>
                            {domain ?? ""}
                        </Text>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent={"end"}>
                        <Button fill disabled={disabled} loading={disabled} variant='default' mr=".8rem" onClick={() => setUpdateModal(true)}>
                            No, Update Domain name
                        </Button>
                        <Button fill disabled={disabled} loading={disabled} variant='secondary' onClick={() => {
                            window.open(oauthUrl, "_self")
                            setDisabled(true)
                        }}>
                            Yes, Domain name is correct
                        </Button>
                    </Flex>
                </ModalFooter>
            </>
        )
    }

    const showUpdateDomainNameModal = () => {

        return (
            <>
                <ModalHeader>
                    Update Domain Name
                </ModalHeader>
                <ModalBody>
                    <Flex flexDirection={"column"}>
                        <Text fontWeight={"500"} mb=".4rem">Please enter the new domain name : </Text>
                        <Input
                            ref={inputRef}
                            placeholder='posaccounting'
                            width={"70%"}
                        />
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Flex>
                        <Button
                            variant='secondary'
                            fill
                            onClick={() => { 
                                if (inputRef?.current?.value?.length === 0) {
                                    showNotification("Please enter a valid domain", "error")
                                } else {
                                    updateDomain({
                                        newDomainName: inputRef?.current?.value
                                    })
                                    .unwrap()
                                    .then(res => window.open(res.oauthUrl, "_self"))
                                }
                            }}
                            loading={isLoading}
                        >
                            Update Domain name
                        </Button>
                    </Flex>
                </ModalFooter>
            </>
        )
    }

    return (
        <>
            <Modal isOpen shouldCloseOnOverlayClick={false} onRequestClose={onClose}>
                <Box width={"600px"}>
                    {showUpdateModal ? showUpdateDomainNameModal() : showDomainNameModal()}
                </Box>
            </Modal>
        </>
    )
}