import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Box } from '@lightspeed/flame/Core';
import { MapSalesByProducts } from '../mapSales/MapSalesByProducts';
import { MapPaymentMethods } from '../mapPaymentMethods/MapPaymentMethods';
import { MapDiscounts } from '../mapDiscounts/MapDiscounts';
import { MapTips } from '../mapTips/MapTips';
import { MapServiceCharges } from '../mapServiceCharges/MapServiceCharges';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { useTranslation } from 'react-i18next';
import { LearnMore } from '../custom/LearnMore';
import { KeepInvoice } from '../keepInvoice/KeepInvoice';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import { useIsInputsOnlyLayout } from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { SplitPayment } from '../mapPaymentMethods/splitPayment';
import { TaxMapping } from '../mapTaxCodes/taxMapping';
import { MapStoreCredit } from '../mapCreditSales/mapCreditSales';
import { MapGiftcard } from '../mapGiftcard/mapGiftcard';
import { SelectSpecificTime } from '../mapSpecificTime/SelectSpecificTime';
import { MapNonCashFee } from '../MapNonCashFee/MapNonCashFee';
import { MapMultipleStoreCredit } from '../mapCreditSales/mapMultipleCreditSales';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { MapCashRounding } from '../MapCashRounding/MapCashRounding';
import { Membership } from '../mapMembership/mapMembership';
import { Series } from '../mapSeries/mapSeries';
import { MapAccountPayment } from '../mapAccountPayment/mapAccountPayment';
import { MapLoyality } from '../mapLoyality/mapLoyality';

const target = getCurrentTarget();

const Section = ({ children }) => {
  return <>
    <CardSection>
      {children}
    </CardSection>
    <Divider />
  </>
};

const MapAccountsContent = (props) => {
  const { postingMethod, specificSynctype } = props.formik.values;
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const { country } = useSelector(state => state.auth)

  /* Keep Invoice */
  let canShowKeepInvoice = amIVisible("keep invoice open for credit sales")

  /* Keep Invoice */
  let canShowEODGroup = amIVisible("end of summary groups")

  if (canShowKeepInvoice) {
    canShowKeepInvoice = postingMethod !== 'journalEntry'
  }

  /* sales */
  let canShowSales = amIVisible("sales")

  /* payments */
  const canShowPayment = amIVisible('payment')

  /* discount */
  let canShowDiscount = amIVisible("discount")

  /* Cash Rounding */
  let canShowCashRounding = amIVisible("CashRounding")

  if (!_.isEmpty(country)) {
    if (country !== "CA") {
      canShowCashRounding = false
    }
  }

  if (canShowDiscount) {
    canShowDiscount = postingMethod && !isInputsOnlyLayout
  }

  if (parseInt(specificSynctype) !== 2) {
    canShowEODGroup = false
  }

  return <Card ml="1rem">
    {canShowEODGroup && <>
      <Box padding={".75rem"}>
        <SelectSpecificTime formik={props?.formik} />
      </Box>
      <Divider />
    </>}
    {canShowKeepInvoice && (
      <>
        <Box padding={".75rem"}>
          <KeepInvoice formik={props?.formik} />
        </Box>
        <Divider />
      </>
    )}
    {canShowSales &&
      <>
        <Section>
          <MapSalesByProducts {...props} />
        </Section>
      </>
    }
    {

    }
    {canShowPayment &&
      <>
        <Section>
          <MapPaymentMethods {...props} />
        </Section>
      </>
    }
    {amIVisible("split credit card payment by card type") &&
      <Section>
        <SplitPayment />
      </Section>
    }
    {amIVisible("tax rates") && <Section>
      <TaxMapping {...props} />
    </Section>}
    {canShowDiscount &&
      <Section>
        <MapDiscounts {...props} />
      </Section>
    }
    {amIVisible("tips") &&
      <Section>
        <MapTips {...props} />
      </Section>
    }
    {
      amIVisible("membership") && 
      <Section>
        <Membership {...props} />
      </Section>
    }
    {
      amIVisible("Account Payment") && 
      <Section>
        <MapAccountPayment {...props} />
      </Section>
    }
    {
      amIVisible("series") && 
      <Section>
        <Series {...props} />
      </Section>
    }
    {canShowCashRounding &&
      <Section>
        <MapCashRounding {...props} />
      </Section>
    }
    {amIVisible("giftCard") &&
      <Section>
        <MapGiftcard {...props} />
      </Section>
    }
    {amIVisible("storeCredit") &&
      <Section>
        <MapStoreCredit {...props} />
      </Section>
    }
    {amIVisible("loyality") &&
      <Section>
        <MapLoyality {...props} />
      </Section>
    }
    {amIVisible("show expense storecredit") &&
      <Section>
        <MapMultipleStoreCredit {...props} />
      </Section>
    }
    {amIVisible("service charge") &&
      <Section>
        <MapServiceCharges {...props} />
      </Section>
    }
    {amIVisible("MapNonCashFee") &&
      <Section>
        <MapNonCashFee {...props} />
      </Section>
    }
  </Card>;
};

export const MapAccounts = (props) => {
  const { t } = useTranslation();

  return <AnnotatedLayout
    title={t('Map Accounts')}
    description={t('MapAccountsDescription', { target: target.fullName })}
    renderExtras={
      <>
        <LearnMore />
        <RefreshAccounts dirty={props?.formik?.dirty} />
      </>
    }
    mt="2.25rem"
    mb="2.25rem"
  >
    <MapAccountsContent {...props} />
  </AnnotatedLayout>;
};
