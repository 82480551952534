import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { Label } from '@lightspeed/flame/FormField';

const HalfBox = ({ children }) => {
  return <Box width="45%" ml="0.75rem" mr="0.75rem">
    {children}
  </Box>;
};

export const ShippingSection = (props) => {
  const [isLoading, accountsList] = useAccounts('shipping');
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  
  const [, cogsAccountsList] = useAccounts('cogs')
  const canShowCogsInExpense = amIVisible("show cogs account in po shipping")

  return <Box>
    <Flex>
      {amIVisible("po discount") &&
        <HalfBox>
          <Label
            htmlFor="poDiscountAccount"
          >
            {t('Discount')}
          </Label>
          <Autocomplete
            id="poDiscountAccount"
            name="poDiscountAccount"
            options={accountsList}
            isLoading={isLoading}
            placeholder={isInputsOnlyLayout ? "Enter an account" : 'Select an account'}
          />
        </HalfBox>}
      {amIVisible("po discount additional") &&
        <HalfBox>
          <Label
            htmlFor="poAdditionalDiscountAccount"
          >
            {t('Additional Discount')}
          </Label>
          <Autocomplete
            id="poAdditionalDiscountAccount"
            name="poAdditionalDiscountAccount"
            isLoading={isLoading}
            options={accountsList}
            placeholder={isInputsOnlyLayout ? "Enter an account" : 'Select an account'}
          />
        </HalfBox>}
    </Flex>
    <Flex mt="1.5rem">
      {amIVisible("shipping account") && <HalfBox>
        <LabelRequired
          htmlFor="shipping"
        >
          {t('Shipping')}
        </LabelRequired>
        <Autocomplete
          id="shipping"
          name="shipping"
          isLoading={isLoading}
          options={canShowCogsInExpense ? cogsAccountsList : accountsList}
          placeholder={isInputsOnlyLayout ? "Enter an account" : 'Select an account'}
        />
      </HalfBox>}
      {amIVisible("freight charges account") && <HalfBox>
        <LabelRequired
          htmlFor="freightCharges"
        >
          {t('Freight charges')}
        </LabelRequired>
        <Autocomplete
          id="freightCharges"
          name="freightCharges"
          isLoading={isLoading}
          options={accountsList}
          placeholder={isInputsOnlyLayout ? "Enter an account" : 'Select an account'}
        />
      </HalfBox>}
    </Flex>
    <Flex mt="1.5rem">
      {amIVisible("other exponses account") && <HalfBox>
        <LabelRequired
          htmlFor="otherExpenses"
        >
          {t('Other expenses')}
        </LabelRequired>
        <Autocomplete
          id="otherExpenses"
          name="otherExpenses"
          isLoading={isLoading}
          options={accountsList}
          placeholder={isInputsOnlyLayout ? "Enter an account" : 'Select an account'}
        />
      </HalfBox>}
    </Flex>
  </Box>
};
