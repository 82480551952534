import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Autocomplete, TextInput } from '../fields';
import { Divider } from '@lightspeed/flame/Divider';
import useConnectionMode from '../../hooks/useConnectionMode';
import { useGetPoTaxesQuery } from '../../services/dashboardService';
import { useEffect } from 'react';
import { RowLoader } from '../custom/rowLoader';
import amIVisible from '../../componentControl/registry';
import { IconDelete } from '@lightspeed/flame/Icon/Delete';
import DynamicFields from '../custom/DynamicFields';

export const PoTaxMapping = (props) => {

    const { formik } = props

    const { poTaxes: taxes, isPoTaxFetching, fetchedConfig, isPostingMethodChanged } = useSelector(state => state.dashboard)
    const portal = useSelector(state => state.auth.fullName)

    const { isLoading, isFetching, isSuccess } = useGetPoTaxesQuery({}, { refetchOnMountOrArgChange: true })

    const [, swName] = useConnectionMode()

    useEffect(() => {
        if ((taxes?.taxLabels?.length > 0 && !fetchedConfig.POInventoryCogs) || isPostingMethodChanged) {
            formik.setFieldValue('poTaxMapping', taxes?.taxLabels)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPostingMethodChanged, taxes, isSuccess])

    const { poTaxMapping: taxMapping } = formik?.values

    const isDynamic = amIVisible("dynamic po taxes")

    return (
        <Box padding="0.75rem">
            <Heading3 mb="0.375rem">{'Map purchase tax rates'}</Heading3>
            <Text color="gray-600" mb="1.125rem" size="small">
                {`Purchase tax rates can be mapped to different ${swName} accounts.`}
            </Text>
            <FieldArray name="poTaxMapping">
                {
                    (array) => {
                        const width = `${100 / 2}%`
                        return (
                            <Card>
                                <>
                                    <CardSection>
                                        <Flex width={"100%"} mb=".6rem">
                                            <Box width={width} pl="0.8rem" pr="0.8rem">
                                                <Text fontWeight="bold">{portal} {' '} purchase Tax Rate</Text>
                                            </Box>
                                            <Box width={width} pl="0.8rem" pr="0.8rem">
                                                <Text fontWeight="bold">Tax Accounts</Text>
                                            </Box>
                                        </Flex>
                                        <Divider />
                                    </CardSection>
                                    {isPoTaxFetching ? <RowLoader box={false} rows={6} /> :
                                        <>
                                            {
                                                taxMapping?.map((row, index) => {
                                                    return (
                                                        <CardSection>
                                                            <Flex alignItems={"center"} width={"100%"} mb=".5rem">
                                                                <Box alignItems={"center"} width={width} pl="0.8rem" pr="0.8rem">
                                                                    <Text>{row?.id}</Text>
                                                                </Box>
                                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                                    <Autocomplete
                                                                        name={`poTaxMapping[${index}].account`}
                                                                        formik={formik}
                                                                        options={taxes?.taxAccounts}
                                                                        placeholder={"Select Tax Account"}
                                                                        isLoading={isLoading || isFetching}
                                                                    />
                                                                </Box>
                                                                {(isDynamic && row?.id !== "Exempt - 0%") && <IconDelete
                                                                    color="danger"
                                                                    pointer="cursor"
                                                                    style={{ marginLeft: '0.75rem', pointer: 'cursor' }}
                                                                    onClick={() => {
                                                                        array.remove(index)
                                                                    }}
                                                                />}
                                                                {
                                                                    ((isDynamic && row?.id === "Exempt - 0%") && <div style={{ width: "1.7rem" }} />)
                                                                }
                                                            </Flex>
                                                            <Divider />
                                                        </CardSection>
                                                    )
                                                })
                                            }
                                            {isDynamic && <DynamicFields labels={taxMapping} title={"Purchase Tax Rate"} options={taxes?.taxAccounts} onFieldAdd={(id = '', account = '') => {
                                                formik.setFieldValue('poTaxMapping', [...taxMapping, { id, account }])
                                            }} />}
                                        </>}
                                </>
                            </Card>
                        )
                    }
                }
            </FieldArray>
        </Box>
    )
}