import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  successAndFailCounts: '',
  connectionMode: '',
};

const portalInfoSlice = createSlice({
  name: 'portalInfo',
  initialState,
  reducers: {
    setSuccessAndFailCounts: (state, action) => {
      state.successAndFailCounts = action.payload;
    },
    setConnectionMode: (state, action) => {
      state.connectionMode = action.payload;
    },
    resetPortalInfo: () => {
      return initialState;
    }
  }
});

export const { setSuccessAndFailCounts, setConnectionMode, resetPortalInfo } = portalInfoSlice.actions;

export default portalInfoSlice.reducer;
