import { Text } from '@lightspeed/flame/Text';
import { Flex } from '@lightspeed/flame/Core';
import { Label } from '@lightspeed/flame/FormField';
import { useTranslation } from 'react-i18next';

export const LabelRequired = ({ children, ...props }) => {
  const { t } = useTranslation();

  return <Label {...props}>
    <Flex
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <div>{children}</div>
      <Text color="gray-600" fontWeight="normal">{t('Required')}</Text>
    </Flex>
  </Label>;
};
