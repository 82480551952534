import { Table, Tbody, Td, Th, Thead, Tr } from '@lightspeed/cirrus-table';
import { useTranslation } from 'react-i18next';
import { TableWithHeading } from './TableWithHeading';
import { IconCheckmark } from '@lightspeed/flame/Icon/Checkmark';
import { Bone } from '@lightspeed/flame/Bone';
import useCurrency from '../../hooks/useCurrency';

export const AdjustmentTable = (props) => {
    const {
        data,
        heading
    } = props;

    const { t } = useTranslation();

    const { toCurrency } = useCurrency();

    const TableContent = ({ data }) => {
        return (
            <>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>
                                {t('Name')}
                            </Th>
                            <Th>
                                {t('Account')}
                            </Th>
                            <Th>
                                {t('Credit')}
                            </Th>
                            <Th>
                                {t('Debit')}
                            </Th>
                            <Th>
                                {t('Amount')}
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data ?
                                <>
                                    {data.length === 0 ?
                                        <Tr>
                                            <td colSpan="5">{t('No rows to show')}</td>
                                        </Tr>
                                        :
                                        data?.map((obj) => (
                                            obj?.accounts?.map((item, idx) => (
                                                <Tr key={idx}>
                                                    <Td>{obj?.name}</Td>
                                                    <Td>{item?.name}</Td>
                                                    <Td>
                                                        {item?.type === "Credit" && <IconCheckmark />}
                                                    </Td>
                                                    <Td>
                                                        {item?.type === "Debit" && <IconCheckmark />}
                                                    </Td>
                                                    <Td>
                                                        {item?.amount ? toCurrency(item?.amount)  : ''}
                                                    </Td>
                                                </Tr>
                                            ))
                                        ))
                                    }
                                </>
                                :
                                [1, 2, 3, 4, 5].map((item, index) => (
                                    <Tr key={index}>
                                        <Td><Bone height="1rem" /></Td>
                                        <Td><Bone height="1rem" /></Td>
                                        <Td><Bone height="1rem" /></Td>
                                        <Td><Bone height="1rem" /></Td>
                                        <Td><Bone height="1rem" /></Td>
                                    </Tr>
                                ))
                        }
                    </Tbody>
                </Table>
            </>
        )
    }

    return <>
        {data?.length > 0 && (
            <TableWithHeading heading={heading}>
                <TableContent data={data} />
            </TableWithHeading>
        )}
    </>
};