import _ from "lodash"

export const parseCogsMapping = (label, values) => {

    if (label?.length === 0) {
        return parseOnlyValues(values, true)
    }

    label = _.filter(label, item => {
        return _.keys(values).includes(item?.id + '')
    })

    return label?.map(item => {
        return {
            ...item,
            account: values[item?.id]?.inventoryAccount ?? '',
            cogs: values[item?.id]?.expenseAccount ?? ''
        }
    })
}

export const parseAdvancedMapping = (label, values) => {

    if (label?.length === 0) {
        return parseOnlyValues(values, false)
    }

    label = _.filter(label, item => {
        return _.keys(values).includes(item?.id + '')
    })

    return label?.map(item => {
        return {
            ...item,
            account: values[item?.id] ?? ""
        }
    })
}

export const parseIndividualMapping = (options, mapping) => {

    if (options?.length === 0) {
        return parseOnlyValues(mapping)
    }

    return options?.map(items => {
        return {
            id: items.id,
            label: items.name,
            account: mapping ? mapping[items?.id] ?? '' : '',
            type: items?.typeId
        }
    })
}

export const optionsParser = (options) => {
    return options?.map(items => {
        return {
            label: items?.name ?? "",
            value: items?.id ?? ""
        }
    })
}

export const checkHasValue = (value) => {
    if (!value) {
        return false
    }

    return [...new Set(_.values(value))][0] !== ''
}

export const payloadIndividualParser = (value) => {
    let obj = {}

    for (let i = 0; i < value.length; i++) {
        obj[value[i]?.id] = value[i]?.account ?? ""
    }

    return obj
}

export const payloadPoParser = (value) => {
    let obj = {}

    for (let i = 0; i < value.length; i++) {
        obj[value[i]?.id] = {
            inventoryAccount: value[i]?.account ?? "",
            expenseAccount: value[i]?.cogs ?? "",
        }
    }

    return obj
}

export const parseOnlyValues = (values, isPo = false) => {
    return _.keys(values ?? {})?.map(key => {

        if (isPo) {
            return {
                id: key,
                name: key,
                label: key,
                account: values[key]?.inventoryAccount ?? '',
                cogs: values[key]?.expenseAccount ?? ''
            }
        }

        return {
            id: key,
            name: key,
            label: key,
            account: values[key] ?? ''
        }
    })
}