import { FieldArray } from 'formik';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Autocomplete, TextInput } from '../fields';
import { Divider } from '@lightspeed/flame/Divider';
import { RowLoader } from '../custom/rowLoader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageLayout } from '../pageLayout/PageLayout';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { LearnMore } from './LearnMore';
import { Label } from '@lightspeed/flame/FormField';
import { useState } from 'react';
import { Button } from '@lightspeed/flame/Button';
import { IconAdd } from '@lightspeed/flame/Icon/Add';
import { IconDelete } from '@lightspeed/flame/Icon/Delete';
import _ from 'lodash';
import configs from "../mapSales/config.json";
import { useNotification } from '../../utils/helpers/notifications';
import { Empty, Radio } from 'antd';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@lightspeed/flame/Modal';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import DynamicFields from './DynamicFields';
import amIVisible from '../../componentControl/registry';
import { Input } from '@lightspeed/flame/Input';

export const FullPageWrapper = (props) => {

    const {
        title,
        fullPageDesp,
        formik,
        advanceMapping,
        refetch,
        isDynamic
    } = props

    return (
        <>
            <PageLayout
                title={title}
                formik={formik}
                refetch={refetch}
                showBack
            >
                <AnnotatedLayout
                    title={title}
                    description={fullPageDesp}
                    renderExtras={
                        <>
                            <LearnMore />
                            {!isDynamic && <RefreshAccounts />}
                        </>
                    }
                    mb="2.25rem"
                >
                    {advanceMapping ?
                        <AdvanceMappingLayout {...props} />
                        :
                        <IndividualMappingLayout {...props} />
                    }
                </AnnotatedLayout>
            </PageLayout>
        </>
    )
}

export const AdvanceMappingLayout = (props) => {

    const {
        formik,
        isLoading,
        isAccountLoading,
        formikFieldName,
        heading,
        options,
        rowLoaderProps,
        labelOption,
        refetch,
        column1,
        column2,
        parentCategory,
        isPo,
        cogsAccounts,
        isDynamicMapping
    } = props

    const { currentSw, isGlobalLoading } = useSelector(state => state.dashboard)

    const { fullName } = useSelector(state => state.auth)

    const [selected, setSelected] = useState('')

    const [showModal, setShowModal] = useState(null);

    const { showNotification } = useNotification()

    const { t } = useTranslation()

    const mapping = formik.values[formikFieldName]

    const canShowParentCategory = heading === "Categories" && amIVisible("groupby")

    const ConfirmationModal = () => {
        return (
            <Modal
                isOpen
                onRequestClose={() => {
                    setShowModal(null)
                }}
            >
                <ModalHeader>{t('Warning')}</ModalHeader>
                <ModalBody style={{ maxWidth: '600px', width: '600px' }}>
                    <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
                        {t('ConfirmCatLevelChange.0')}
                    </Text>
                    <Text mb="0.5rem" color="gray-600" fontSize="text-s">
                        {t('ConfirmCatLevelChange.1')}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent="flex-end">
                        <Button onClick={() => {
                            setShowModal(null)
                        }} mr="0.8rem">{'Cancel'}</Button>
                        <Button fill="true" variant="danger" onClick={() => {
                            if (!isPo) {
                                formik.setFieldValue('isParentCategory', showModal)
                            } else {
                                formik.setFieldValue('swapSuperParentCategoryFromProducts', showModal)
                            }
                            formik.setFieldValue(formikFieldName, [])
                            refetch(showModal)
                            setShowModal(null)
                        }}>
                            {'Change'}
                        </Button>
                    </Flex>
                </ModalFooter>
            </Modal>
        )
    }

    return <>
        {showModal !== null && <ConfirmationModal />}
        <Box>
            <FieldArray name={formikFieldName}>
                {
                    (array) => {
                        const width = `${100 / (column2 ? 3 : 2)}%`
                        return (
                            <>
                                <Card mb="1.125rem">
                                    <CardSection pl={0} pr={0} pt="1.5rem" pb="1.5rem">
                                        <Box pl="1.5rem" pr="1.5rem">
                                            <Label
                                                description={`Add a ${heading} to the list below to map it to a custom ${currentSw} accounts.`}
                                                descriptionProps={{ marginTop: '0.375rem', marginBottom: '0.75rem' }}
                                                htmlFor="selectedOption"
                                            >Add {heading}</Label>
                                        </Box>
                                        {
                                            canShowParentCategory && (
                                                <>
                                                    <Flex p="1rem" pl="1.5rem">
                                                        <Radio.Group disabled={isLoading || isGlobalLoading} onChange={(e) => {
                                                            setShowModal(e.target.value)
                                                            setSelected('')
                                                        }} value={Number(parentCategory ?? 0)}>
                                                            {
                                                                _.keys(configs)?.map(key => {
                                                                    return (
                                                                        <>
                                                                            <Radio value={parseInt(key)}>
                                                                                <Text>{configs[key]?.label}</Text>
                                                                            </Radio>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Radio.Group>
                                                    </Flex>
                                                    <Divider mb="1.1rem" />
                                                </>
                                            )
                                        }
                                        <Flex pl="1.5rem" pr="1.5rem">
                                            <Box minWidth="20rem">
                                                {isDynamicMapping ?
                                                    <Input
                                                        id="selectedOption"
                                                        name="selectedOption"
                                                        isLoading={isLoading}
                                                        placeholder={`Enter ${heading} name`}
                                                        value={selected}
                                                        onChange={(evt) => {
                                                            setSelected(evt.target.value)
                                                        }}
                                                    />
                                                    : <Autocomplete
                                                        id="selectedOption"
                                                        name="selectedOption"
                                                        isLoading={isLoading}
                                                        dropdown
                                                        options={labelOption?.filter((option) => !(Array.isArray(mapping) ? mapping : [])?.map(i => i?.id).includes(option?.value))}
                                                        placeholder={`Enter ${heading} name`}
                                                        value={selected}
                                                        onChange={setSelected}
                                                    />}
                                            </Box>
                                            <Button
                                                variant="secondary" fill={true} ml="0.75rem" pl="0.625rem" pt="0.625rem" pb="0.625rem"
                                                disabled={isLoading || isGlobalLoading}
                                                onClick={() => {
                                                    if (selected !== '') {
                                                        if (isDynamicMapping) {
                                                            if (!mapping?.map(row => row?.id)?.includes(selected)) {
                                                                array.push({
                                                                    id: selected,
                                                                    name: selected,
                                                                    account: '',
                                                                    cogs: ''
                                                                })
                                                                setSelected('')
                                                            } else {
                                                                showNotification(`${heading} is already mapped.`, "error")
                                                            }
                                                        } else {
                                                            array.push({
                                                                id: selected.value,
                                                                name: selected.label,
                                                                account: '',
                                                                cogs: ''
                                                            })
                                                            setSelected('')
                                                        }

                                                    } else {
                                                        if (isDynamicMapping) {
                                                            showNotification(`Enter a ${heading} first`, { type: 'error' })
                                                        } else {
                                                            showNotification("Select an option first", { type: 'error' })
                                                        }
                                                    }
                                                }}
                                                value={selected}
                                            >
                                                <IconAdd />
                                                <Text>{'Add'}</Text>
                                            </Button>
                                        </Flex>
                                    </CardSection>
                                </Card>
                                <>
                                    <Card>
                                        <>
                                            <CardSection>
                                                <Flex width={"100%"} mb=".6rem">
                                                    <Box width={width} pl="0.8rem" pr="0.8rem">
                                                        <Text fontWeight="bold">{`${fullName} ${heading}`}</Text>
                                                    </Box>
                                                    <Box width={width} pl="0.8rem" pr="0.8rem">
                                                        <Text fontWeight="bold">{currentSw} {column1} Accounts</Text>
                                                    </Box>
                                                    {column2 && <Box width={width} pl="0.8rem" pr="0.8rem">
                                                        <Text fontWeight="bold">{currentSw} {column2} Accounts</Text>
                                                    </Box>}
                                                </Flex>
                                                <Divider />
                                            </CardSection>
                                            {isLoading ? <RowLoader {...rowLoaderProps} /> :
                                                <>
                                                    {
                                                        (mapping?.length === 0 || !mapping) && <Flex justifyContent={"center"}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Flex>
                                                    }
                                                    {
                                                        mapping?.map((row, index) => {
                                                            return (
                                                                <CardSection>
                                                                    {index !== 0 && <Divider mb="1rem" />}
                                                                    <Flex alignItems={"center"} width={"100%"} mb=".5rem">
                                                                        <Box alignItems={"center"} width={width} pl="0.8rem" pr="0.8rem">
                                                                            <Text>{row?.name}</Text>
                                                                        </Box>
                                                                        <Box width={width} pl="0.8rem" pr="0.8rem">
                                                                            <Box display="grid" style={{ gridTemplateColumns: 'auto 2.125rem', alignItems: 'center' }}>
                                                                                <Autocomplete
                                                                                    name={`${formikFieldName}[${index}].account`}
                                                                                    formik={formik}
                                                                                    options={options}
                                                                                    placeholder={"Select an Account"}
                                                                                    isLoading={isAccountLoading}
                                                                                />
                                                                                {!column2 && <IconDelete
                                                                                    color="danger"
                                                                                    pointer="cursor"
                                                                                    style={{ marginLeft: '0.75rem', pointer: 'cursor' }}
                                                                                    onClick={() => {
                                                                                        array.remove(index)
                                                                                    }}
                                                                                />}
                                                                            </Box>
                                                                        </Box>
                                                                        {column2 && <Box width={width} pl="0.8rem" pr="0.8rem">
                                                                            <Box display="grid" style={{ gridTemplateColumns: 'auto 2.125rem', alignItems: 'center' }}>
                                                                                <Autocomplete
                                                                                    name={`${formikFieldName}[${index}].cogs`}
                                                                                    formik={formik}
                                                                                    options={cogsAccounts ?? []}
                                                                                    placeholder={"Select an Account"}
                                                                                    isLoading={isAccountLoading}
                                                                                />
                                                                                <IconDelete
                                                                                    color="danger"
                                                                                    pointer="cursor"
                                                                                    style={{ marginLeft: '0.75rem', pointer: 'cursor' }}
                                                                                    onClick={() => {
                                                                                        array.remove(index)
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Box>}
                                                                    </Flex>
                                                                </CardSection>
                                                            )
                                                        })
                                                    }
                                                </>}
                                        </>
                                    </Card>
                                </>
                            </>
                        )
                    }
                }
            </FieldArray>
        </Box>
    </>
}

export const IndividualMappingLayout = (props) => {

    const {
        formik,
        isLoading,
        isAccountLoading,
        formikFieldName,
        heading,
        desp,
        options,
        paymentLiabilityList,
        labels,
        rowLoaderProps,
        isDynamic,
        dynamicFieldTitle
    } = props

    const { t } = useTranslation()
    const { currentSw } = useSelector(state => state.dashboard)
    const { fullName } = useSelector(state => state.auth)

    return (
        <>
            <Box padding="0.75rem">
                {desp && <>
                    <Heading3 mb="0.375rem">{t(heading)}</Heading3>
                    <Text color="gray-600" mb="1.125rem" size="small">
                        {t(desp, { swName: currentSw })}
                    </Text>
                </>}
                <FieldArray name={formikFieldName}>
                    {
                        (array) => {
                            const width = `${100 / 2}%`
                            return (
                                <Card>
                                    <>
                                        <CardSection>
                                            <Flex width={"100%"} mb=".6rem">
                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                    <Text fontWeight="bold">{`${fullName} ${heading}`}</Text>
                                                </Box>
                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                    <Text fontWeight="bold">{currentSw}</Text>
                                                </Box>
                                            </Flex>
                                            <Divider />
                                        </CardSection>
                                        {isLoading ? <RowLoader {...rowLoaderProps} /> :
                                            <>
                                                {
                                                    labels?.map((row, index) => {
                                                        return (
                                                            <CardSection>
                                                                <Flex alignItems={"center"} width={"100%"} mb=".5rem">
                                                                    <Box alignItems={"center"} width={width} pl="0.8rem" pr="0.8rem">
                                                                        <Text>{row?.label}</Text>
                                                                    </Box>
                                                                    <Box width={width} pl="0.8rem" pr="0.8rem">
                                                                        <Autocomplete
                                                                            name={`${formikFieldName}[${index}].account`}
                                                                            formik={formik}
                                                                            options={row?.type === "Liability" ? paymentLiabilityList : options}
                                                                            placeholder={"Select an Account"}
                                                                            isLoading={isAccountLoading}
                                                                        />
                                                                    </Box>
                                                                    {isDynamic && <IconDelete
                                                                        color="danger"
                                                                        pointer="cursor"
                                                                        style={{ marginLeft: '0.75rem', pointer: 'cursor' }}
                                                                        onClick={() => {
                                                                            array.remove(index)
                                                                        }}
                                                                    />}
                                                                </Flex>
                                                                <Divider />
                                                            </CardSection>
                                                        )
                                                    })
                                                }
                                                {isDynamic && <DynamicFields labels={labels ?? []} title={dynamicFieldTitle} options={options} onFieldAdd={(id = '', account = '') => {
                                                    formik.setFieldValue(formikFieldName, [...labels ?? [], { id, label: id, account }])
                                                }} />}
                                            </>}
                                    </>
                                </Card>
                            )
                        }
                    }
                </FieldArray>
            </Box>
        </>
    )
}