import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { useSelector } from 'react-redux';

export const MapLoyality = () => {
    const [, softwareName] = useConnectionMode();
    const [isLoading, accountsList] = useAccounts('paymentLiability');
    const [, expenseAccountList] = useAccounts('chargebacks');
    const { t } = useTranslation();
    const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

    const { fullName } = useSelector(state => state.auth)

    return <Box padding="0.75rem">
        <Heading3 mb="0.375rem">Loyalty </Heading3>
        <Text color="gray-600" mb="1.5rem" mr="3.25rem" size="small">
            Loyalty  can be mapped to an account in {softwareName}.
        </Text>
        <Flex flexDirection={"column"}>
            <Box width="100%">
                <Flex>
                    <Flex flexDirection={"column"} width={"49%"} pr="1.1rem">
                        <Text fontWeight={"700"}>Liability</Text>
                        <Text color="gray-600" mb="1.5rem" size="small">{fullName} will increase this liability account when Loyalty is manually issued or with a refund. {fullName} will decrease this account when Loyalty is used to make a purchase.</Text>
                    </Flex>
                    <div style={{ width: "49%" }}>
                        <LabelRequired />
                        <Autocomplete
                            isLoadingOptions={isLoading}
                            id="loyalityAccount"
                            name="loyalityAccount"
                            options={accountsList}
                            placeholder={
                                t(isInputsOnlyLayout ? 'Enter Loyalty  account' : 'Select an account')
                            }
                        />
                    </div>
                </Flex>
            </Box>
            <Box width="100%">
                <Flex>
                    <Flex flexDirection={"column"} width={"49%"} pr="1.1rem" mt=".8rem">
                        <Text fontWeight={"700"}>Expense</Text>
                        <Text color="gray-600" mb="1.5rem" size="small">{fullName} will increase this expense account when Loyalty is manually issued.</Text>
                    </Flex>
                    <div style={{ width: "49%" }}>
                        <LabelRequired />
                        <Autocomplete
                            isLoadingOptions={isLoading}
                            id="loyaltyExpenseAccount"
                            name="loyaltyExpenseAccount"
                            options={expenseAccountList}
                            placeholder={
                                t(isInputsOnlyLayout ? 'Enter Expense Loyalty  account' : 'Select an account')
                            }
                        />
                    </div>
                </Flex>
            </Box>
        </Flex>
    </Box>;
};
