import { TextLink } from '@lightspeed/flame/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@lightspeed/flame/Core';
import { Fragment } from 'react';

export const LearnMore = ({ link, isInline }) => {
  const { t } = useTranslation();
  const finalLink = (link || 'https://resto-support.lightspeedhq.com/hc/en-us/articles/1260804626929')

  const handleClick = () => {
    window.open(finalLink, '_blank');
  };

  const WrapperComponent = isInline ? Fragment : Box;

  return <>
      <WrapperComponent>
        <TextLink size="small" fontWeight="bold" onClick={handleClick}>
          {t('Learn more')}
        </TextLink>
      </WrapperComponent>
  </>;
};
