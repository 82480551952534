import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'react-fast-compare';

const getInitialMetaObject = () => {
  return Object.assign({}, {
    isChangedPostingMethod: false,
    lastSave: {
      source: 'fetch',
      isChangedPostingMethod: false,
      entities: {},
    }
  });
};

const checkDashboardSwitchChangedOnly = (savedValues, newValues) => {

  const checkFlipped = (fieldName) => {
    const savedValuesWithClassFlipped = Object.assign({}, savedValues, {
      [fieldName]: newValues[fieldName],
    });

    return isEqual(savedValuesWithClassFlipped, newValues);
  }

  return [
    'isMapDiscounts',
    'isMapClass',
    'isMapOverShort',
    'isSentPettyCashPayouts',
    'isMapECom',
    'isMapCogs',
    'isMapKeepInvoice',
    'isMapLayAway'
  ].some(checkFlipped);
};

const initialState = {
  entities: {},
  meta: getInitialMetaObject(),
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const { name, data } = action.payload;
      const prevData = state.entities[name];
      let canUpdate = !prevData;

      if (!canUpdate) {
        const isRehydrate = prevData.dirty && (!data.dirty);
        const valuesChanged = !isEqual(state.entities[name].values, data.values);
        canUpdate = valuesChanged && (!isRehydrate);

        /**
         * Mapping switch can go back for any reasons
         * If this happens as the first thing in the series of changes
         * we need to handle it separately
         */
        if (valuesChanged && isRehydrate && name === 'dashboard') {
          const switchReversed = (name === 'dashboard') && checkDashboardSwitchChangedOnly(
            state.entities[name].values,
            data.values
          );
          canUpdate = switchReversed;
        }
      }

      if (canUpdate) {
        state.entities[name] = data;
      }
    },
    // Used on submit
    setFormsClean: (state) => {
      /**
       * Save latest config. This avoids re-fetching of data
       * Do this before modifying state
       */
      state.meta.lastSave.source = 'save';
      state.meta.lastSave.entities = Object.assign({}, state.entities);
      state.meta.lastSave.isChangedPostingMethod = state.meta.isChangedPostingMethod;

      // Mark all clean
      Object.keys(state.entities).forEach(formName => {
        if (state.entities[formName].dirty) {
          state.entities[formName].dirty = false;
        }
        if (['categoriesMapping', 'poCategoriesMapping'].includes(formName) && state.entities[formName].values) {
          state.entities[formName].values.hasMappingLevelChanged = false;
        }
      });

      // Reset method change flag
      state.meta.isChangedPostingMethod = false;
    },
    setConfigSourceFetch: (state) => {
      state.meta.lastSave.source = 'fetch';
    },
    setPostingMethodChanged: (state, action) => {
      const dashboardForm = state.entities.dashboard;
      const formikState = action.payload;

      let entities = {};

      if (dashboardForm) {
        entities.dashboard = {
          isValid: true,
          dirty: false, // This ensures isValid and dirty will be updated in setFormData
          values: formikState.values,
        };
      }

      return {
        entities,
        meta: { ...state.meta, isChangedPostingMethod: true },
      };

    },
    // Used on disconnect
    forgetSavedForms: (state, action) => {
      const result = {
        entities: {},
        meta: getInitialMetaObject(),
      };

      if (action.payload) {
        const { name, values, options } = action.payload;

        if (options && options.preserveLastSave) {
          result.meta.lastSave = state.meta.lastSave;
        }

        result.entities = {
          [name]: {
            dirty: false,
            isValid: false,
            values,
          }
        }
      }

      return result
    },
  }
});

export const {
  setFormData,
  setFormsClean,
  forgetSavedForms,
  setPostingMethodChanged,
  setConfigSourceFetch,
} = formsSlice.actions;

export default formsSlice.reducer;
