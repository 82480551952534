import { Flex, Box } from '@lightspeed/flame/Core';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Button } from '@lightspeed/flame/Button';
import { Label } from '@lightspeed/flame/FormField';
import { Input } from '@lightspeed/flame/Input';
import { Card, CardHeader, CardSection, CardFooter } from '@lightspeed/flame/Card';
import { useDispatch, useSelector } from 'react-redux';
import { TextLink } from '@lightspeed/flame/Text';
import { moveToNextScreen } from '../../utils/helpers/commonHelpers';
import { setToken } from '../../slices/authSlice';
import { useLoginMutation } from '../../services/authService';
import { useNotification } from '../../utils/helpers/notifications';
import { useState } from 'react';
import Copyrights from '../../components/custom/copyright';
import { AuthDomainEdit } from '../components/authDomainEdit';
import { AuthForgotPwd } from '../components/authForgotPwd';
import amIVisible from '../../componentControl/registry';

export const TextInput = (props) => {
  const [field, meta] = useField(props);

  let status = {};

  if (meta.touched && meta.error) {
    status = { type: 'error', message: meta.error };
  }

  return (
    <Input
      status={status} {...field} {...props}
      value={field.value === undefined ? '' : field.value}
    />
  );
};

const createValidationSchema = () => {
  const schemaObject = {
    email: Yup.string()
      .email('Invalid email address')
      .required('Email  is required'),
    password: Yup.string()
      .required('Password is required'),
  };

  return Yup.object().shape(schemaObject);
};

const LoginForm = ({ loading, onClickForgotPwd }) => {

  return <Form>
    <Box mb={5}>
      <Label htmlFor="email">Email</Label>
      <TextInput id="email" name="email" placeholder="Enter email" />
    </Box>
    <Box mb={2}>
      <Label htmlFor="password">Password</Label>
      <TextInput id="password" name="password" placeholder="Enter password" type="password" />
    </Box>
    {/* {amIVisible("forgot Password") && <Box mb={2} mr={1}>
      <Flex justifyContent={"end"}>
        <TextLink style={{ fontSize: "13px", fontWeight: "500" }} onClick={onClickForgotPwd}>Forgot Password?</TextLink>
      </Flex>
    </Box>} */}
    <Button variant="primary" type="submit" fill={true}
      disabled={loading}
      loading={loading}
      width="100%"
      mb={2}
    >
      Login
    </Button>
  </Form>;
};

export const Login = (props) => {
  const dispatch = useDispatch();
  const [sendLoginData] = useLoginMutation()
  const { showNotification } = useNotification()

  const [isLoading, setLoading] = useState(false)

  const [showDomainUpdateModal, setModalUpdate] = useState()

  const [showforgotPwdModal, setForgotPwdModal] = useState(false)

  const isRefresh = useSelector(state => state?.auth?.isLogout);
  
  if (isRefresh) {
    window.location.reload();
  }

  const onSubmit = (values) => {
    // Send data to backend
    setLoading(true)
    sendLoginData({
      email: values.email,
      password: values.password
    })
      .unwrap()
      .then((res) => {
        dispatch(setToken(res.token))
        moveToNextScreen(props.navigate, dispatch, '../dashboard', res);
      })
      .catch(({ data: res }) => {
        if (res?.error || res?.message) {
          showNotification(res?.error || res?.message, "error")
        } else if (res?.oauthUrl) {
          setModalUpdate(res)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const moveToSignup = () => {
    props.navigate('../signup');
  };

  return (
    <>
      <Flex justifyContent="center">
        <Box width={450} marginTop={50}>
          {showDomainUpdateModal && <AuthDomainEdit response={showDomainUpdateModal} onClose={() => setModalUpdate()} />}
          {showforgotPwdModal && <AuthForgotPwd onClose={() => setForgotPwdModal(false)} />}
          <Card>
            <CardHeader title="Login" />
            <CardSection>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={createValidationSchema()}
                onSubmit={onSubmit}
              >
                {formik =>
                  <LoginForm formik={formik} loading={isLoading} onClickForgotPwd={() => setForgotPwdModal(true)} {...props} />
                }
              </Formik>
            </CardSection>
            <CardFooter>
              <Flex justifyContent="center">
                New User? <TextLink onClick={() => { moveToSignup() }} ml={1}>Signup</TextLink>
              </Flex>
            </CardFooter>
          </Card>
        </Box>
      </Flex>
      <Copyrights isFullPage />
    </>
  );
};
