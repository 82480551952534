import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { Autocomplete, TextInput } from '../fields';
import { useToasts } from '@lightspeed/flame/Toaster';
import { LabelRequired } from '../custom/LabelRequired';
import { useTranslation } from 'react-i18next';
import useAppSubmitting from '../../hooks/useAppSubmitting';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentComponent } from '../../slices/dashboardSlice';
import { useNotification } from '../../utils/helpers/notifications';
import { Alert } from 'antd';

export const MapPaymentMethods = ({ fetchedConfig, formik, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const [, softwareName] = useConnectionMode();
  const [isLoadingAccounts, accountsList] = useAccounts('payment');
  const { addToast } = useToasts();
  const { defaultPaymentAccountName: defaultAccount } = formik.values;
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;

  const dispatch = useDispatch()

  const { showNotification } = useNotification()

  const { hasPaymentError } = useSelector(state => state.dashboard)

  const gotoPaymentMapping = () => {
    if (formik.dirty) {
      showNotification("Save the current changes to proceed", "error")
    } else {
      dispatch(setCurrentComponent("payment mapping"))
    }
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultAccount) {
      gotoPaymentMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'SelectPaymentFirst'), {
        appearance: 'error'
      });
    }
  };

  return <Box padding="0.75rem">
    <Heading3 mb="0.375rem">{t('Payment types')}</Heading3>
    <Text color="gray-600" mb="1.5rem" size="small">
      {t('DefaultPaymentDesc', { swName: softwareName })}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultPaymentAccountName">
          {t('Default payment account')}
        </LabelRequired>
        <Autocomplete
          id="defaultPaymentAccountName"
          name="defaultPaymentAccountName"
          isLoading={isLoading}
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter payments account' : 'Select an account')
          }
        />
      </Box>
      {amIVisible("payment individual") && <Flex mt="1.4rem" alignItems="center" height="2.25rem">
        {isLoading ?
          <Bone height="1rem" width="12rem" /> :
          <TextLink size="small" fontWeight="bold"
            onClick={handleNavigation}
          >
            {t('Map individual payment types')}
          </TextLink>
        }
      </Flex>}
    </Flex>
    {hasPaymentError && <Alert
      message="Gift Card mapping is required."
      type="error"
      showIcon
      style={{ width: "250px", marginTop: "1.5rem" }}
    />}
  </Box>;
};