import { CheckboxField } from '../fields';
import { Box } from '@lightspeed/flame/Core';
import { CheckboxLabel } from '@lightspeed/flame/Checkbox';
import { useSelector } from 'react-redux';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { useTranslation } from 'react-i18next';

export const MappingOptions = ({ isSentPurchaseOrder, trackInventory, isSentCogs }) => {
  const disableInventory = isSentCogs || (!isSentPurchaseOrder);
  const hasSubmittedWithErrors = useSelector(state => state.dashboard.hasError);
  const showError = !(isSentPurchaseOrder || isSentCogs) && hasSubmittedWithErrors;
  const { t } = useTranslation();
  const isJeTrue = useSelector(state => state?.forms?.entities?.dashboard?.values?.postingMethod === 'journalEntry')

  const { fullName } = useSelector(state => state.auth)

  return <Box>
    <CheckboxField
      id="isSentPurchaseOrder"
      name="isSentPurchaseOrder"
      label={`For each purchase order in ${fullName}, create a Purchase Invoice`}
    />
    <Box mt="1.125rem" mb="1.125rem">
      {!isJeTrue && <CheckboxLabel color={disableInventory ? 'gray-500' : 'black'}>
        <CheckboxField
          id="trackInventory"
          name="trackInventory"
          disabled={disableInventory}
        />
        Track Inventory
      </CheckboxLabel>}
    </Box>
    <CheckboxField
      id="isSentCogs"
      name="isSentCogs"
      label="Track Cost of Goods Sold"
    />{showError &&
      <Box width="49%" pr="1.1rem" pt="1.5rem">
        <AlertInCard
          type="danger"
          width="auto"
          noCloseBtn
        >
          {t('Select an option to map your accounts')}
        </AlertInCard>
      </Box>
    }
  </Box>;
};
