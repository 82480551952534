import { Modal, ModalHeader, ModalBody } from '@lightspeed/flame/Modal';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Button } from '@lightspeed/flame/Button';
import { Text } from '@lightspeed/flame/Text';
import { Label } from '@lightspeed/flame/FormField';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { TextInput } from '../authorizeLogin/login';
import { useNotification } from '../../utils/helpers/notifications';
import { useResetPasswordMutation } from '../../services/authService';
import { useState } from 'react';
import Lottie from "react-lottie";
import anim from "../../images/emailSent.json"
import { isEmpty, some } from 'lodash';

export const AuthForgotPwd = ({ onClose }) => {
    const { showNotification } = useNotification()

    const [resetPassword, { isLoading: loading }] = useResetPasswordMutation()

    const [showGreet, setGreetMsg] = useState()

    const createValidationSchema = () => {
        const schemaObject = {
            email: Yup.string()
                .email('Invalid email address')
                .required('Email  is required'),
        };

        return Yup.object().shape(schemaObject);
    };

    const triggerApi = ({ email }) => {
        resetPassword({
            email
        })
            .unwrap()
            .then((res) => {
                if (res?.message) {
                    setGreetMsg(true)
                    showNotification(res?.message, "success")
                }
            })
            .catch((err) => {
                if (err?.data?.message) {
                    showNotification(err?.data?.message, "error")
                }
                setGreetMsg(false)
            })
    }

    const showInput = () => {
        return (
            <Flex flexDirection={"column"}>
                {!showGreet && (<Text fontWeight={"500"} mb=".4rem">Please enter the registered email address : </Text>)}
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={createValidationSchema()}
                    onSubmit={triggerApi}
                >
                    {(formik) =>
                    (
                        <Form>

                            {showGreet ? showGreetMsg() :
                                <>
                                    <Box mb={2} mt={2}>
                                        <Label htmlFor="email">Registered Email</Label>
                                        <TextInput id="email" name="email" placeholder="Enter email address" />
                                    </Box>
                                </>
                            }
                            <Button variant="primary" type="submit" fill={true}
                                disabled={(!isEmpty(formik.errors) || some(formik.values, isEmpty))}
                                loading={loading}
                                width="100%"
                                mt={2}
                            >
                                 {showGreet ? "Resend Email" : "Send Reset Link"}
                            </Button>
                        </Form>
                    )
                    }
                </Formik>
            </Flex>
        )
    }

    const showGreetMsg = () => {
        return (
            <>
                <Flex justifyContent={"center"} flexDirection={"column"}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: anim,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        className="lottie"
                        height={80}
                        width={250}
                        background="transparent"
                        isClickToPauseDisabled
                    />
                    <Text textAlign={"center"} fontWeight={"600"} mt="1.5rem" mb=".4rem">Please check your email for the reset link, if you didn't find it on inbox, please check the spam.</Text>
                </Flex>
            </>
        )
    }

    return (
        <Modal isOpen shouldCloseOnOverlayClick={false} onRequestClose={onClose} style={{ width: "400px" }}>
            <ModalHeader>
                Reset Password
            </ModalHeader>
            <ModalBody>
                <Box width={"400px"}>
                    {showInput()}
                </Box>
            </ModalBody>
        </Modal>
    )
}