import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { TextLink } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { useTranslation } from 'react-i18next';
import { Divider } from '@lightspeed/flame/Divider';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { Box } from '@lightspeed/flame/Core';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import { MappingOptions } from './MappingOptions';
import { ShippingSection } from './ShippingSection';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import useConnectionMode from '../../hooks/useConnectionMode';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { PoTaxMapping } from '../mapTaxCodes/PoTaxMapping';
import { MapDefaultIndividualCogsInventory } from './MapDefaultIndividualCogsInventory';

export const InventoryCogsSwitch = ({ formik, ...props }) => {
  const {
    POInventoryCogs,
    isSentPurchaseOrder,
    trackInventory,
    isSentCogs,
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  // Auto disable track inventory
  useEffect(() => {
    if ((isSentCogs && trackInventory) || (!isSentPurchaseOrder && trackInventory)) {
      formik.setFieldValue('trackInventory', false);
    }
  }, [formik, isSentCogs, trackInventory, isSentPurchaseOrder]);

  const description = `${t('PO, inventory and COGS desc.0', { swName })} ${(POInventoryCogs) ?
    t('PO, inventory and COGS desc.1') : ''}`;

  return <SwitchLayout
    heading={t('PO, Inventory and COGS')}
    description={
      description
    }
    switchFieldName="POInventoryCogs"
    isSwitchOn={POInventoryCogs}
  >
    <Box>
        <MappingOptions
          isSentPurchaseOrder={isSentPurchaseOrder}
          trackInventory={trackInventory}
          isSentCogs={isSentCogs}
        />
    </Box>
  </SwitchLayout>;
};

const CogsInventorySectionContent = (props) => {
  const {
    isSentPurchaseOrder,
    isSentCogs,
    POInventoryCogs,
  } = props.formik.values;

  return <>
    <Card ml="1rem">
      <CardSection>
        <InventoryCogsSwitch {...props} />
      </CardSection>
      {isSentPurchaseOrder && POInventoryCogs &&
        <>
          <Divider />
          <CardSection pt="1.5rem" pb="1.5rem">
            <ShippingSection />
            <Box ml="0.75rem" mr="0.75rem" mt="1.5rem">
              {amIVisible("po taxes") && <PoTaxMapping {...props} />}
            </Box>
          </CardSection>
        </>
      }
      {(isSentPurchaseOrder || isSentCogs) && POInventoryCogs &&
        <>
          <Divider />
          <CardSection pt="1.5rem" pb="1.5rem" pl="1.5rem" pr="1.5rem">
            <MapDefaultIndividualCogsInventory {...props} />
          </CardSection>
        </>
      }
    </Card>
  </>;
};

export const CogsInventorySection = (props) => {
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <AnnotatedLayout
    title={t('Map Purchase Orders, COGS and Inventory')}
    description={t('MapAccountsDescription', { target: getCurrentTarget().fullName })}
    renderExtras={
      <>
        {false && <Box>
          <TextLink size="small" fontWeight="bold">
            {t('Learn more')}
          </TextLink>
        </Box>}
        {(isInputsOnlyLayout === false) && <RefreshAccounts />}
      </>
    }
    mb="2.25rem"
    mt="2.25rem"
  >
    <CogsInventorySectionContent {...props} />
  </AnnotatedLayout>;
};