import { Heading1, Text, TextLink } from '@lightspeed/flame/Text';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    /* TODO: translation here for Error on authorize! */
    const { t } = useTranslation();

    const retry = (clickEvent) => {
        clickEvent.preventDefault();
        props.retry();
    }

    return (
        <>
            <Heading1>Error</Heading1>
            <Text>
                An error occurred. Please click 
                <TextLink onClick={retry}>
                   {`  here  `}
                </TextLink>
                 to try again
            </Text>
        </>
    );
};