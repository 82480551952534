import { useState, useEffect } from 'react';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { PageLayout } from '../pageLayout/PageLayout';
import { useSelector } from 'react-redux';
import { useToasts } from '@lightspeed/flame/Toaster';
import { FieldArray, Formik, Form } from 'formik';
import { Autocomplete, RadioField } from '../fields';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { Label } from '@lightspeed/flame/FormField';
import { IconAdd } from '@lightspeed/flame/Icon/Add';
import { IconRemove } from '@lightspeed/flame/Icon/Remove';
import { Button } from '@lightspeed/flame/Button';
import {
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
} from '@lightspeed/cirrus-table';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { LearnMore } from '../custom/LearnMore';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { Divider } from '@lightspeed/flame/Divider';
import * as Yup from 'yup';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { usePersist } from '../../hooks/usePersist';

const RemoveRow = ({ children, handleRemoveRow }) => {
  return <Box display="grid" style={{ gridTemplateColumns: 'auto 2.125rem', alignItems: 'center' }}>
    {children}
    <IconRemove
      color="danger"
      pointer="cursor"
      style={{ marginLeft: '0.75rem', pointer: 'cursor' }}
      onClick={handleRemoveRow}
    />
  </Box>;
};

const DropdownInput = ({ name, options, config, onCreateOption, selectiveMapping, handleRemoveRow, isLastColumn }) => {
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoadingOptions = !(options && options.length > 0);
  const isLoading = isInputsOnlyLayout ? false : isLoadingOptions;
  const [isInput] = useIsInputsOnlyLayout()

  const inputContent = <Autocomplete
    name={name}
    isLoading={isLoading}
    options={options}
    placeholder={isInput ? "Enter an Account" : config.placeholder}
    onCreateOption={onCreateOption}
  />;

  return <>
    {isLoading ?
      <Bone height="2em" /> :
      <>
        {selectiveMapping && selectiveMapping.enabled && isLastColumn ?
          <RemoveRow {...selectiveMapping} handleRemoveRow={handleRemoveRow}>
            {inputContent}
          </RemoveRow> :
          <>
            {inputContent}
          </>
        }
      </>
    }
  </>;
};

const TableCell = ({ data, config, rowData, ...props }) => {
  const type = config.type || 'label';
  let onCreateOption;

  if (config.onCreateOption) {
    onCreateOption = (userInput) => {
      config.onCreateOption(userInput, rowData);
    }
  }

  return <>
    {
      type === 'label' ?
        <>{data}</> :
        <DropdownInput
          config={config}
          onCreateOption={onCreateOption}
          {...props}
        />
    }
  </>;
};

const MappingRow = (props) => {
  const { data, type, config, name, rowIndex, selectiveMapping, handleRemoveRow } = props;
  const isLoading = !data;
  const numColumns = config.length;
  const columnWidth = 100 / numColumns;

  const getKey = (index, columnName) => {
    return `${name}.${index}.${columnName}`;
  };

  const removeRow = () => {
    handleRemoveRow(props.arrayHelpers, data, rowIndex);
  };

  const content = config.map((columnData, columnIndex) => {
    const { name: columnName, optionsGetter } = columnData;
    const cellName = getKey(rowIndex, columnName);

    let options = columnData.options;

    if (optionsGetter) {
      options = optionsGetter(data);
    }

    return <>
      {type === 'header' ?
        <Th key={columnIndex} width={`${columnWidth}%`} maxWidth={`${columnWidth}%`}>
          {config[columnIndex].title}
        </Th> :
        <Td key={columnIndex} width={`${columnWidth}%`} maxWidth={`${columnWidth}%`}>
          {isLoading ?
            <Bone height="2rem" /> :
            <TableCell
              isLastColumn={columnIndex === (numColumns - 1)}
              config={columnData}
              data={data[columnName]}
              rowData={data}
              options={options}
              name={cellName}
              selectiveMapping={selectiveMapping}
              handleRemoveRow={removeRow}
            />
          }
        </Td>
      }
    </>;
  });

  return <Tr>{content}</Tr>;
};

const TableData = ({ config, name, rowsData, ...props }) => {
  const { visibleRowIds, selectiveMapping } = props;
  let isLoaded = Boolean(rowsData);
  let rowsVisible = isLoaded && rowsData.length !== 0;
  const { t } = useTranslation();

  if (selectiveMapping && selectiveMapping.enabled) {
    isLoaded = isLoaded && visibleRowIds;
    rowsVisible = isLoaded && rowsData.some(data => visibleRowIds.includes(data.rowId));
  }

  const getVisibleRows = () => {
    // Render rows in order
    return visibleRowIds.map((rowId, keyIndex) => {
      const rowIndex = rowsData.findIndex(data => data.rowId === rowId);
      const rowData = rowsData[rowIndex];
      return <MappingRow
        data={rowData}
        key={keyIndex}
        rowIndex={rowIndex}
        name={name}
        config={config}
        {...props}
      />;
    });
  };

  return <>{
    isLoaded ?
      <>{
        rowsVisible ?
          <>{
            selectiveMapping && selectiveMapping.enabled ?
              <>{getVisibleRows()}</>
              :
              <>{
                rowsData.map((rowData, index) => {
                  return <MappingRow
                    data={rowData}
                    key={index}
                    rowIndex={index}
                    name={name}
                    config={config}
                    {...props}
                  />;
                })
              }</>
          }</>
          :
          <Tr>
            <Td colSpan={config.length}>
              <Flex alignItems="center" justifyContent="center">
                <Text>{t('No rows to show')}</Text>
              </Flex>
            </Td>
          </Tr>
      }</>
      :
      <>{
        [1, 2, 3].map((index) => {
          return <MappingRow config={config} key={index} />;
        })
      }</>
  }</>;
};

const ConfirmLevelChange = ({ confirmMethods, label }) => {
  const { resolve, reject } = confirmMethods;
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={reject}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '600px', width: '600px' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('ConfirmCatLevelChange.0')}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('ConfirmCatLevelChange.1')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={reject} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={resolve}>
          {t('Change')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const LevelSwitchButton = ({ label, value, field, isLoading, handleMappingLevelChange }) => {
  const [confirmMethods, setConfirmMethods] = useState();

  const confirmChange = ({ event, meta }) => {
    /**
     * For react v17, e.persist() will not be needed as the SyntheticEvent is no longer pooled
     */
    event.persist();
    return new Promise((resolve, reject) => {

      const handleChange = () => {
        handleMappingLevelChange({ [field]: event.target.value, hasMappingLevelChanged: true });
        resolve();
      };

      if (meta.value === '') {
        resolve();
      } else {
        setConfirmMethods({ resolve: handleChange, reject });
      }
    }).finally(() => { setConfirmMethods(null) });
  };

  return <>
    {confirmMethods &&
      <ConfirmLevelChange
        label={label}
        confirmMethods={confirmMethods}
      />
    }
    <Box mr="2.625rem">
      <RadioField
        name={field}
        confirmChange={confirmChange}
        descriptionProps={{ color: 'gray-600' }}
        type="radio"
        label={<Box mb="0.375rem">{label}</Box>}
        value={value}
        id={value + "_button"}
        disabled={isLoading}
      />
    </Box>
  </>;
};

const LevelSwitchButtons = ({ buttons, field, ...props }) => {
  return <Flex mt="1.5rem" mb="1.125rem">
    {buttons.map(buttonData => {
      return <LevelSwitchButton
        {...buttonData}
        field={field}
        {...props}
      />;
    })}
  </Flex>
};

const AddRow = ({
  heading,
  description,
  placeholder,
  handleSelection,
  optionsList,
  radioButtons,
  handleMappingLevelChange,
  allRowsData,
}) => {
  const isLoading = !allRowsData;
  const [selection, setSelection] = useState('');
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleAddRow = () => {
    if (!selection) {
      addToast(t('Please select an option first'), { appearance: 'error' });
    } else {
      handleSelection(selection);
      setSelection('');
    }
  };

  // Clear selection on loading start
  useEffect(() => {
    if (isLoading) {
      setSelection('');
    }
  }, [isLoading]);

  return <Card mb="1.125rem">
    <CardSection pl={0} pr={0} pt="1.5rem" pb="1.5rem">
      <Box pl="1.5rem" pr="1.5rem">
        <Label
          description={description}
          descriptionProps={{ marginTop: '0.375rem', marginBottom: '0.75rem' }}
          htmlFor="selectedOption"
        >{heading}</Label>
        {radioButtons && radioButtons.isShown &&
          <LevelSwitchButtons
            {...radioButtons}
            isLoading={isLoading}
            handleMappingLevelChange={handleMappingLevelChange}
          />
        }
      </Box>
      {radioButtons && radioButtons.isShown &&
        <Divider mb="1.5rem" />
      }
      <Flex pl="1.5rem" pr="1.5rem">
        <Box minWidth="20rem">
          <Autocomplete
            id="selectedOption"
            name="selectedOption"
            isLoading={isLoading}
            dropdown
            options={optionsList}
            placeholder={placeholder}
            value={selection}
            onChange={setSelection}
          />
        </Box>
        <Button
          variant="secondary" fill={true} ml="0.75rem" pl="0.625rem" pt="0.625rem" pb="0.625rem"
          onClick={handleAddRow}
        >
          <IconAdd />
          <Text>{t('Add')}</Text>
        </Button>
      </Flex>
    </CardSection>
  </Card>
};

const MappingTable = ({ rowsData, ...props }) => {
  const { config, name } = props;
  return <Card>
    <Table style={{ tableLayout: 'fixed', width: '100%' }}>
      <Thead>
        <MappingRow config={config} type="header" />
      </Thead>
      <Tbody>
        <FieldArray name={name}>
          {
            arrayHelpers =>
              <TableData
                {...props}
                rowsData={rowsData}
                arrayHelpers={arrayHelpers}
              />
          }
        </FieldArray>
      </Tbody>
    </Table>
  </Card>;
};

const MapGenericContent = ({
  persistFormName,
  onSuccessfulSaveSubject,
  setInitialValues,
  restartDataFetch,
  mappingType,
  emit,
  cancelEmit,
  ...props
}) => {
  const { name, formik, selectiveMapping } = props;
  const allRowsData = formik.values[name];
  const [visibleRowIds, setVisibleRowIds] = useState();
  const [optionsList, setOptionsList] = useState();
  usePersist(props.formik, persistFormName);

  useEffect(() => {
    if (emit) {
      const newInitialValues = Object.assign({}, formik.values);
      if (newInitialValues && newInitialValues.hasOwnProperty('hasMappingLevelChanged')) {
        newInitialValues.hasMappingLevelChanged = false;
      }
      setInitialValues(newInitialValues);
      cancelEmit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emit])

  // Update initialValues to the current values
  // useEffect(() => {
  //   if (updateInitialValuesPending) {
  //     setUpdateInitialValuesPending(false);
  //     const newInitialValues = Object.assign({}, formik.values);
  //     if (newInitialValues && newInitialValues.hasOwnProperty('hasMappingLevelChanged')) {
  //       newInitialValues.hasMappingLevelChanged = false;
  //     }
  //     setInitialValues(newInitialValues);
  //   }
  // }, [formik.values, setInitialValues, updateInitialValuesPending]);

  // // Register subscriptions. This should be run only once to avoid re-subscribing which will throw error
  // useEffect(() => {
  //   onSuccessfulSaveSubject.subscribe(() => {
  //     setUpdateInitialValuesPending(true);
  //   });
  
  //   return () => {
  //     onSuccessfulSaveSubject.unsubscribe();
  //   }
  // }, [onSuccessfulSaveSubject]);

  // Filter out rows at the beginning
  useEffect(() => {
    // Run once when the data loads
    if (allRowsData) {
      if (!visibleRowIds) {
        // Populate options if enabled
        if (selectiveMapping && selectiveMapping.enabled) {
          const { hasMapping, rowToOption } = selectiveMapping;
          const visibility = [];
          const options = [];
          allRowsData.forEach(data => {
            if (hasMapping(data)) {
              visibility.push(data.rowId);
            } else {
              options.push(rowToOption(data));
            }
          });
          setVisibleRowIds(visibility);
          setOptionsList(options);
        }
      }
    } else {
      setVisibleRowIds(null);
      setOptionsList(null);
    }
  }, [allRowsData, selectiveMapping, visibleRowIds]);

  const handleAddRow = (selection) => {
    // Remove option
    const newOptions = optionsList.filter(data => data.value !== selection.value);
    setOptionsList(newOptions);
    // Show row
    setVisibleRowIds([selection.rowId, ...visibleRowIds]);
  };

  const handleRemoveRow = (arrayHelpers, rowData, rowIndex) => {
    const { rowToOption, removeMapping } = selectiveMapping;
    // Hide row
    setVisibleRowIds(visibleRowIds.filter(rowId => rowId !== rowData.rowId));
    // Add to option list
    const newOption = rowToOption(rowData);
    const newOptions = [...optionsList, newOption];
    setOptionsList(newOptions);
    // Remove mapping
    const cleanedRow = removeMapping(rowData);
    arrayHelpers.replace(rowIndex, cleanedRow);
  };

  const handleFetchParamsChange = (newFieldMapping) => {
    setInitialValues(Object.assign({}, formik.values, { [name]: null, ...newFieldMapping }));
    restartDataFetch(newFieldMapping);
  };

  // Re-fetch data when mapping type changes
  useEffect(() => {
    if (formik?.values?.mappingType !== mappingType) {
      const newValues = { mappingType };
      // Handle radio buttons re-appearing
      if ((mappingType === 'category') && selectiveMapping && selectiveMapping.enabled) {
        const radioButtons = selectiveMapping.radioButtons;
        // Assume radio buttons are guaranteed to show after mappingType update
        if (radioButtons) {
          newValues[radioButtons.field] = radioButtons.initialValue;
        }
      }

      handleFetchParamsChange(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.mappingType, mappingType]);

  return <Form>
    {selectiveMapping && selectiveMapping.enabled &&
      <AddRow
        {...selectiveMapping}
        optionsList={optionsList}
        handleSelection={handleAddRow}
        allRowsData={allRowsData}
        handleMappingLevelChange={handleFetchParamsChange}
      />
    }
    <MappingTable
      visibleRowIds={visibleRowIds}
      rowsData={allRowsData}
      handleRemoveRow={handleRemoveRow}
      {...props}
    />
  </Form>;
};

const getInitialFetchParams = (selectiveMapping, mappingType) => {
  const fetchParams = {};

  if (selectiveMapping && selectiveMapping.enabled) {
    const radioButtons = selectiveMapping.radioButtons;
    if (radioButtons && radioButtons.isShown) {
      fetchParams[radioButtons.field] = radioButtons.initialValue || radioButtons[0].value;
      fetchParams.hasMappingLevelChanged = false;
    }
  }

  if (mappingType) {
    fetchParams.mappingType = mappingType;
  }

  return fetchParams;
};

const getInitialState = (formFieldName, startingState, fetchParams) => {
  const initialState = {
    [formFieldName]: startingState || null,
    ...fetchParams,
  };

  return initialState;
};

export const MapGeneric = ({
  config,
  validationSchema,
  startingState,
  fetchRows,
  errorMessage,
  pageTitle,
  pageDescription,
  title,
  description,
  showBack,
  mappingSwitch,
  ...props
}) => {
  const { name: formFieldName, persistFormName, selectiveMapping } = props;
  const savedForm = useSelector(state => state.forms.entities[persistFormName]);
  const [initialSavedForm] = useState(savedForm);
  const { addToast } = useToasts();
  const [mappingType, setMappingType] = useState(mappingSwitch && mappingSwitch?.isEnabled && mappingSwitch?.initialValue);
  // Meta fields are used directly as fetch params
  const [fetchParameters, setFetchParameters] = useState(getInitialFetchParams(selectiveMapping, mappingType));
  const [initialValues, setInitialValues] = useState(getInitialState(
    formFieldName,
    startingState,
    fetchParameters
  ));
  const [onSuccessfulSaveSubject] = useState(new Subject());
  const hasChanges = savedForm && savedForm.dirty;
  const hasMappingLevelChanged = savedForm && savedForm?.values?.hasMappingLevelChanged;
  const showSwitchMapping = Boolean(mappingType) && !(hasChanges || hasMappingLevelChanged);
  const disableSwitchMapping = !initialValues || !Boolean(initialValues[formFieldName]);
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const [emit, setEmitter] = useState(false);

  const isDirty = useSelector((state) => state.globals.isConfigReFetched)

  const handleError = () => {
    if (errorMessage) {
      const msg = errorMessage;
      addToast(msg, { appearance: 'error' });
    }
  };

  // Fetch rows
  useEffect(() => {
    let mounted = true;
    fetchRows(fetchParameters).then(res => {
      if (mounted) {
        if (res) {
          if (initialSavedForm && (!isDirty || initialSavedForm.dirty) && !initialValues.hasMappingLevelChanged) {
            let data = initialSavedForm?.values
            // This should run only at first mount
            if (persistFormName === "paymentsMapping") {
              if (res.length !== data?.paymentsMapping) {
                data = {
                  paymentsMapping: res?.map((item) => {
                    for (let i = 0; i < data?.paymentsMapping?.length; i++) {
                      if (data?.paymentsMapping[i].payment === item.id) {
                        return data?.paymentsMapping[i]
                      }
                    }
                    return item
                  })
                }
              }
            }
            setInitialValues(data);
          } else {
            const fieldName = res.isTypeRowDataObject ? res.formName : formFieldName;
            const rowsList = res.isTypeRowDataObject ? res.rowsList : res;
            setInitialValues(Object.assign({}, initialValues, { [fieldName]: rowsList }));
          }
        } else {
          handleError();
        }
      }
    }).catch(() => {
      if (mounted) {
        handleError();
      }
    });

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParameters]);

  const handleSuccessfulSave = () => {
    // Signal to update initialValues
    // onSuccessfulSaveSubject.next();
    setEmitter(true);
  };

  const restartDataFetch = (newMapping) => {
    setFetchParameters(Object.assign({}, {
      ...fetchParameters,
      hasMappingLevelChanged: false,
    }, newMapping));
  };

  const getValidationSchema = () => {
    return Yup.object().shape({
      purchaseTaxMapping: Yup.array()
        .of(
          Yup.object().shape({
            account: Yup.string().nullable(true).required('Required'),
          })
        )
    });
  };

  return <PageLayout
    title={pageTitle}
    description={pageDescription}
    showBack={showBack || true}
    navigate={props.navigate}
    handleSuccessfulSave={handleSuccessfulSave}
    disableBack={showSwitchMapping && disableSwitchMapping}
    switchMapping={{
      isShown: showSwitchMapping,
      value: mappingType,
      setMappingType: setMappingType,
      isDisabled: disableSwitchMapping,
    }}
  >
    <AnnotatedLayout
      title={title}
      description={description}
      renderExtras={
        <>
          <LearnMore />
          {(isInputsOnlyLayout === false) && <RefreshAccounts />}
        </>
      }
      mb="2.25rem"
    >
      <Box ml="1rem">
        {initialValues[persistFormName] ? <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={getValidationSchema}
        >
          {formik =>
            <MapGenericContent
              formik={formik}
              config={config}
              onSuccessfulSaveSubject={onSuccessfulSaveSubject}
              setInitialValues={setInitialValues}
              restartDataFetch={restartDataFetch}
              mappingType={mappingType}
              emit={emit}
              cancelEmit={() => {
                setEmitter(false)
              }}
              {...props}
            />
          }
        </Formik>
        : <Bone height={"3"} />}
      </Box>
    </AnnotatedLayout>
  </PageLayout>;
};
