import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { setFormData } from '../redux/reducer/formsReducer';

const saveForm = debounce((formikProps, name, dispatch) => {
  dispatch(setFormData({
    name, data: {
      dirty: formikProps.dirty,
      isValid: formikProps.isValid,
      values: formikProps.values,
    }
  }));
}, 300);

export const usePersist = (formik, name) => {
  const dispatch = useDispatch();
  useEffect(() => {
    saveForm(formik, name, dispatch);
  }, [dispatch, formik, name]);
};
