import { useSelector } from 'react-redux';
import { MapGeneric } from '../mapGeneric/MapGeneric';
import { useTranslation } from 'react-i18next';
import useLocation from '../../hooks/useLocation';
import useAccounts from '../../hooks/useAccounts';
import useConnectionMode from '../../hooks/useConnectionMode';
import useMessage from '../../hooks/useMessage';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import { getDiscountsList } from '../../redux/api/advancedConfig';

export const IndividualDiscounts = (props) => {
  const { isChangedPostingMethod } = useSelector(state => state.forms.meta);
  const fetchedMapping = isChangedPostingMethod ? null : props.location.state.fetchedMapping;
  const location = useLocation();
  const [, accountsList] = useAccounts('discount');
  const { defaultDiscountAccountName: defaultAccountValue } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const placeholderAccount = defaultAccountValue && accountsList.find(account => (account.value === defaultAccountValue));
  const placeholder = placeholderAccount && placeholderAccount.label;
  const [, softwareName] = useConnectionMode();
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const { addMessage } = useMessage();

  const config = [
    {
      title: t('LsDiscounts', { target: target.fullName }),
      name: 'discount',
    },
    {
      title: t('SwAccounts', { swName: softwareName }),
      name: 'account',
      options: accountsList,
      type: 'dropdown',
      placeholder: placeholder
    }
  ];

  const handleError = (res) => {
    console.log("check 2")
    addMessage(res, { default: t('Fetch failure') });
  };

  const fetchRows = () => {
    return getDiscountsList(location.id).then(res => {
      let rowsList;
      if (res.data) {
        const resData = res.data;
        rowsList = resData.map(rowInfoRaw => {
          const formValues = {
            [config[0].name]: rowInfoRaw.name,
            [config[1].name]: (fetchedMapping && fetchedMapping[rowInfoRaw.id]) || '',
            id: rowInfoRaw.id,
          };

          return formValues;
        });

        return rowsList;
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  };

  return <>
    <MapGeneric
      pageTitle={t('Map discounts')}
      pageDescription={t('MapDiscountPageDesc', { swName: softwareName })}
      title={t('Discounts')}
      description={t('IndividualDiscountsDesc', { swName: softwareName })}
      config={config}
      fetchRows={fetchRows}
      name="discountsMapping"
      persistFormName="discountsMapping"
      {...props}
    />
  </>;
};
