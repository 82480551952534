import axios from "axios";
import { getCurrentTarget, getHeaders } from "../utils/helpers/commonHelpers";
import { getToken } from "../utils/helpers/sessionStorage";
import { getHistoricalSyncUrl, getPoOrderDetails, getPoResyncUrl, getPoSyncLogUrl, getReconnectAccountUrl, getRegisterSwUrl, getResyncUrl, getSalesOrderDetailsUrl, getSalesSyncDataUrl, getSubmitConfigUrl, getTransactionAnalysisUrl, getValidateSwConnectionUrl } from "../utils/urls/url";

export const getSalesSyncData = (location, flag, pbt, pot, s, sd, ed) => {
  return axios.get(flag ? getPoSyncLogUrl() : getSalesSyncDataUrl(), {
    headers: getHeaders(location),
    params: {
      pageBackupTable: pbt,
      pageOriginalTable: pot,
      s,
      sd,
      ed
    }
  });
};


export const getSalesOrderDetails = (orderNumber, location, flag) => {
  const options = {
    headers: getHeaders(location),
  };

  const target = getCurrentTarget()

  let url = flag ? getPoOrderDetails() : getSalesOrderDetailsUrl();

  if (target.isLs || target.isEposNow) {
    url = `${url}${orderNumber}`;
  } else {
    options.params = { orderNumber };
  }

  return axios.get(url, options);
};

export const repostOrder = (payload, locationId, flag) => {
  const url = flag ? getPoResyncUrl() : getResyncUrl();
  return axios.post(url, payload, {
    headers: getHeaders(locationId),
  });
};

export const startHistoricalSync = (payload, location) => {
  return axios.post(getHistoricalSyncUrl(), payload, {
    headers: getHeaders(location),
  });
};

export const getTransactionAnalysisLightspeed = (orderNumber, location) => {
  const url = `${getTransactionAnalysisUrl()}${orderNumber}`;
  return axios.get(url, {
    headers: getHeaders(location),
  });
};

export const getTransactionAnalysisRevel = (orderNumber) => {
  return axios.get(getTransactionAnalysisUrl(), {
    params: {
      orderNumber,
    },
    headers: {
      'Authorization': getToken(),
    },
  });
};

export const getTransactionAnalysis = (orderNumber, location) => {
  const target = getCurrentTarget();
  let result;

  if (target.isRevel) {
    result = getTransactionAnalysisRevel(orderNumber);
  } else {
    result = getTransactionAnalysisLightspeed(orderNumber, location);
  }

  return result;
};


export const copyAccountData = (payload, targetLocation) => {
  return axios.post(getRegisterSwUrl(), payload, {
    headers: getHeaders(targetLocation),
  });
};

export const sendConfigData = (payload, locationId, isUpdating = false) => {
  let method = axios.post;
  if (isUpdating) {
    method = axios.put;
  };

  return method(getSubmitConfigUrl(), payload, {
    headers: getHeaders(locationId),
  });
};

export const registerSw = (payload, locationId, isUpdating) => {
  let requestMethod = axios.post;

  if (isUpdating) {
    requestMethod = axios.put;
  }

  return requestMethod(getRegisterSwUrl(), payload, {
    headers: getHeaders(locationId),
  });
};

export const validateSwConnection = (locationId) => {
  return axios.get(getValidateSwConnectionUrl(), {
    headers: getHeaders(locationId),
  });
};


export const deactivateAccount = (location) => {
  return axios.delete(getRegisterSwUrl(), {
    headers: getHeaders(location),
  });
};

export const reconnectAccount = (location) => {
  return axios.get(getReconnectAccountUrl(), {
    headers: getHeaders(location),
  });
};

export const fetchConfig = (location) => {
  return axios.get(getSubmitConfigUrl(), {
    headers: getHeaders(location),
  });
};