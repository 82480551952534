import { useState } from 'react';
import { Heading2, Text, TextLink } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { IconChevronLeft } from '@lightspeed/flame/Icon/ChevronLeft';
import { Button } from '@lightspeed/flame/Button';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { useToasts } from '@lightspeed/flame/Toaster';
import { Alert } from '@lightspeed/flame/Alert';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { useTranslation } from 'react-i18next';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import useConnectionMode from '../../hooks/useConnectionMode';
import { useSendPayloadMutation } from '../../services/dashboardService';
import { parsePayload } from '../../utils/helpers/dashboardTransformer';
import { useLocationData } from '../../hooks/useLocation';
import { setCurrentComponent, setCurrentPostingMethod, setFetchedConfig, setIsPostingMethodChanged, setPaymentError, setRefreshAccounts } from '../../slices/dashboardSlice';
import { useNotification } from '../../utils/helpers/notifications';
import amIVisible from '../../componentControl/registry';
import Copyrights from '../custom/copyright';

const HeadingSection = ({ title, description }) => {
  const { t } = useTranslation();
  const portal = useSelector(state => state.auth.fullName)

  return <Box pb="35px">
    <Heading2 mb="0.375rem">{title || t('LsAccounting', { target: portal })}</Heading2>
    <Text size="small" color="gray-600">
      {description || t('AppDesc')}
    </Text>
  </Box>;
};

const NavigateBack = ({ isSubmitting, path, currentLocation, isMultiLocation, disableBack, hasChanges, ...props }) => {
  const { addToast } = useToasts();
  const targetName = useSelector(state => state.auth.fullName);
  const { t } = useTranslation();
  const isLocationLink = useSelector(state => state.dashboard.currentComponent) === "dash";
  const target = (isMultiLocation) ? currentLocation?.name : targetName;
  const dispatch = useDispatch()

  const { showNotification } = useNotification()

  console.log("#@$@#%#@$")

  const gotoDashboard = () => {
    if (isSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else {
      if (isLocationLink && !hasChanges) {
        props.navigate('../select-location');
      } else {
        if (hasChanges) {
          showNotification('Save the current changes to proceed', 'error')
        } else {
          dispatch(setCurrentComponent("dash"))
        }
      }
    }
  };

  return <Box mt="1.125rem" mb="0.8rem">
    <TextLink mb="0.75rem" onClick={gotoDashboard}>
      <IconChevronLeft color="blue-500"
        style={{
          width: '0.75rem',
          height: '0.75rem',
          mr: '0.563rem',
          marginTop: '0.2rem',
          marginBottom: '0.2rem',
          marginRight: '0.375rem',
        }}
      />
      {isLocationLink ? "Back to Locations" : t('LsAccounting', { target })}
    </TextLink>
  </Box>
};

const DiscardWarning = ({ closeModal, confirm }) => {
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ width: '37.5rem', minWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('DiscardWarning.0')}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('DiscardWarning.1')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={confirm}>
          {t('Discard changes')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const BottomBar = ({ children }) => {
  return <>
    <Box pt="3rem"></Box>
    <Box
      width="100%"
      style={{
        position: 'fixed', bottom: 0, left: 0, zIndex: 3, borderRadius: 0,
        boxShadow: '0px -2px 4px rgba(12, 13, 13, 0.2)'
      }}
      boxShadow="shadow"
    >
      <Card>
        <CardSection
          pl="1.125rem" pr="1.125rem"
          pt="0.563rem" pb="0.563rem"
        >
          {children}
        </CardSection>
      </Card>
    </Box>
  </>;
};

const SaveBar = ({ isSubmitting, isDisabled, discardData, submitData, refetch }) => {
  const [showWarning, setShowWarning] = useState(false);
  const { t } = useTranslation();

  const { isParentCategory, swapSuperParentCategoryFromProducts, currentComponent } = useSelector(state => state.dashboard)?.fetchedConfig
  const { isPostingMethodChanged, fetchedConfig } = useSelector(state => state.dashboard)

  const dispatch = useDispatch()

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirm = () => {
    discardData(isPostingMethodChanged);
    if (refetch) {
      if ((currentComponent === "sales category mapping")) {
        refetch(Number(isParentCategory) ?? 0)
      } else {
        refetch(Number(swapSuperParentCategoryFromProducts) ?? 0)
      }
    }

    if (isPostingMethodChanged) {
      dispatch(setRefreshAccounts(true))
      dispatch(setIsPostingMethodChanged(false))
      dispatch(setCurrentPostingMethod(fetchedConfig?.postingMethod))
    }
  };

  const handleClick = () => {
    setShowWarning(true);
  };

  return <>
    {showWarning &&
      <DiscardWarning
        closeModal={handleCloseWarning}
        confirm={handleConfirm}
      />
    }
    <BottomBar>
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{t('Unsaved changes')}</Text>
        <Flex>
          <Button
            disabled={isSubmitting}
            onClick={handleClick}
            mr="0.75rem"
          >
            {t('Discard')}
          </Button>
          <Button
            fill="true"
            variant="secondary"
            type={"submit"}
            loading={isSubmitting}
            onClick={submitData}
            disabled={isDisabled}
          >
            {t('Save changes')}
          </Button>
        </Flex>
      </Flex>
    </BottomBar>
  </>;
};

const SwitchMappingBar = ({ isDisabled, formik, switchMapping }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const { currentComponent } = useSelector(state => state.dashboard)

  if (!switchMapping) {
    return <></>
  }

  const switchOptions = {
    category: {
      label: t('Map Products'),
      switchTo: 'product',
    },
    product: {
      label: t('Map Categories'),
      switchTo: 'category',
    },
  };

  const handleMappingChange = () => {
    if (currentComponent === "po category mapping") {
      dispatch(setCurrentComponent("po product mapping"))

    } else if (currentComponent === "sales product mapping") {
      dispatch(setCurrentComponent("sales category mapping"))

    } else if (currentComponent === "sales category mapping") {
      dispatch(setCurrentComponent("sales product mapping"))

    } else if (currentComponent === "po product mapping") {
      dispatch(setCurrentComponent("po category mapping"))
    }
  };

  return <BottomBar>
    <Flex justifyContent="flex-end" alignItems="center">
      <Flex>
        <Button
          fill="true"
          variant="secondary"
          onClick={handleMappingChange}
          disabled={isDisabled}
        >
          {switchOptions[(currentComponent === "sales category mapping" || currentComponent === "po category mapping") ? "category" : "product"].label}
        </Button>
      </Flex>
    </Flex>
  </BottomBar>;
};

const ConnectionLost = ({ swName }) => {
  const { t } = useTranslation();

  return <Box pb="35px">
    <Alert
      noCloseBtn={true}
      type="danger"
      title={t('ConnectionLost', { swName })}
    >
      {t('Reconnect to resume syncing')}
    </Alert>
  </Box>;
};

export const PageLayout = ({
  title,
  description,
  children,
  showBack,
  loadAccounts,
  showDisconnected,
  disableBack,
  onReconnectSuccess,
  refetchConfig,
  formik,
  noBottomBar,
  ...props
}) => {
  const [, connectionModeName] = useConnectionMode();
  const { currentLocation, isMultiLocation } = useLocationData();
  const posType = getCurrentTarget();
  const hasChanges = formik?.dirty;
  const [sendPayload] = useSendPayloadMutation()

  const { isGlobalLoading, fetchedConfig, isPostingMethodChanged } = useSelector(state => state.dashboard);

  const { showNotification } = useNotification()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { currentComponent } = useSelector(state => state.dashboard)

  let switchMapping = (amIVisible("sales can show both product and categories") || amIVisible("inventory cogs can show both product and categories"))

  if (switchMapping) {
    switchMapping = ["sales product mapping", "sales category mapping", "po category mapping", "po product mapping"]
      .includes(currentComponent)
  }
    
  if (switchMapping) {
    const { postingMethod } = formik?.values

    const isTypeCategory = postingMethod !== 'invoice';
    switchMapping = !isTypeCategory && switchMapping
  }

  let extraStyleProps = {};

  const discard = (reInit = false) => {
    if (reInit) {
      dispatch(setFetchedConfig({ ...fetchedConfig, key: Math.random() }))
    } else {
      formik.resetForm()
    }
  }

  const handleOnSubmit = () => {
    sendPayload(parsePayload({ ...formik?.values, isResetConfiguration: isPostingMethodChanged }))
      .unwrap()
      .then((res) => {
        // TODO : show success msg
        formik.validateForm();
        if (res?.error && !res?.Validation) {
          showNotification(res?.error, "error")
          if (res?.error === "Configuration can't be completed, Gift Card mapping is required.") {
            dispatch(setPaymentError(true))
          }
        } else if (res?.error) {
          showNotification("Configuration can't be completed", "error")
        } else {
          showNotification("Configuration completed", "success")
        }
      })
      .catch((res) => {
        // TODO: handle erorr on config
        if (res?.data?.error?.key) {
          showNotification(t(res?.data?.error?.key) ?? "Something went wrong", "error")
        } else {
          showNotification(res?.data?.error ?? "Something went wrong", "error")
        }
      })
  }

  return <><Box padding="1.125rem" pb={5} minHeight="100vh" maxWidth="1024px" {...extraStyleProps}>
    {showBack &&
      <NavigateBack
        isSubmitting={isGlobalLoading}
        currentLocation={currentLocation}
        isMultiLocation={isMultiLocation}
        disableBack={disableBack}
        hasChanges={hasChanges}
        {...props}
      />
    }
    <HeadingSection title={title} description={description} />
    {showDisconnected && <ConnectionLost swName={connectionModeName} />}
    {children}

    {
      (hasChanges ? <SaveBar isSubmitting={isGlobalLoading} discardData={discard} submitData={handleOnSubmit} {...props} /> : <SwitchMappingBar switchMapping={switchMapping} formik={formik} isDisabled={isGlobalLoading} />)
    }
  </Box>
    <Box>
      <Copyrights hasShownBottomBar={hasChanges || switchMapping} />
    </Box></>
};
