import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Divider } from '@lightspeed/flame/Divider';
import { TimePicker } from 'antd';
import { Autocomplete } from '../fields';
import ct from "countries-and-timezones"
import { getTZ } from '../../utils/helpers/commonHelpers';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import "./style.css"

export const SelectSpecificTime = ({ formik }) => {

    let c = Object.assign({
        "US": null,
        "CA": null,
        "GB": null,
        "MX": null,
        "AU": null,
        "IN": null,
        "DE": null,
    }, ct.getAllCountries())

    const { isGlobalLoading, isAccountLoading, isRefreshAccount, hasError: anyError } = useSelector(state => state.dashboard);
    const isLoading = isGlobalLoading || isAccountLoading || isRefreshAccount;
    let isError = ''

    c = Object.keys(c).map(k => { return { label: c[k].name, value: c[k].id } })

    let { preferredSyncTime: time, preferredTzCountry: country } = formik?.values;

    const handleOnTimeChange = (pc, time) => {
        formik.setFieldValue("preferredSyncTime", time)
    } 

    if (anyError && _.isEmpty(time)) {
        isError = 'error'
    } else {
        isError = ''
    }

    return <Box padding="0.75rem">
        <Heading3 mb="0.375rem">End of day summary specific time and time zone</Heading3>
        <Text color="gray-600" mb="1.5rem" mr="3.25rem" size="small">
            Select a specific Time and timezone to start syncing data
        </Text>
        <Flex>
            <Box width="49%" pr="1.1rem">
                <LabelRequired htmlFor="time">
                    Pick a time
                </LabelRequired>
                <TimePicker value={_.isEmpty(time) ? undefined : dayjs(time, "HH:mm")} status={isError} disabled={isLoading} format={"HH:mm"} placeholder='HH:MM' allowClear onChange={handleOnTimeChange} style={{ width: "100%" }} />
                {isError !== '' && <Text mt={1} fontSize={".875rem"} color="#DE2E37">Required</Text>}
            </Box>
        </Flex>
        <Divider mt="1.2rem" mb="1.2rem" />
        <Flex>
            <Box width="49%" pr="1.1rem">
                <LabelRequired htmlFor="country">
                    Country
                </LabelRequired>
                <Autocomplete
                    options={c}
                    name="preferredTzCountry"
                    placeholder="Select country"
                />
            </Box>
            <Box width="49%" pr="1.1rem">
                <LabelRequired htmlFor="country">
                    Timezone
                </LabelRequired>
                <Autocomplete
                    name="preferredSyncTz"
                    disabled={_.isEmpty(country)}
                    placeholder="Select timezone"
                    options={getTZ(country ?? '')?.map(t => { return { label: t, value: t } }) ?? []}
                />
            </Box>
        </Flex>
    </Box>;
};
