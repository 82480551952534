import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setConnectionMode } from '../redux/reducer/portalInfoReducer';
import { getConnectionModeName } from '../utils/helpers/commonHelpers';

export const useConnectionMode = () => {
  const connectionMode = useSelector(state => state.dashboard.connectionMode);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setValue = (newValue) => {
    dispatch(setConnectionMode(newValue));
  };

  return [connectionMode, getConnectionModeName(t, connectionMode), setValue];
};

export default useConnectionMode;
