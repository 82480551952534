import { Card, CardHeader, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Form, Formik } from 'formik';
import Copyrights from '../../components/custom/copyright';
import * as Yup from 'yup';
import { useChangePasswordMutation } from '../../services/authService';
import { useNotification } from '../../utils/helpers/notifications';
import { Button } from '@lightspeed/flame/Button';
import { Label } from '@lightspeed/flame/FormField';
import { TextInput } from './login';
import { parse as parseQuery } from 'query-string';
import { isEmpty, some } from 'lodash';

export const ChangePassword = ({ navigate, location }) => {

    const [changePassword, { isLoading }] = useChangePasswordMutation()
    const { showNotification } = useNotification()
    const params = parseQuery(location.search);

    const createValidationSchema = () => {
        const schemaObject = {
            password: Yup.string()
            .required('Password is required')
            .max(16, 'No more than 16 characters')
            .min(6, 'At least 6 characters required')
            .matches(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&]).{6,16}',
              'Must have one capital character, one number and one special character'
            ),
            confirmPassword: Yup.string()
            .required('Password is required')
            .max(16, 'No more than 16 characters')
            .min(6, 'At least 6 characters required')
            .matches(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&]).{6,16}',
              'Must have one capital character, one number and one special character'
            )
        };

        return Yup.object().shape(schemaObject);
    };

    const sendNewPassword = (payload) => {
        changePassword({ ...payload, ...params })
            .unwrap()
            .then((res) => {
                console.log(res)
                if (res?.message) {
                    showNotification("Password Changed Successfully!")
                    navigate("../login")
                }
            })
            .catch((err) => {
                if (err?.data?.message) {
                    showNotification(err?.data?.message, "error")
                }
            })
    }

    const ForgetPwdForm = ({ formik, loading }) => {

        return <Form>
            <Box mb={4}>
                <Label htmlFor="password">Password</Label>
                <TextInput id="password" name="password" placeholder="Enter password" type="password" />
            </Box>
            <Box mb={4}>
                <Label htmlFor="password">Confirm Password</Label>
                <TextInput id="confirmPassword" name="confirmPassword" placeholder="confirm password" type="password" />
            </Box>
            <Button variant="primary" type="submit" fill={true}
                disabled={(!isEmpty(formik.errors) || some(formik.values, isEmpty))}
                loading={loading}
                width="100%"
                mb={2}
            >
                Change Password
            </Button>
        </Form>;
    };

    return (
        <>
            <Flex justifyContent="center">
                <Box width={450} marginTop={50}>
                    <Card>
                        <CardHeader title="Change Password" />
                        <CardSection>
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmPassword: '',
                                }}
                                validationSchema={createValidationSchema()}
                                onSubmit={sendNewPassword}
                            >
                                {(formik) =>
                                    <ForgetPwdForm formik={formik} loading={isLoading} />
                                }
                            </Formik>
                        </CardSection>
                    </Card>
                </Box>
            </Flex>
            <Copyrights isFullPage />
        </>
    )
}