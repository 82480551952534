import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../hooks/useAccounts';
import useConnectionMode from '../../hooks/useConnectionMode';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';

export const PettyCashPayouts = ({ formik }) => {
  const [isLoading, accountsList] = useAccounts('pettyCash');

  const {
    isSentPettyCashPayouts
  } = formik.values;

  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <SwitchLayout
    heading={t('Petty Cash / Payouts')}
    description={`Record Petty Cash / Payouts to accounts in ${swName}. All unassigned and new float differences will be assigned to the default account selected below.`}
    switchFieldName="isSentPettyCashPayouts"
    isSwitchOn={isSentPettyCashPayouts}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="pettyCashPayoutsAccount">
          {t('Default Petty Cash / Payouts account')}
        </LabelRequired>
        <Autocomplete
          id="pettyCashPayoutsAccount"
          name="pettyCashPayoutsAccount"
          isLoadingOptions={isLoading}
          options={accountsList}
          placeholder={isInputsOnlyLayout ? "Enter an account" : "Select an account"}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};