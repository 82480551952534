export const setItem = (key, value) => {
    sessionStorage.setItem(key, value)
}

export const getItem = (key, value) => {
    return sessionStorage.getItem(key, value)
}

export const setTarget = (target) => {
    setItem('target', target)
}

export const setServerUrl = (url) => {
    setItem('server_url', url)
}

export const getServerURL = () => {
    return getItem('server_url')
}

export const getTarget = () => {
    return getItem('target')
}

export const setCompanyToken = (token) => {
    setItem('companyToken', token)
}

export const getCompanyToken = () => {
    return getItem('companyToken')
}

export const setToken = (token) => {
    setItem('token', token)
}

export const getToken = () => {
    return getItem('token')
}