import { useState, useEffect } from 'react';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { Button } from '@lightspeed/flame/Button';
import { Label } from '@lightspeed/flame/FormField';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { IconUnlink } from '@lightspeed/flame/Icon/Unlink';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PopupButton } from "react-calendly";
import { Autocomplete } from '@lightspeed/flame/Autocomplete';
import { getStateOnChangePostingMethod } from '../../utils/helpers/dashboard';
import { setPostingMethodChanged } from '../../redux/reducer/formsReducer';
import { setReload } from '../../redux/reducer/globalsReducer';
import { RadioField, SelectField, TextInput } from '../fields';
import useCompanyName from '../../hooks/useCompanyName';
import { softwareImages } from '../../utils/helpers/softwareImages';
import useConnectionMode from '../../hooks/useConnectionMode';
import useMessage from '../../hooks/useMessage';
import useLocation from '../../hooks/useLocation';
import useAppConnected from '../../hooks/useAppConnected';
import { fetchAllAccounts, refreshAccounts, resetAccounts } from '../../redux/reducer/accountsReducer';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { LabelRequired } from '../custom/LabelRequired';
import { LearnMore } from '../custom/LearnMore';
import { SyncStatus } from '../syncStatus/SyncStatus';
import { AuthorizeSoftware } from '../../Auth/AuthorizeSoftware';
import { registerSw } from '../../api/api';
import amIVisible from '../../componentControl/registry';
import { useConnectionModeMutation } from '../../services/authService';
import { setIsReconnecting } from '../../slices/authSlice';
import { setCurrentPostingMethod, setIsPostingMethodChanged, setRefreshAccounts } from '../../slices/dashboardSlice';

const ConfirmChange = ({ confirmMethods, label }) => {
  const { resolve, reject } = confirmMethods;
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={reject}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('ChangeMethodWarning.0', { label })}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('ChangeMethodWarning.1')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={reject} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={resolve}>
          {t('Change')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const PostingMethodRadio = ({ style, label, formik, ...props }) => {
  const [confirmMethods, setConfirmMethods] = useState();
  const dispatch = useDispatch();

  const confirmChange = ({ meta }) => {
    return new Promise((resolve, reject) => {

      const handleChange = () => {
        const newState = getStateOnChangePostingMethod(formik);
        // Reset formik form. This will propagate and update saved forms

        dispatch(setIsPostingMethodChanged(true))
        dispatch(setRefreshAccounts(true))
        dispatch(setCurrentPostingMethod(props.value))

        formik.resetForm(newState);
        formik.setFieldValue('postingMethod', props.value)

        resolve();
      };

      if (meta.value === '') {
        dispatch(setIsPostingMethodChanged(true))
        dispatch(setRefreshAccounts(true))
        dispatch(setCurrentPostingMethod(props.value))

        resolve();
      } else {
        setConfirmMethods({ resolve: handleChange, reject });
      }
    }).finally(() => { setConfirmMethods(null) });
  };

  return (
    <>
      {confirmMethods &&
        <ConfirmChange
          label={label}
          confirmMethods={confirmMethods}
        />
      }
      <Box width="50%" style={style || {}}>
        <RadioField
          name="postingMethod"
          confirmChange={confirmChange}
          descriptionProps={{ color: 'gray-600' }}
          type="radio"
          label={<Box mb="0.375rem">{label}</Box>}
          {...props}
        />
      </Box>
    </>
  );
};

const PostingMethodRadioLoading = () => {
  return <Box width="49%">
    <Bone height="1rem" width="10rem" style={{ marginBottom: "1.5rem" }} />
    <Bone height="1rem" width="13rem" style={{ marginBottom: "0.5rem" }} />
    <Bone height="1rem" width="6rem" style={{ marginBottom: "0.5rem" }} />
    <Bone height="1rem" width="9rem" />
  </Box>;
};

const AccountingSwInfo = ({ connectionMode, softwareName }) => {
  const [companyName] = useCompanyName();

  return <Flex>
    <Flex width="110px" height="100%" flexDirection="column" justifyContent="center" alignItems="center">
      {connectionMode ?
        <img src={softwareImages[connectionMode]} alt={softwareName}
          style={{ width: '80px' }}
        /> :
        <Bone width="80px" height="80px" />
      }
    </Flex>
    <Flex justifyContent="center" flexDirection="column">
      {connectionMode ?
        <Heading3>{softwareName}</Heading3> :
        <Bone height="1.125rem" width="100px" />
      }
      {Boolean(companyName) && companyName !== "null" &&
        <Text color="gray-600" size="small">{companyName}</Text>
      }
    </Flex>
  </Flex>;
};

const ConfirmDisconnect = ({ closeModal, confirm, description }) => {
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '37.5rem' }}>
      {description ?
        <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
          {description}
        </Text>
        :
        <>
          <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
            {t('DisconnectWarning.0', { swName })}
          </Text>
          <Text mb="0.5rem" color="gray-600" fontSize="text-s">
            {t('DisconnectWarning.1')}
          </Text>
        </>}
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={confirm}>{t('Disconnect')}</Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

export const DeactivateButton = ({ isAppSubmitting, isConnected, onSuccessCallback, onClickCallBack, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [deleteConnection] = useConnectionModeMutation()

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    setShowModal(false);
    onClickCallBack && onClickCallBack()
    deleteConnection({
      method: "DELETE"
    })
      .unwrap()
      .then(() => {
        !onSuccessCallback && props.navigate('../user-onboard');
        onSuccessCallback && onSuccessCallback()
      })
  };

  const handleClick = () => {
    if (!(isLoading || showModal)) {
      setShowModal(true);
    }
  };

  return <>
    {
      showModal &&
      <ConfirmDisconnect
        closeModal={handleCloseModal}
        confirm={handleConfirm}
        {...props}
      />
    }
    {<Button
      fill={true}
      disabled={isAppSubmitting}
      onClick={handleClick}
      loading={isLoading}
      pl="0.563rem"
      pt="0.625rem"
      pb="0.625rem"
    >
      <IconUnlink />
      {t('Disconnect')}
    </Button>}
  </>;
};

const ReconnectButton = ({ isAppSubmitting, isConnected, setConnected, formik, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [swId, swName] = useConnectionMode();
  const [reconnectInfo, setReconnectInfo] = useState();
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const selectedLocation = useLocation();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const connectionMode = useSelector((state) => state.globals.defaultConfigs.connectionMode)
  const [showModal, setShowModal] = useState(false);
  const versions = useSelector(state => state.globals.sage50Versions)
  const [selectedVersion, setSelectedVersion] = useState("")
  const [isUpdating, setUpdating] = useState(false);
  const [popupWindow, setPopupWindow] = useState("");
  const isJeTrue = useSelector(state => state?.forms?.entities?.dashboard?.values?.postingMethod === 'journalEntry')

  let isSage = false;

  if (connectionMode) {
    if (connectionMode.split(" ")[0] === "Sage50") {
      isSage = true
    }
  }

  const handleCloseModal = (args) => {
    setReconnectInfo(null);

    if (args && (args.success === true)) {


      if (!isInputsOnlyLayout) {
        dispatch(refreshAccounts({ id: currentLocation.id, isJeTrue })).then(res => {
          if (fetchAllAccounts.rejected.match(res)) {
            addMessage(res.payload && res.payload.response, { default: t('Fetch failure') });
          }
        });
        dispatch(setReload(true))
      }
    }
  };

  const handleUpdateVersion = () => {
    if (selectedVersion !== "") {
      setUpdating(true)
      registerSw({
        connectionMode: selectedVersion
      }, selectedLocation.id, true).then(res => {
        const url = res?.data?.appDownloadURL;
        const popup = window.open(url, '_blank');
        setPopupWindow(popup)
      })
        .catch(() => {
          setUpdating(false)
          setSelectedVersion("")
          setShowModal(false);
          addMessage({ data: { message: "Failed to update Desktop Version" } }, { type: "error" })
        })
    } else {
      addMessage({ data: { message: "Please select a version" } }, { type: "warning" })
    }
  }

  return <>
    {
      reconnectInfo &&
      <AuthorizeSoftware
        swData={{ id: swId, accountingSoftware: swName }}
        closeModal={handleCloseModal}
        isReconnect={true}
        reconnectInfo={reconnectInfo}
        {...props}
      />
    }
    <Modal isOpen={showModal} closeOnEsc={false}>
      <ModalHeader showCloseButton={false}>Change Desktop Version Of {swName}</ModalHeader>
      <ModalBody style={{ maxWidth: '37.5rem' }}>
        <Autocomplete
          name="version"
          onChange={(e) => setSelectedVersion(e?.label)}
          placeholder={`Select a ${swName} version`}
          options={versions}
        />
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="flex-end">
          <Button disabled={isUpdating} onClick={() => setShowModal(false)} mr="0.8rem">{t('Cancel')}</Button>
          <Button loading={isUpdating} fill="true" variant="danger" onClick={handleUpdateVersion}>
            {t('Change')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
    {isSage && <Button mr="0.75rem"
      disabled={isAppSubmitting}
      onClick={() => setShowModal(true)}
      loading={isLoading}
    >
      Change Desktop Version
    </Button>}
    <Button mr="0.75rem"
      disabled={isAppSubmitting}
      onClick={() => {
        dispatch(setIsReconnecting(1))
        props.navigate('../user-onboard')
      }}
      loading={isLoading}
    >
      {t('Reconnect')}
    </Button>
  </>;
};

const ActionButtons = (props) => {
  const isAppSubmitting = props?.isLoading;
  const [isConnected, setConnected] = useAppConnected();

  const canShowReconnect = amIVisible("reconnect button")
  const canShowDisconnect = amIVisible("disconnect button")

  return <Flex alignItems="center" pr="0.5rem">
    {isConnected !== null ?
      <>
        {canShowReconnect && <ReconnectButton
          isAppSubmitting={isAppSubmitting}
          isConnected={isConnected}
          setConnected={setConnected}
          {...props}
        />}
        {canShowDisconnect && <DeactivateButton {...props}
          isAppSubmitting={isAppSubmitting}
          isConnected={isConnected}
        />}
      </> :
      <>
        {canShowReconnect && <Bone height="2.25rem" width="6.25rem" style={{ marginRight: '0.75rem' }} />}
        {canShowDisconnect && <Bone height="2.25rem" width="6.25rem" />}
      </>
    }
  </Flex>;
};

const InfoCardSectionTop = (props) => {
  return <Flex height="100px" justifyContent="space-between" width="100%">
    <AccountingSwInfo {...props} />
    <ActionButtons {...props} />
  </Flex>;
};

const SyncInfoForm = ({ isLoading, formik }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const connectionMode = useConnectionMode()

  const { fetchedConfig } = useSelector(state => state.dashboard)

  const { postingMethod } = formik?.values;

  const handleOnChange = (evt) => {
    if (evt?.target?.value === "1" && postingMethod === "journalEntry") {
      dispatch(setPostingMethodChanged(getStateOnChangePostingMethod(formik)))
      dispatch(resetAccounts());
      formik?.setFieldValue('postingMethod', "")
      formik?.setFieldValue('keepInvoice', false)
    }

    formik?.setFieldValue('dataTransferType', evt?.target?.value)
  }

  const handleOnSubTypeChange = (evt) => {
    if (evt?.target?.value === "1") {
      formik?.setFieldValue('preferredSyncTime', '')
      formik?.setFieldValue('preferredTzCountry', '')
      formik?.setFieldValue('preferredSyncTz', '')
    }

    formik?.setFieldValue('specificSynctype', evt?.target?.value)
  }

  const canShowPostingType = amIVisible("Posting Type")
  let canShowEODGroup = amIVisible("end of summary groups")
  const canShowSyncSchedule = amIVisible("Sync Schedule")
  const canShowEmail = amIVisible("Notification email")
  const canShowPhoneNo = amIVisible("Notification phone number")

  return <Box width="50%" padding="0.75rem">
    <Box>
      {connectionMode[0] === 'QB Desktop Edition' ?
        <div style={{ wordWrap: "break-word" }}>
          <Label>File Path : </Label>
          <p htmlFor="filepath" style={{ fontWeight: "700", color: "#2E61DE", paddingBottom: "5px" }}>{fetchedConfig?.filePath}</p>
        </div>
        :
        ''
      }
      {connectionMode[0] === 'QB Desktop Edition' ?
        <Label htmlFor="ConnectorTime" fontWeight="bold">{t('ConnectorTime')} : {fetchedConfig?.connectorLastRun}</Label>
        :
        ''
      }
    </Box>
    <Box mb="1.5rem">
      {canShowPostingType &&
        <>
          <Label htmlFor="syncRange">Posting Type</Label>
          <SelectField isLoading={isLoading} id="dataTransferType" onChange={handleOnChange} name="dataTransferType">
            <option value="1">Individual invoice sync</option>
            <option value="2">Batch end of day summary</option>
          </SelectField>
        </>
      }
      {canShowSyncSchedule &&
        <>
          <Label htmlFor="syncRange">{t('Sync schedule')}</Label>
          <SelectField isLoading={isLoading} id="syncRange" name="syncRange">
            <option value="0">{t('Pause')}</option>
            <option value="1">{t('Sync daily')}</option>
            <option value="3">{t('Sync weekly on Mondays')}</option>
            <option value="4">{t('Sync weekly on Tuesdays')}</option>
            <option value="5">{t('Sync weekly on Wednesdays')}</option>
            <option value="6">{t('Sync weekly on Thursdays')}</option>
            <option value="7">{t('Sync weekly on Fridays')}</option>
            <option value="8">{t('Sync weekly on Saturdays')}</option>
            <option value="2">{t('Sync weekly on Sundays')}</option>
          </SelectField>
        </>}
      {canShowEODGroup &&
        <Box mt="1.5rem">
          <Label htmlFor="syncRangeSubType">When do you want to sync the data to the accounting software?</Label>
          <SelectField isLoading={isLoading} id="specificSynctype" onChange={handleOnSubTypeChange} name="specificSynctype">
            <option value="1">Credit Card Processing Cut Off</option>
            <option value="2">Specific Time of the Day</option>
          </SelectField>
        </Box>
      }
    </Box>
    {canShowEmail && <Box mb="1.5rem">
      <LabelRequired htmlFor="email1"
        description={t('NotificationEmailDescription')}
        descriptionProps={{ color: 'gray-600' }}
      >
        {t('Notification email')}
      </LabelRequired>
      <TextInput
        isLoading={isLoading}
        name="email1"
        id="email1"
        placeholder={t('Enter email address')}
      />
    </Box>}
    {canShowPhoneNo && <Box>
      <CustomLabel
        label={"Notification Phone Number"}
        description={"Get a notification by phone number when a sync error occurs"}
        htmlFor="phoneNumber"
        mb="0.375rem"
      />
      <TextInput
        pt={".5rem"}
        type={"number"}
        isLoading={isLoading}
        id="phoneNumber"
        name="phoneNumber"
        placeholder={t('Enter Phone number')}
      />
    </Box>}
  </Box>;
};

const CustomLabel = ({ label, required, ...props }) => {
  const LabelComp = required === true ? LabelRequired : Label;
  return <LabelComp
    descriptionProps={{ marginTop: '0.375rem' }}
    {...props}
  >
    {label}
  </LabelComp>;
};

const InfoCardSectionBottom = (props) => {

  return <Flex>
    <SyncInfoForm {...props} />
    {amIVisible("Sync box") && <SyncStatus {...props} />}
  </Flex>;
};

const InfoCard = (props) => {
  return <>
    <InfoCardSectionTop {...props} />
    <InfoCardSectionBottom {...props} />
  </>;
};

const PostingMethod = ({ isLoading, formik }) => {
  const disabled = isLoading;
  const fullName = useSelector(state => state.auth.fullName);
  const { t } = useTranslation();
  // TODO : need to check isInput later
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  const canShowProduct = amIVisible("Invoice By Product")
  const canShowCategory = amIVisible("Invoice By Category")
  const canShowGroup = amIVisible("Invoice By Group")
  const canShowAccountingSw = amIVisible("Invoice By accounting sw")
  let canShowJe = amIVisible("Journal Entry")
  const canShowPostingMethod = amIVisible("Posting Type")

  const isIndividualSync = parseInt(formik?.values?.dataTransferType) === 1 && canShowPostingMethod;

  if (isIndividualSync) {
    canShowJe = false;
  }

  return <Box padding="0.75rem">
    <Heading3 mb="1.5rem">{t('Select posting method')}</Heading3>
    {
      <Flex mb="1.5rem">
        {canShowCategory && <PostingMethodRadio
          value="invoiceCategory"
          id="postingCategories"
          label={'Invoices by category'}
          disabled={disabled}
          style={{ paddingRight: '0.8rem' }}
          formik={formik}
          description={t('InvoiceCategoriesDescription LSeries', {
            target: fullName,
          })}
        />}
        {canShowAccountingSw && <PostingMethodRadio
          value="invoiceCategory"
          id="postingInvoices"
          label={'Invoices by accounting software'}
          disabled={disabled}
          formik={formik}
          description={t('InvoicesDescription KSeries', {
            target: fullName,
          })}
        />}
        {canShowGroup && <PostingMethodRadio
          value="invoiceCategory"
          id="postingInvoices"
          label={'Invoices by Grouping'}
          disabled={disabled}
          formik={formik}
          description={t('InvoicesDescription KSeries', {
            target: fullName,
          })}
        />}
        {canShowProduct && <PostingMethodRadio
          value="invoice"
          id="postingInvoices"
          label={'Invoices by product'}
          disabled={disabled}
          formik={formik}
          description={t('InvoicesDescription LSeries', {
            target: fullName,
          })}
        />}
      </Flex>
    }
    {
      <Flex mb="1.5rem">
        {canShowJe && <PostingMethodRadio
          value="journalEntry"
          id="postingJe"
          label={t('Journal entry')}
          disabled={disabled}
          formik={formik}
          description={t('JournalEntryDescription', {
            target: fullName
          })}
        />}
      </Flex>
    }
  </Box>;
};

const PostingMethodLoading = () => {
  return <Box padding="0.75rem">
    <Bone height="1.5rem" width="7rem" style={{ marginBottom: "1.5rem" }} />
    <Flex mb="1.5rem">
      {
        [1, 2].map(id => {
          return <PostingMethodRadioLoading key={id} />
        })
      }
    </Flex>
  </Box>;
};

const AccountingSoftwareContent = (props) => {
  const canShowScheduleMeeting = amIVisible("schedule Meeting button")

  return <Card ml="1rem">
    <CardSection>
      {canShowScheduleMeeting && <Flex justifyContent={"end"}>
        <PopupButton
          text="Schedule time with Integration Developer to get help on setup"
          url="https://calendly.com/posaccounting/30min"
          rootElement={document.getElementById("root")}
          className="epos-chat"
        />
      </Flex>}
      <InfoCard {...props} />
    </CardSection>
    <Divider />
    <CardSection>
      {!props?.isLoading ?
        <PostingMethod {...props} /> :
        <PostingMethodLoading />
      }
    </CardSection>
  </Card>;
};

export const AccountingSoftware = (props) => {
  const { t } = useTranslation();
  const [connectionMode, softwareName] = useConnectionMode();

  const { isGlobalLoading } = useSelector(state => state.dashboard);

  return <AnnotatedLayout
    title={t('Accounting Software')}
    description={t('AccountingSoftwareDescription')}
    renderExtras={
      <LearnMore />
    }
    mb="2.25rem"
  >
    <AccountingSoftwareContent connectionMode={connectionMode} softwareName={softwareName} {...props} isLoading={isGlobalLoading} />
  </AnnotatedLayout>;
};
