import { Suspense } from 'react';
import { FlameTheme, FlameGlobalStyles } from '@lightspeed/flame/Core';
import { ToasterProvider } from '@lightspeed/flame/Toaster';
import './App.css';
import Router from './Router/routes';
import { FloatButton } from 'antd';

function App() {

  return (
    <FlameTheme>
      {/* Wrapping `<div>` is necessary since `<FlameTheme>` is a Provider */}
      <div style={{ height: '100%' }}>
        {/* We set some global styles, like fonts and minimal resets */}
        <FlameGlobalStyles />
        <ToasterProvider>
          <Router />
        </ToasterProvider>
        <FloatButton.BackTop style={{ bottom: "130px" }} />
      </div>
    </FlameTheme>
  );
}

export default function AppSuspense() {
  return (
    <Suspense fallback="loading">
      <App />
    </Suspense>
  );
};