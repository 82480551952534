import { createSlice } from '@reduxjs/toolkit';
import { syncApi } from '../services/syncService';

const initialState = {
    masterData: [],
    syncData: [],
    pageBackupTable: null,
    pageOriginalTable: null,
    isLastPage: true,
    s: null,
    sd: null,
    ed: null,
    filterPostType: null,
    isLoading: false,
    isResyncing: false,
}

export const syncSlice = createSlice({
    name: 'sync',
    initialState,
    reducers: {
        resetSyncPage: () => {
            return initialState;
        },
        setSearchQuery: (state, { payload: s }) => {
            return {
                ...state,
                syncData: [],
                masterData: [],
                pageBackupTable: null,
                pageOriginalTable: null,
                sd: null,
                ed: null,
                filterPostType: null,
                s
            }
        },
        setDateFilter: (state, { payload: [sd, ed] }) => {
            return {
                ...state,
                syncData: [],
                masterData: [],
                pageBackupTable: null,
                pageOriginalTable: null,
                filterPostType: null,
                sd,
                ed
            }
        },
        setPostTypeFilter: (state, { payload: filterPostType }) => {
            let filteredData = state?.masterData;

            if (filterPostType !== -1 && filterPostType !== undefined) {
                filteredData = state?.masterData?.filter(data => {
                    return data?.success === filterPostType
                })
            }

            return {
                ...state,
                filterPostType: (filterPostType !== -1 && filterPostType !== undefined) ? filterPostType : null,
                syncData: filteredData
            }
        },
        setResetParticular: (state, { payload }) => {
            return {
                ...state,
                ...payload
            }
        },
        setResetAll: () => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            syncApi.endpoints.getSyncData.matchPending, (state) => {
                return {
                    ...state,
                    isLoading: true
                }
            }
        )

        builder.addMatcher(
            syncApi.endpoints.getSyncData.matchFulfilled, (state, { payload }) => {
                const { pageOriginalTable, pageBackupTable } = payload?.pageValues;
                const { filterPostType } = state

                let data = [...state.syncData, ...payload?.data] ?? []

                if (filterPostType !== null) {
                    data = data.filter(data => {
                        return data?.success === filterPostType
                    })
                }

                return {
                    ...state,
                    syncData: data ?? [],
                    masterData: [...state.masterData, ...payload?.data] ?? [],
                    pageOriginalTable,
                    pageBackupTable,
                    isLastPage: payload?.data?.length < 20,
                    isLoading: false
                }
            }
        )

        builder.addMatcher(
            syncApi.endpoints.getSyncData.matchRejected, (state, arg) => {

                if (arg?.error) {
                    if (arg?.error?.name === "ConditionError" && arg?.error?.message === "Aborted due to condition callback returning false.") {
                        return {
                            ...state
                        }
                    }
                }

                return {
                    ...state,
                    isLoading: false
                }
            }
        )

        builder.addMatcher(
            syncApi.endpoints.repostSync.matchPending, (state) => {

                return {
                    ...state,
                    isResyncing: true
                }
            }
        )

        builder.addMatcher(
            syncApi.endpoints.repostSync.matchFulfilled, (state, { payload, meta }) => {
                let syncs = state.syncData;
                let masterSyncs = state.masterData;

                syncs = syncs?.map(data => {
                    let newData = data;
                    if (data.id === meta?.arg?.originalArgs?.body?.id) {
                        newData = Object.assign({}, data, {
                            success: 2,
                        });
                    }
                    return newData;
                })

                masterSyncs = masterSyncs?.map(data => {
                    let newData = data;
                    if (data.id === meta?.arg?.originalArgs?.body?.id) {
                        newData = Object.assign({}, data, {
                            success: 2,
                        });
                    }
                    return newData;
                })

                return {
                    ...state,
                    syncData: syncs,
                    masterData: masterSyncs,
                    isResyncing: false
                }
            }
        )

        builder.addMatcher(
            syncApi.endpoints.repostSync.matchRejected, (state) => {

                return {
                    ...state,
                    isResyncing: false
                }
            }
        )
    }
})

export const {
    setSearchQuery,
    setDateFilter,
    setResetParticular,
    setResetAll,
    resetSyncPage,
    setPostTypeFilter
} = syncSlice.actions;

export default syncSlice.reducer;