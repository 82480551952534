import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import './i18n';
import './index.css';
import { Freshchat } from 'reactjs-freshchat';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Freshchat
        token={'00b6ddea-bee5-4a57-b767-e51cb812490d'}
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();