
import { createSlice } from '@reduxjs/toolkit';
import { authService } from '../services/authService';
import { dashboardApi } from '../services/dashboardService';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        portal: '',
        fullName: '',
        serverUrl: '',
        token: '',
        appToken: '',
        isLogout: false,
        swList: [],
        isReconnecting: 0,
        locationid: '',
        locationList: [],
        isLogoutPending: false,
        country: "",
        isOpsAction: false,
        isInputsOnlyLayout: false
    },
    reducers: {
        setPortal: (state, { payload }) => {
            state.portal = payload
        },
        setLocationId: (state, { payload: locationid }) => {
            return {
                ...state,
                locationid
            }
        },
        setIsOpsAction: (state, { payload: isOpsAction }) => {
            return {
                ...state,
                isOpsAction
            }
        },
        setLocationList: (state, { payload: locationList }) => {
            return {
                ...state,
                locationList
            }
        },
        setFullName: (state, { payload }) => {
            state.fullName = payload
        },
        setServerUrl: (state, { payload }) => {
            state.serverUrl = payload
        },
        setToken: (state, { payload: token }) => {
            return {
                ...state,
                token
            }
        },
        setAppToken: (state, { payload: appToken }) => {
            return {
                ...state,
                appToken
            }
        },
        setIsReconnecting: (state, { payload }) => {
            return {
                ...state,
                isReconnecting: payload
            }
        }
    },
    extraReducers: (builder) => {
        /* authorize api */
        builder.addMatcher(
            authService.endpoints.authorize.matchFulfilled, (state, { payload }) => {
                return {
                    ...state,
                    swList: payload.accountingSoftware,
                    locationid: payload.locationList[0].id,
                    locationList: payload.locationList
                }
            }
        )

        /* authorize api */
        builder.addMatcher(
            authService.endpoints.isCompanyAuthorized.matchFulfilled, (state, { payload }) => {
                return {
                    ...state,
                    isInputsOnlyLayout: (Object.keys(payload ?? {})).includes("showMappingDropdowns") ? !payload.showMappingDropdowns : false,
                }
            }
        )

        /* authorize account ID api */
        builder.addMatcher(
            authService.endpoints.authorizeAccountId.matchFulfilled, (state, { payload }) => {
                return {
                    ...state,
                    swList: payload.accountingSoftware,
                    locationid: payload?.locationList[0]?.id,
                    locationList: payload.locationList
                }
            }
        )

        /* login api */
        builder.addMatcher(
            authService.endpoints.login.matchFulfilled, (state, { payload }) => {
                return {
                    ...state,
                    swList: payload.accountingSoftware,
                    locationid: payload.locationList[0].id,
                    locationList: payload.locationList,
                    country: payload?.country ?? ""
                }
            }
        )

        /* authenticate api */
        builder.addMatcher(
            authService.endpoints.authenticate.matchFulfilled, (state, { payload }) => {
                return {
                    ...state,
                    swList: payload.accountingSoftware,
                    locationid: payload.locationList[0].id,
                    locationList: payload.locationList,
                    country: payload?.country ?? ""
                }
            }
        )

        /* authorizeOAuth api */
        builder.addMatcher(
            authService.endpoints.authorizeOAuth.matchFulfilled, (state, { payload }) => {
                return {
                    ...state,
                    swList: payload.accountingSoftware,
                    locationid: payload.locationList[0].id,
                    locationList: payload.locationList
                }
            }
        )

        /* logout api */
        builder.addMatcher(
            authService.endpoints.logout.matchPending, (state) => {
                return {
                    ...state,
                    isLogoutPending: true,
                }
            }
        )

        builder.addMatcher(
            authService.endpoints.logout.matchFulfilled, (state) => {
                return {
                    ...state,
                    isLogout: true,
                    isLogoutPending: false,
                }
            }
        )

        builder.addMatcher(
            authService.endpoints.logout.matchRejected, (state) => {
                return {
                    ...state,
                    isLogoutPending: false,
                }
            }
        )

        /* config api */
        builder.addMatcher(
            dashboardApi.endpoints.sendPayload.matchFulfilled, (state, { payload }) => {
                const locs = state.locationList?.map(loc => {
                    const obj = Object.assign({}, loc)
                    if (obj?.id === state.locationid) {
                        obj.isConfigured = Number(!Boolean(payload?.error ?? false))
                        obj.syncEnabled = Number(!Boolean(payload?.error ?? false))
                    }

                    return obj
                })

                return {
                    ...state,
                    locationList: locs
                }
            }
        )

        /* disconnect api */
        builder.addMatcher(
            authService.endpoints.connectionMode.matchFulfilled, (state, { meta }) => {
                if (meta?.arg?.originalArgs?.method === "DELETE") {
                    const locs = state.locationList?.map(loc => {
                        const obj = Object.assign({}, loc)
                        if (obj?.id === state.locationid) {
                            obj.isConfigured = 0
                            obj.syncEnabled = 0
                            obj.showSetupAccountingButton = true
                            obj.isParentLocation = true
                            obj.connectionMode = null
                        }

                        return obj
                    })

                    return {
                        ...state,
                        locationList: locs
                    }
                }

                return state
            }
        )
    }
});

export const { setPortal, setFullName, setIsOpsAction, setServerUrl, setToken, setAppToken, setIsReconnecting, setLocationId, setLocationList } = authSlice.actions;

export const currentPortal = (state) => state.auth.portal;
export const serverUrl = (state) => state.auth.serverUrl;
export const token = (state) => state.auth.token;
export const appToken = (state) => state.auth.appToken;

export default authSlice.reducer;