import { useField } from 'formik';
import { Text } from '@lightspeed/flame/Text';
import { Select } from '@lightspeed/flame/Select';
import { Radio } from '@lightspeed/flame/Radio';
import { Input } from '@lightspeed/flame/Input';
import { FormHelper } from '@lightspeed/flame/FormField';
import { Switch } from '@lightspeed/flame/Switch';
import { Bone } from '@lightspeed/flame/Bone';
import { Autocomplete } from '@lightspeed/flame/Autocomplete';
import { withTheme } from 'emotion-theming';
import AutocompleteStyle from '@lightspeed/flame/Autocomplete/Autocomplete.style';
import { Checkbox } from '@lightspeed/flame/Checkbox';
import { useEffect } from "react"
import useAppSubmitting from '../../hooks/useAppSubmitting';
import { useHasSubmittedWithErrors } from '../../hooks/useHasSubmittedWithErrors';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { useSelector } from 'react-redux';
import { Flex } from '@lightspeed/flame/Core';

const AutocompleteField = ({ theme, hasError: hasErrorInitial, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const selectedOption = props.options.find(option => (option.value === meta.value));
  const { isGlobalLoading, isAccountLoading, isRefreshAccount, hasError: anyError } = useSelector(state => state.dashboard);
  const isLoading = isGlobalLoading || isAccountLoading || isRefreshAccount;
  let status = {};
  let conditionalProps = {};
  let SelectField = Autocomplete;
  let hasError = hasErrorInitial ?? false;

  const handleChange = (option, effect) => {
    if (effect?.action === "clear") {
      helpers.setValue("");
    } else {
      helpers.setValue(option.value);
    }
  };

  if (anyError && meta.error && !props.disabled) {
    status = { type: 'error', message: meta.error };
    hasError = true;
  }

  // Fix the overlapping problem of the component
  const flameStyles = AutocompleteStyle(theme);
  const flameMenuStyle = flameStyles.menu;

  const menuStyle = provided => ({
    ...flameMenuStyle(provided),
    zIndex: 5,
  });

  const placeholderOverflowStyle = provided => ({
    ...(flameStyles.placeholder(provided)),
    whiteSpace: 'nowrap',
  });

  return <>
    <SelectField
      {...field} {...meta}
      value={selectedOption || ''}
      onChange={handleChange}
      isDisabled={isLoading || props.isLoading || props.disabled}
      menuPlacement="auto"
      hasError={hasError}
      isClearable
      styles={Object.assign({}, flameStyles, { menu: menuStyle, placeholder: placeholderOverflowStyle })}
      status={status}
      {...conditionalProps}
      {...props}
      isLoading={isLoading || props.isLoading}
    />
    {
      status.message &&
      <FormHelper mt={1} status={status.type}>
        {status.message}
      </FormHelper>
    }
  </>;
};

const AutocompleteFieldWithTheme = withTheme(AutocompleteField);

const AutocompleteFieldProtected = (props) => {
  const isLoading = !props.options;
  return <>
    {isLoading ?
      <Bone height="2rem" /> :
      <AutocompleteFieldWithTheme {...props} />
    }
  </>;
};

export const RadioField = ({ description, confirmChange, ...props }) => {
  const [field, meta] = useField(props.name);
  let label = props.label;
  const [isAppSubmitting] = useAppSubmitting();

  if (meta.error && meta.touched && props.showErrors === true) {
    label = <Text color="red">{props.label}</Text>;
  }

  const handleChange = (event) => {
    /**
     * For react v17, e.persist() will not be needed as the SyntheticEvent is no longer pooled
     */
    event.persist();
    if (confirmChange) {
      confirmChange({ event, field, meta }).then(() => {
        field.onChange(event);
      }).catch(() => { });
    } else {
      field.onChange(event);
    }
  };

  return <Radio
    {...field}
    {...props}
    disabled={props.disabled || isAppSubmitting}
    onChange={handleChange}
    checked={field.value === props.value}
    label={label}
    description={description}
  />;
};


const TextInput = (props) => {
  const [field, meta, helpers] = useField(props);
  const [isAppSubmitting] = useAppSubmitting();
  const { hasError: anyError } = useSelector(state => state.dashboard);

  let status = {}, hasError = false;

  if ((anyError || meta.touched) && meta.error && !props.disabled) {
    status = { type: 'error', message: meta.error };
    hasError = true;
  }

  useEffect(() => {
    const elem = document?.getElementById("phoneNumber");

    elem?.addEventListener("keydown", (e) => {
      var invalidChars = ["-", "+", "e", ".", "_", "E"];
      if (invalidChars?.includes(e?.key)) {
        e?.preventDefault();
      }
    })

    return () => window?.removeEventListener("keydown", elem, true)
  }, [])

  const handleChange = (args) => {
    helpers.setTouched();
    field.onChange(args);
  };

  return (
    <Flex flexDirection={"column"}>
      <Input
        {...field}
        {...props}
        disabled={props.disabled || isAppSubmitting}
        onBlur={handleChange}
        status={status}
        hasError={hasError}
        value={field.value === undefined ? '' : field.value}
      />
    </Flex>
  );
};

const TextInputProtected = ({ isLoading, ...props }) => {
  return <>
    {isLoading ?
      <Bone height="2rem" /> :
      <TextInput {...props} />
    }
  </>;
};


const SelectField = (props) => {
  const [field, meta] = useField(props);
  const [isAppSubmitting] = useAppSubmitting();
  const [forceShowErrors] = useHasSubmittedWithErrors();

  let status;

  if (meta.error && (meta.touched || forceShowErrors)) {
    status = { type: 'error', message: meta.error };
  }

  return <>
    <Select
      status={status} {...field} {...props}
      disabled={props.disabled || isAppSubmitting}
    />
    {
      status &&
      <FormHelper mt={1} status={status.type}>
        {status.message}
      </FormHelper>
    }
  </>
};

const SelectFieldProtected = ({ isLoading, ...props }) => {
  return <>
    {isLoading ?
      <Bone height="2rem" /> :
      <SelectField {...props} />
    }
  </>;
};

export const CheckboxField = ({ description, confirmChange, ...props }) => {
  const [field, meta] = useField(props.name);
  let label = props.label;
  const [isAppSubmitting] = useAppSubmitting();

  if (meta.error && meta.touched && props.showErrors === true) {
    label = <Text color="red">{props.label}</Text>;
  }

  return <Checkbox
    {...field}
    {...props}
    disabled={props.disabled || isAppSubmitting}
    checked={field.value === true}
    label={label}
    description={description}
  />;
};

export const SwitchField = ({ values, ...props }) => {
  const [field, meta] = useField(props.name);
  const [isAppSubmitting] = useAppSubmitting();

  return <Switch
    {...field}
    checked={Boolean(meta?.value) === true}
    {...props}
    disabled={props.disabled || isAppSubmitting}
  />;
};

const AutocompleteTextInput = (props) => {
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  let result = <></>;
  if (isInputsOnlyLayout === true && !props?.dropdown) {
    result = <TextInputProtected {...props} />;
    /* TODO: support for isInputOnly */
  } else {
    result = <AutocompleteFieldProtected {...props} />
  }

  return result;
};

export {
  SelectFieldProtected as SelectField,
  TextInputProtected as TextInput,
  AutocompleteFieldProtected as AutocompleteOnly,
  AutocompleteTextInput as Autocomplete,
};
