import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete, SwitchField } from '../fields';
import { useToasts } from '@lightspeed/flame/Toaster';
import { useTranslation } from 'react-i18next';
import useAppSubmitting from '../../hooks/useAppSubmitting';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { useNotification } from '../../utils/helpers/notifications';
import { useDispatch } from 'react-redux';
import { setCurrentComponent } from '../../slices/dashboardSlice';

export const MapDiscounts = ({ fetchedConfig, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const formik = props.formik;
  const [, softwareName] = useConnectionMode();
  const { addToast } = useToasts();
  const [isLoadingAccounts, accountsList] = useAccounts('discount');
  const {
    isSentDiscount,
    defaultDiscountAccountName: defaultAccount
  } = formik.values;
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;

  const { showNotification } = useNotification()
  const dispatch = useDispatch()


  const gotoPaymentMapping = () => {
    if (formik.dirty) {
      showNotification("Save the current changes to proceed", "error")
    } else {
      dispatch(setCurrentComponent("discount mapping"))
    }
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultAccount) {
      gotoPaymentMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'SelectDiscountFirst'), {
        appearance: 'error'
      });
    }
  };

  return <Box padding="0.75rem">
    <Flex justifyContent="space-between">
      <Heading3 mb="0.375rem">{t('Discounts')}</Heading3>
      <SwitchField id="isSentDiscount" name="isSentDiscount" disabled={isLoading} />
    </Flex>
    <Text color="gray-600"
      mb={isSentDiscount ? '1.5rem' : 0}
      mr="3.25rem"
      size="small"
    >
      {t('MapDiscountDescription', { swName: softwareName })}
    </Text>
    {isSentDiscount &&
      <Flex>
        <Box width="49%" pr="1.1rem">
          <LabelRequired htmlFor="defaultDiscountAccountName">
            {t('Default discount account')}
          </LabelRequired>
          <Autocomplete
            id="defaultDiscountAccountName"
            name="defaultDiscountAccountName"
            isLoading={isLoading}
            options={accountsList}
            placeholder={
              t(isInputsOnlyLayout ? 'Enter default account' : 'Select an account')
            }
          />
        </Box>
        {amIVisible("discount individual") && <Flex mt="1.4rem" alignItems="center" height="2.25rem">
          {isLoading ?
            <Bone height="1rem" width="12rem" /> :
            <TextLink size="small" fontWeight="bold"
              onClick={handleNavigation}
            >
              {t('Map individual discounts')}
            </TextLink>
          }
        </Flex>}
      </Flex>
    }
  </Box>;
};