import React from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Autocomplete } from '../fields';
import { Text } from '@lightspeed/flame/Text';
import { Label } from '@lightspeed/flame/FormField';
import { useGetLocationsQuery } from '../../services/dashboardService';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import { useNotification } from '../../utils/helpers/notifications';

export const ClassTracking = ({ formik }) => {
  const { isMapClass } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  console.log(isMapClass)

  const { data: classList, isFetching: fetchingLocation, isLoading: loadingLocation } = useGetLocationsQuery({ class: false }, { skip: !isMapClass ?? true })

  const isLoading = fetchingLocation || loadingLocation

  const { showNotification } = useNotification();

  useEffect(() => {
      if (classList && classList?.length === 0) {
          showNotification('Class Tracking not enabled in accounting software', 'error');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classList])

  return (
    <SwitchLayout
      heading={"Location/Class tracking"}
      description={`Use locations/classes to track your transactions by departments, product lines, or any other meaningful segments in your business. Only available for ${swName} Plus and Advanced. Ensure that "Track Locations/Classes" is enabled in ${swName}.`}
      switchFieldName="isMapClass"
      isSwitchOn={isMapClass}
      isLoading={isLoading}
    >
      <Flex>
        <Box width="49%" pr="1.1rem">
          <Label htmlFor="classMapping">
            {t('Select a Class')}
          </Label>
          <Autocomplete
            id="classMapping"
            name="classes"
            isLoadingOptions={!classList || isLoading}
            options={classList}
            placeholder={t('Select a class')}
            formik={formik}
          />
        </Box>
      </Flex>
      <Flex mt="1rem" ml=".2rem">
        <><Text fontWeight={"bold"} mr=".3rem">Required : </Text> Class mapping </>
      </Flex>
    </SwitchLayout>
  )
}