// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        name: "Domain name",
        fieldName: "field_1",
        type: "input",
        required: true,
        display: true
    },
    {
        name: "First Name",
        fieldName: "firstName",
        type: "input",
        required: true
    }, 
    {
        name: "Last Name",
        fieldName: "lastName",
        type: "input",
        required: true
    }, 
    {
        name: "Email",
        fieldName: "email",
        type: "input",
        required: true
    }, 
    {
        name: "Country",
        fieldName: "country",
        type: "option",
        required: true
    }, 
    {
        name: "Timezone",
        fieldName: "timeZone",
        type: "option",
        required: true
    }, 
    {
        name: "Password",
        fieldName: "password",
        type: "password",
        required: true
    }, 
    {
        name: "Confirm Password",
        fieldName: "confirmPass",
        type: "password",
        required: true
    },
    {
        name: "Contact phone number",
        fieldName: "contactNumber",
        type: "input",
        required: true
    }
]