import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Decides whether to "clear" the mapping list or not
 */
export const useIgnoreMapping = () => {
  const formsMeta = useSelector(state => state.forms.meta);
  // User changed posting method without save
  let ignoreFetchedMapping = formsMeta.isChangedPostingMethod;

  // User changed posting method, saved, and then moved to mapping screen
  if (!ignoreFetchedMapping) {
    const { source, isChangedPostingMethod } = formsMeta.lastSave;
    ignoreFetchedMapping = (source === 'save') && (isChangedPostingMethod === true);
  }

  return { ignoreFetchedMapping };
};

export const useShowTaxError = (fetchedConfig, isPurchaseTax = false) => {
  const [showError, setShowError] = useState(false);
  const { taxMapping, purchaseTaxMapping } = useSelector(state => state.forms.entities);
  const savedMapping = isPurchaseTax ? purchaseTaxMapping : taxMapping;
  const { ignoreFetchedMapping } = useIgnoreMapping();
  const hasSubmittedWithErrors = useSelector(state => state.globals.hasSubmittedWithErrors);

  // Do calculations only when necessary
  useEffect(() => {
    if (hasSubmittedWithErrors) {
      if (!showError) {
        // Check if user has done mapping before page load
        let taxesMissingInConfig = true;
        const taxApiField = isPurchaseTax ? 'poTaxes' : 'tax';
        if (!ignoreFetchedMapping && fetchedConfig && fetchedConfig[taxApiField]) {
          taxesMissingInConfig = Object.keys(fetchedConfig[taxApiField]).some(key => {
            return Boolean(fetchedConfig[taxApiField][key]) === false;
          });
        }

        // Check if user has done mapping just now
        let taxesMissing = true;
        const taxFormField = isPurchaseTax ? 'purchaseTaxMapping' : 'taxMapping';
        if (taxesMissingInConfig && savedMapping) {
          const allValues = savedMapping?.values;
          const values = allValues && allValues[taxFormField];
          taxesMissing = values ? values.some(data => !Boolean(data.account)) : taxesMissing;
        }

        setShowError(taxesMissingInConfig && taxesMissing);
      }
    } else if (showError) {
      setShowError(false);
    }
  }, [showError, savedMapping, hasSubmittedWithErrors, fetchedConfig, ignoreFetchedMapping, isPurchaseTax]);

  return showError;
};

export default useShowTaxError;