import { useDispatch, useSelector } from 'react-redux';
import { setIsInputsOnlyLayout } from '../redux/reducer/globalsReducer';

export const useIsInputsOnlyLayout = () => {
  const currentValue = useSelector(state => state.auth.isInputsOnlyLayout);
  const dispatch = useDispatch();

  const setValue = (newValue) => {
    dispatch(setIsInputsOnlyLayout(newValue ?? false));
  };

  return [currentValue, setValue];
};

export default useIsInputsOnlyLayout;