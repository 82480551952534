import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAccounts } from '../api/advancedConfig';

export const fetchAllAccounts = createAsyncThunk(
  'accounts/fetchAllAccounts',
  async (payload, { getState, requestId, rejectWithValue }) => {
    const { id: locationId, isJeTrue: journalEntry } = payload;

    const { currentRequestId, status } = getState().accounts;
    const alreadyLoading = status !== 'pending' || requestId !== currentRequestId;
    let result;
    if (!alreadyLoading) {
      try {
        const response = await getAccounts(locationId, { journalEntry });
        result = response.data;
      } catch (err) {
        result = rejectWithValue(err);
      }
    }

    return result;
  }
);

export const refreshAccounts = createAsyncThunk(
  'accounts/fetchAllAccounts',
  async (payload, { getState, requestId, rejectWithValue }) => {
    const { id: locationId, isJeTrue: journalEntry } = payload;
    const { currentRequestId, status } = getState().accounts;
    
    const alreadyLoading = status !== 'pending' || requestId !== currentRequestId;
    let result;
    if (!alreadyLoading) {
      try {
        const response = await getAccounts(locationId, { isRefresh: true, journalEntry });
        result = response.data;
      } catch (err) {
        result = rejectWithValue(err);
      }
    }

    return result;
  }
);

const transformAccounts = (accountsRaw) => {
  return accountsRaw.map(accountData => {
    return {
      value: accountData.name,
      label: accountData.name,
      type: accountData.type.toLowerCase(),
    };
  })
};

const initialState = {
  status: 'idle',
  entities: [],
  currentRequestId: undefined,
  error: null,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsList: (state, action) => {
      state.entities = transformAccounts(action.payload);
    },
    resetAccounts: (state) => {
      return Object.assign({}, initialState);
    },
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(fetchAllAccounts.pending, (state, action) => {
      if (state.status === 'idle') {
        state.status = 'pending';
        state.currentRequestId = action.meta.requestId;
        state.entities = [];
        state.error = null;
      }
    });

    builder.addCase(fetchAllAccounts.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if (state.status === 'pending' && state.currentRequestId === requestId) {
        state.status = 'idle';
        state.entities = transformAccounts(action.payload);
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(fetchAllAccounts.rejected, (state, action) => {
      const { requestId } = action.meta
      if (state.status === 'pending' && state.currentRequestId === requestId) {
        state.status = 'idle';
        state.error = action.payload.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { setAccountsList, resetAccounts } = accountsSlice.actions;

export default accountsSlice.reducer;
