import { Text } from "@lightspeed/flame/Text";
import target from "../../target.json";
import amIVisible from '../../componentControl/registry';

const Copyrights = ({ isFullPage, hasShownBottomBar }) => {

    let extraStyle = {}

    const canShowCopyright = amIVisible("showCopyRight")

    if (isFullPage) {
        extraStyle =  {
            "position": "fixed",
            "bottom": 0
        }
    }

    if (hasShownBottomBar) {
        extraStyle = {
            ...extraStyle,
            height: "100px",
            marginTop: 0
        }
    }

    return (
        <div>
            <div style={{
                width: "100%",
                height: "30px",
                textAlign: "center",
                ...extraStyle
            }}>
                {canShowCopyright && <Text fontSize={"12px"}>
                    ©️ Copyright {new Date()?.getFullYear()} - SM Technology
                </Text>}
                <Text fontSize={"10px"}>
                    Build - {target?.version ?? ""}
                </Text>
            </div>
        </div>
    );
}

export default Copyrights;