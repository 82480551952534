/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from "react";
import useCompanyName from "../../hooks/useCompanyName";
import { useIsCompanyAuthorizedQuery } from "../../services/authService"
import { useGetAccountsQuery, useGetDashValuesQuery, useGetSyncCountsQuery, useGetTaxesQuery } from "../../services/dashboardService";
import { useDispatch, useSelector } from 'react-redux';
import useJeAsCategory from "../../hooks/useJeAsCategory";
import { setIsTaxAgency, setShowLayAway } from "../../redux/reducer/globalsReducer";
import amIVisible from "../../componentControl/registry";
import { PageLayout } from "../pageLayout/PageLayout";
import { Form, Formik } from 'formik';
import { getValidationSchema } from "../../utils/helpers/fieldsValidation";
import { useTranslation } from 'react-i18next';
import { getCleanState } from "../../utils/helpers/dashboard";
import { dashboardTransformer } from '../../utils/helpers/dashboardTransformer';
import { AccountingSoftware } from "./AccountingSoftware";
import { Divider } from '@lightspeed/flame/Divider';
import { MapAccounts } from "./MapAccounts";
import { CogsInventorySection } from "../cogsInventory/CogsInventorySection";
import { OptionsSection } from './OptionsSection';
import useAppConnected from "../../hooks/useAppConnected";
import { SyncLogs } from '../syncLogs/SyncLogs';
import { IndividualPaymentMapping } from "../mapPaymentMethods/IndividualPaymentMapping";
import { IndividualProductMapping } from '../mapSales/IndividualProductMapping';
import { IndividualCategoriesMapping } from '../mapSales/IndividualCategoryMapping';
import { setRefetchSyncCount, setRefreshAccounts } from "../../slices/dashboardSlice";
import { setIsReconnecting } from "../../slices/authSlice";
import { IndividualPoProductMapping } from "../cogsInventory/individualPo/IndividualProductPo";
import { IndividualPoCategoriesMapping } from "../cogsInventory/individualPo/IndividualCategoriesPo";
import { useLocationData } from "../../hooks/useLocation";
import Logout from "../custom/logout";
import { IndividualDiscountMapping } from '../mapDiscounts/IndividualDiscountMapping';
import { SessionTimeoutError } from '../custom/sessionTimeout';
import { resetSyncPage } from "../../slices/syncSlice";

export const Dashboard = (props) => {

    const key = useRef(Date.now()).current;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [companyName, setCompanyName] = useCompanyName();
    const [, setConnected] = useAppConnected();

    const { isMultiLocation, currentLocation } = useLocationData()

    const [, setJeAsCategory] = useJeAsCategory();
    const [initialValues] = useState(getCleanState(true));
    const configData = useSelector(state => state.dashboard.fetchedConfig)

    const { currentComponent, currentPostingMethod, isAccountLoading, isRefreshAccount, refreshAccounts, refetchSyncCount } = useSelector(state => state.dashboard)

    // isCompanyAuthorize
    const isCompanyAuthorizeResponse = useIsCompanyAuthorizedQuery({ key }, { refetchOnMountOrArgChange: 1 })

    // fetch all configurations
    const configurationResponse = useGetDashValuesQuery({ key }, {
        refetchOnMountOrArgChange: true,
        skip: !isCompanyAuthorizeResponse.isSuccess,
        selectFromResult: ({ data, ...rest }) => {
            return {
                initialData: { ...data, ...data?.attributes },
                data: dashboardTransformer({ ...data, ...data?.attributes }),
                ...rest
            }
        }
    })

    // fetch all accounts and taxes
    const accountsData = useGetAccountsQuery({ key, isRefresh: isRefreshAccount, journalEntry: currentPostingMethod === 'journalEntry' }, { skip: !configurationResponse?.isSuccess, refetchOnMountOrArgChange: false })
    const taxData = useGetTaxesQuery({ key, journalEntry: currentPostingMethod === 'journalEntry' }, { skip: !configurationResponse?.isSuccess, refetchOnMountOrArgChange: false })

    const syncCounts = useGetSyncCountsQuery({ key }, { skip: !configurationResponse?.isSuccess, refetchOnMountOrArgChange: false })

    useEffect(() => {
        if (isAccountLoading && !accountsData.isLoading && (!accountsData?.isUninitialized)) {
            accountsData.refetch()
        }
    }, [isAccountLoading])

    useEffect(() => {
        if (refreshAccounts && (!accountsData?.isUninitialized && !taxData?.isUninitialized)) {
            accountsData.refetch()
            taxData.refetch()
            dispatch(setRefreshAccounts(false))
        }
    }, [refreshAccounts])

    useEffect(() => {
        if (refetchSyncCount) {
            syncCounts?.refetch()
            dispatch(setRefetchSyncCount(false))
        }
    }, [refetchSyncCount])

    /* handle isCompanyAuthorize response */
    useEffect(() => {

        const { isSuccess, data, isError, error } = isCompanyAuthorizeResponse;

        if (isSuccess && companyName === "") {
            setCompanyName(data?.companyName)
            setJeAsCategory(data?.JeAsInvoice)
            dispatch(setShowLayAway(data?.isLayAway ?? false))
            dispatch(setIsTaxAgency(data?.taxAgency ?? false))
            setConnected(true)
        }

        if (isError && currentLocation?.connectionMode !== "Sage Intacct") {
            if (error.status === 404) {
                props.navigate("../user-onboard/")
            } else if (error.status === 430) {
                dispatch(setIsReconnecting(1))
                props.navigate("../user-onboard/")
            }
        }

    }, [isCompanyAuthorizeResponse])

    useEffect(() => {

        if (currentComponent === "dash") {
            dispatch(resetSyncPage())
        }

    }, [currentComponent])

    const getContext = (props) => {
        switch (currentComponent) {
            case "sync history":
                return <SyncLogs />
            case "po sync history":
                return <SyncLogs isTypePo />
            case "payment mapping":
                return <IndividualPaymentMapping {...props} />
            case "discount mapping":
                return <IndividualDiscountMapping {...props} />
            case "sales product mapping":
                return <IndividualProductMapping {...props} />
            case "sales category mapping":
                return <IndividualCategoriesMapping {...props} />
            case "po product mapping":
                return <IndividualPoProductMapping {...props} />
            case "po category mapping":
                return <IndividualPoCategoriesMapping {...props} />
            default:
                return <></>
        }
    }

    return (
        <>
            <SessionTimeoutError {...props} />
            <Formik
                initialValues={configData || initialValues}
                validationSchema={getValidationSchema(t)}
                enableReinitialize
            >
                {formik =>
                    <>
                        <DashboardWrapper
                            formik={formik}
                        >
                            {
                                currentComponent === "dash" ?
                                    <>
                                        <PageLayout
                                            showDisconnected={false}
                                            path={props.path}
                                            navigate={props.navigate}
                                            formik={formik}
                                            refetchConfig={configurationResponse?.refetch}
                                            title={isMultiLocation && currentLocation?.name}
                                            description={isMultiLocation && t('locationMappingDesc')}
                                            showBack={isMultiLocation}
                                        >
                                            <AccountingSoftware formik={formik} isLoading={configurationResponse?.isLoading || configurationResponse?.isFetching} refetchConfig={configurationResponse?.refetch} {...props} />
                                            <Divider />
                                            {formik.values.postingMethod &&
                                                <>
                                                    <MapAccounts
                                                        formik={formik}
                                                        {...props}
                                                    />
                                                    {amIVisible("po cogs") &&
                                                        <>
                                                            <Divider />
                                                            <CogsInventorySection
                                                                formik={formik}
                                                                {...props} />
                                                        </>
                                                    }
                                                    {
                                                        <>
                                                            <Divider />
                                                            <OptionsSection
                                                                formik={formik}
                                                                {...props}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            }
                                        </PageLayout>
                                    </>
                                    :
                                    <>
                                        {getContext({ formik })}
                                    </>
                            }
                        </DashboardWrapper>
                    </>
                }
            </Formik>
            <Logout {...props} />
        </>
    )
};

const DashboardWrapper = ({
    formik,
    children,
}) => {
    return <Form>{children}</Form>
}