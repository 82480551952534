import { TextLink } from "@lightspeed/flame/Text";
import { useNotification } from '../../../utils/helpers/notifications';
import { useDispatch } from 'react-redux';
import { setCurrentComponent } from "../../../slices/dashboardSlice";
import amIVisible from "../../../componentControl/registry";

const IndividualPo = (props) => {

    const {
        isSentPurchaseOrder,
        trackInventory,
        isSentCogs,
        defaultInventoryAccountName,
        defaultCogsAccountName,
        postingMethod
    } = props?.formik?.values;

    const { showNotification } = useNotification()
    const dispatch = useDispatch()

    let showCogs = (trackInventory && isSentPurchaseOrder) || isSentCogs
    const isTypeCategory = postingMethod !== 'invoice';
    const canShowBothProductAndCategories = amIVisible("inventory cogs can show both product and categories")

    const isProductOnly = amIVisible("show only individual sales / po product mapping")

    const getLabel = () => {
        
        if (isSentPurchaseOrder && !trackInventory && !isSentCogs) {
            return 'Map Individual Inventory Accounts'
        }

        return 'Map Individual Inventory And COGS Accounts'
    }

    const goToMapping = () => {
        if (props?.formik?.dirty) {
            showNotification("Save the current changes to proceed", "error")
        } else if (defaultInventoryAccountName && (!showCogs || defaultCogsAccountName)) {
            let comp = 'product'
            if (!isTypeCategory && canShowBothProductAndCategories) {
                comp = 'category'
            } else if (isTypeCategory) {
                comp = 'category'
            }

            if (isProductOnly) {
                comp = 'product'
            }

            dispatch(setCurrentComponent("po " + comp + " mapping"))
        } else {
            showNotification("Please select the default account first", "error")
        }
    }

    return (
        <>
            <TextLink fontWeight="bold" pl=".3rem" fontSize="text-s" onClick={goToMapping}>{getLabel()}</TextLink>
        </>
    );
}

export default IndividualPo;