
import { apiService } from './apiService';
import store from '../redux/store';
import _ from 'lodash';

export const syncApi = apiService.injectEndpoints({
    endpoints: (builder) => ({
        getSyncData: builder.query({
            query: () => {
                const { pageBackupTable, pageOriginalTable, s, sd, ed } = store.getState().sync
                const { currentComponent } = store.getState().dashboard

                let params = {
                    pageBackupTable,
                    pageOriginalTable,
                    s,
                    sd,
                    ed
                }

                params = _.omitBy(params, _.isNil)

                return {
                    url: currentComponent === 'sync history' ? 'getSyncData/' : 'getPOSyncData/',
                    params
                }
            }
        }),
        historicalSync: builder.mutation({
            query: (body) => {
                return {
                    url: 'historicalSync/',
                    method: "POST",
                    body
                }
            }
        }),
        repostSync: builder.mutation({
            query: ({ url, body }) => {
                return {
                    url,
                    method: 'POST',
                    body,
                }
            }
        }),
        getOrder: builder.query({
            query: ({ key, ...params}) => {
                const { currentComponent } = store.getState().dashboard

                return {
                    url: currentComponent === 'sync history' ? 'order/' : 'purchaseOrder/',
                    params
                }
            }
        })
    })
})

export const {
    useGetSyncDataQuery,
    useHistoricalSyncMutation,
    useRepostSyncMutation,
    useGetOrderQuery
} = syncApi;